import { Type, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IReportFilterOptions, IReportFilterContext } from '../_state/generic/filter';

export function setupInputProviders(component: Type<ControlValueAccessor>) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => component),
      multi: true,
    },
  ];
}

export function handleFilterMapping(
  options: IReportFilterOptions,
  mapFnc: (filter: IReportFilterContext) => IReportFilterContext,
) {
  const props = Object.keys(options);
  for (const key of props) {
    options[key].context = mapFnc(options[key].context);
  }
  return options;
}
