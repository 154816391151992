import {
  IReportSearchFilter,
  ReportSearchFilter,
} from './reportSearch.filter';
import {
  ExchangeTypes,
  HireMovementTypes,
  IHireMovement,
} from '../models/reports/hireMovement.model';

export interface IHireMovementReportSearchFilter extends IReportSearchFilter {
  createdReasonCode: string;
  exchangeType: ExchangeTypes;
  hireMovementType: HireMovementTypes;
  lineType: string;
  offHireDateFrom: string; // yyyy-mm-dd (ISO8601)
  offHireDateTo: string; // yyyy-mm-dd (ISO8601)
  quantityReturned: number; // yyyy-mm-dd (ISO8601)
  terminatedReasonCode: string;
  createdOrTerminatedCode?: string;
}

export class HireMovementReportSearchFilter
  extends ReportSearchFilter
  implements IHireMovementReportSearchFilter, Partial<IHireMovement> {

  constructor(limit: number = 1000, page: number = 1, maxLimit: number = 1000000) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000000
    super(limit, page, maxLimit);
    // set the default sort for the live hire reports to be the onHireDate
    this.sort = '-onHireDate';
  }

  private _offHireDateFrom: string; // yyyy-mm-dd (ISO8601)
  private _offHireDateTo: string; // yyyy-mm-dd (ISO8601)
  private _quantityReturned: number;
  private _exchangeType: ExchangeTypes;
  private _hireMovementType: HireMovementTypes;
  private _createdReasonCode: string;
  private _lineType: string;
  private _terminatedReasonCode: string;
  private _createdOrTerminatedReasonCode: string;

  get quantityReturned(): number {
    return this._quantityReturned;
  }

  set quantityReturned(value: number) {
    this._quantityReturned = value;
  }

  get hireMovementType(): HireMovementTypes {
    return this._hireMovementType;
  }

  set hireMovementType(value: HireMovementTypes) {
    this._hireMovementType = value;
  }

  get createdReasonCode(): string {
    return this._createdReasonCode;
  }

  set createdReasonCode(value: string) {
    this._createdReasonCode = value;
  }

  get exchangeType(): ExchangeTypes {
    return this._exchangeType;
  }

  set exchangeType(value: ExchangeTypes) {
    this._exchangeType = value;
  }

  get lineType(): string {
    return this._lineType;
  }

  set lineType(value: string) {
    this._lineType = value;
  }

  get offHireDateFrom(): string {
    return this._offHireDateFrom;
  }

  set offHireDateFrom(value: string) {
    this._offHireDateFrom = value;
  }

  get offHireDateTo(): string {
    return this._offHireDateTo;
  }

  set offHireDateTo(value: string) {
    this._offHireDateTo = value;
  }

  get terminatedReasonCode(): string {
    return this._terminatedReasonCode;
  }

  set terminatedReasonCode(value: string) {
    this._terminatedReasonCode = value;
  }

  get createdOrTerminatedReasonCode(): string {
    return this._createdOrTerminatedReasonCode;
  }

  set createdOrTerminatedReasonCode(value: string) {
    this._createdOrTerminatedReasonCode = value;
  }

  public toJS(): IHireMovementReportSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        createdReasonCode: this.createdReasonCode,
        exchangeType: this.exchangeType,
        hireMovementType: this.hireMovementType,
        lineType: this.lineType,
        offHireDateFrom: this.offHireDateFrom,
        offHireDateTo: this.offHireDateTo,
        quantityReturned: this.quantityReturned,
        terminatedReasonCode: this.terminatedReasonCode,
        createdOrTerminatedReasonCode: this.createdOrTerminatedReasonCode,
      },
    );
  }
}
