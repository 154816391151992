import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { IAuthentication } from '@waracle/gap-sdk';
import { retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiService {

  params: HttpParams;

  url: string = environment.apiUrl;

  constructor(private _http: HttpClient,
              private _authenticationService: AuthenticationService) {
    this.params = new HttpParams();
  }

  get headers(): HttpHeaders {
    const authentication: IAuthentication = this._authenticationService.authentication;

    let httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    if (authentication) {
      httpHeaders = httpHeaders.append('Authorization', `JWT ${authentication.token}`);
    }

    return httpHeaders;
  }

  get<T>(path: string, params?: any): Observable<T> {

    // Support easy query params for GET requests
    if (params) {
      for (const param of params) {
        this.params = this.params.set(param, params[param]);
      }
    }

    return this._http.get<T>(`${this.url}${path}`, { headers: this.headers, params: this.params }).pipe(
      retry(1),
    );
  }

  post<T>(path: string, body: any, params?: any): Observable<T> {
    return this._http.post<T>(this.url + path, JSON.stringify(body), {
      params,
      headers: this.headers,
    });
  }

  delete<T>(path: string): Observable<T> {
    return this._http.delete<T>(this.url + path, { headers: this.headers });
  }

  put<T>(path: string, body: any, params?: any): Observable<T> {
    return this._http.put<T>(this.url + path, JSON.stringify(body), {
      params,
      headers: this.headers,
    });
  }
}
