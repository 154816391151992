import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[gap-ui-minor-nav-item]',
  template: '<ng-content></ng-content>',
  styles: [
    `:host {
      padding: 0px 5px 0px 20px;
      margin: 10px 0 10px -5px;
      font-size: 0.9rem;
      font-weight: 300;
      margin-left: -5px;
    }
    :host:first-child {
      padding-top: 0;
    }
    :host:last-child: {
      padding-bottom: 0;
    }
    :host.active, :host:hover, :host:active {
      padding-left: 15px;
      border-left: 5px solid white;
    }
    :host:hover {
      cursor: pointer;
    }`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinorNavItemComponent {}
