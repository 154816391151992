import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: '[gap-ui-clear-button]',
  template: '<ng-content></ng-content>',
  styles: [`:host {
    color: #E32526;
    background: transparent;
    border: none;
    font-size:1rem;
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearButtonComponent extends MatButton {}
