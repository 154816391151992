import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { UserAuthRoutingModule } from './user-auth-routing.module';
import { AuthFrameComponent } from './auth-frame/auth-frame.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthSliderComponent } from './auth-slider/auth-slider.component';
import { AuthForgottenComponent } from './auth-forgotten/auth-forgotten.component';
import { AuthCreateComponent } from './auth-create/auth-create.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AuthFrameComponent,
    AuthLoginComponent,
    AuthSliderComponent,
    AuthForgottenComponent,
    AuthCreateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserAuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
  ],
  exports:[
    UserAuthRoutingModule,
  ],
})
export class UserAuthModule { }
