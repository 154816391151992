import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { Observable, of, from } from 'rxjs';

import {
  IAuthenticationResponse,
  ROLE_USER,
  IAuthentication,
  IRole,
} from '@waracle/gap-sdk';
import { map, catchError, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(private _api: ApiService, private _authenticationService: AuthenticationService) {
  }

  // login(email: string, password: string): Observable<User> {
  login(email: string, password: string): Observable<boolean> {

    const authLogin = (response: IAuthentication) => from(this._authenticationService.login(response));

    return this._api.post<IAuthenticationResponse>('/v1/login', { email, password })
      .pipe(
        map((res: IAuthenticationResponse): IAuthentication => {
          // login successful if there's a jwt token in the response
          if (!res.response) {
            throw new Error('Login Failed');
          }
          return res.response;
        }),
        switchMap((auth: IAuthentication) => {
          return authLogin(auth);
        }),
        map((isAuthed: boolean) => {
          if (!isAuthed) {
            throw new Error('Login Failed');
          }
          return isAuthed;
        }),
        map((_: boolean) => {
          return this._authenticationService.isAuthenticatedRoleValid(ROLE_USER);
        }),
        catchError((err: any) => {
          return of(false);
        }),
      );
  }

  forget(email: string, force: boolean = false) {
    return this._api.put('/v1/password/reset', { email, force }).pipe(
      take(1),
    );
  }

  reset(password: string, token: string) {
    return this._api.post(`/v1/password/${token}`, { password });
  }

  // used to validate current password when setting a new one
  validate(email: string, password: string): Observable<boolean> {
    return this._api.post<IAuthenticationResponse>('/v1/login', { email, password })
      .pipe(
        map((res: IAuthenticationResponse) => res.response ? true : false),
        catchError((err) => of(false) as Observable<boolean>),
      );
  }
}
