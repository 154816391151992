import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { DefaultInputComponent } from '../default/default.component';
import { IReportDropdownFilterContext } from '../../_state/generic/filter';

@Component({
  selector: 'report-filter-dropdown',
  template: `
    <div ngbDropdown class="dropdown">
      <input
        class="form-control placeholder-with-icon mt-0"
        #rawInputRef
        [formControl]="inputControl"
        placeholder="{{ placeholder | i18n:'REPORT.FILTERS' | translate }}"
        type="text"
        ngbDropdownToggle
        readonly
        autocomplete="off"/>
      <div ngbDropdownMenu class="dropdown-menu" *ngIf="optionsArray$ | async as options">
        <button
          class="dropdown-item"
          *ngFor="let item of options"
          (click)="choiceSelected(item)"
          type="button">
          {{ item.label }}
        </button>
        <button *ngIf="options.length === 0" class="dropdown-item" type="button">
          {{emptyLabel}}
        </button>
      </div>
    </div>
  `,
  styles: [`
    input.form-control {
      width: 100%;
    }
  `],
})
export class DropdownInputComponent extends DefaultInputComponent implements OnInit {
  @Input() emptyLabel: string = 'No matches found';
  @Input() options$: (query: string) => Observable<{label: string, value: string}[]> = (_: string) => of([]);
  @Input() limit: number;
  // translation string
  @Input() placeholder: string = 'NONE';

  optionsArray$: Observable<{label: string, value: string}[]> = of([]);

  ngOnInit() {
    this.optionsArray$ = this.options$(null);
  }

  setProps(data: {
    placeholder?: string,
    activeFilter: IReportDropdownFilterContext,
    limit: number,
  }) {
    this.options$ = data.activeFilter.dropdownChoices$;
    this.limit = data.limit || 0;
    super.setProps(data);
  }

  choiceSelected(item) {
    this.inputControl.setValue(item.label);
    this._onChange(item);
  }

  updateOptions(value) {
    this.optionsArray$ = this.options$(value);
  }
}
