import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Utils } from '@waracle/gap-sdk';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'i18n' })
export class I18nPipe implements PipeTransform {
  transform(value: string, prefix: string): string {
    return `${prefix.split('.').map((str) => Utils.toI18N(str)).join('.')}.${Utils.toI18N(value)}`;
  }
}
