import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-mini-nav',
  templateUrl: './dashboard-mini-nav.component.html',
  styles: [],
})
export class DashboardMiniNavComponent {

  constructor() { }
}
