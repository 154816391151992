import { Injectable } from '@angular/core';
import { ROLE_ADMIN } from '@waracle/gap-sdk';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { checkAuth } from './auth-check';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  protected _requiredRole = ROLE_ADMIN;

  constructor(private _router: Router,
              private _authenticationService: AuthenticationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const result = await checkAuth(this._requiredRole, this._authenticationService);
    if (!result) {
      this._router.navigate(['/']);
    }
    return result;
  }
}
