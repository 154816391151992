import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-auth-slider',
  templateUrl: './auth-slider.component.html',
  styleUrls: ['./auth-slider.component.scss'],
})
export class AuthSliderComponent implements AfterViewInit {
  slides: { title: string, description: string, image: string}[] = [
    {
      title: 'SLIDER.TITLE_1',
      description: 'SLIDER.DESC_1',
      image: '/img/dash.png',
    },
    {
      title: 'SLIDER.TITLE_2',
      description: 'SLIDER.DESC_2',
      image: '/img/pod.png',
    },
    {
      title: 'SLIDER.TITLE_3',
      description: 'SLIDER.DESC_3',
      image: '/img/report.png',
    },
  ];

  trackByFnc = (_, s: any) => s.title;

  ngAfterViewInit(): void {
  }
}
