import {
  ReportTypes,
  InvoiceReportSearchFilter,
} from '@waracle/gap-sdk';
import { createSelector } from '@ngrx/store';

import { getReportManagementState } from '../../_state/report-management.selectors';
import { IReportManagementState } from '../../_state/report-management.model';

import {
  getReportActionTypes,
  getReportActions,
  ReportAction,
} from '../../_state/generic/actions';
import { getInitialReportState, IReportState } from '../../_state/generic/state';
import { IReportDTO } from '../../_state/generic/model';
import { IReportFilterOptions, SupportedFilterTypes } from '../../_state/generic/filter';
import { createReportReducers } from '../../_state/generic/reducers';
import { createReportEntityAdapter, IReportEntityAdapter } from '../../_state/generic/adapter';
import { IReportEntitySelectors } from '../../_state/generic/selectors';
import { createFilterOption } from '../../_filters/_factories';
import { sharedReportFilters, sharedCustomFilterOptions } from '../../_config/shared';

export const additionalFilterOptions = [
  ...sharedReportFilters,
  'invoiceNumber',
  'invoiceType',
  'invoiceDateTo',
  'invoiceDateFrom',
];

export interface IInvoiceReport extends IReportDTO {
  reportType: ReportTypes.INVOICE;
  invoiceType?: string;
  lineType: number;
  invoiceDate?: string;
  invoiceDateFrom?: string;
  invoiceDateTo?: string;
  invoiceNumber: string;
  productCode: string;
}

export const INVOICE_ACTION_TYPES = getReportActionTypes('invoice');

export const INVOICE_ACTIONS = getReportActions<IInvoiceReport>(INVOICE_ACTION_TYPES);

const invoiceTypeFilter = createFilterOption('invoiceType', SupportedFilterTypes.DROPDOWN);

const invoiceDateToFilter = createFilterOption('invoiceDateTo', SupportedFilterTypes.DATE);
invoiceDateToFilter.context.required = true;
const invoiceDateFromFilter = createFilterOption('invoiceDateFrom', SupportedFilterTypes.DATE);
invoiceDateFromFilter.context.required = true;

export const customFilterOptions: IReportFilterOptions = {
  ...sharedCustomFilterOptions,
  invoiceType: invoiceTypeFilter,
  invoiceDateTo: invoiceDateToFilter,
  invoiceDateFrom: invoiceDateFromFilter,
};

export const initialState: IReportState<IInvoiceReport> = getInitialReportState<IInvoiceReport>({
  filters: {
    customOptions: customFilterOptions,
    additionalOptions: additionalFilterOptions,
  },
});

const adapter: IReportEntityAdapter<IInvoiceReport, InvoiceReportSearchFilter> =
  createReportEntityAdapter<IInvoiceReport, InvoiceReportSearchFilter>();

export function reducer(
  state = initialState,
  action: ReportAction<IInvoiceReport>,
): IReportState<IInvoiceReport> {
  return createReportReducers<IInvoiceReport, InvoiceReportSearchFilter>(
    INVOICE_ACTION_TYPES,
    adapter,
  )(state, action);
}

export const getReportState = createSelector(
  getReportManagementState,
  (state: IReportManagementState) => state.invoice,
);

export const reportSelectors: IReportEntitySelectors<IInvoiceReport, InvoiceReportSearchFilter> =
  adapter.getSelectors(getReportState);
