import {
  ISearchFilter,
  SearchFilter,
} from './search.filter';

export interface IInsightSearchFilter extends ISearchFilter {
  /**
   * The date that the insight has been automated to run at
   *
   * @type {string} cron expression
   */
  automateOn: string;

  /**
   * Whether the insight is pinned to the user's dashboard or not
   * @type {boolean}
   */
  isPinned: boolean;
}

export class InsightSearchFilter extends SearchFilter implements IInsightSearchFilter {

  constructor(limit: number = 100, page: number = 1, maxLimit: number = 100) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000
    super(limit, page, maxLimit);
  }

  private _automateOn: string;
  private _isPinned: boolean;

  get automateOn(): string {
    return this._automateOn;
  }

  set automateOn(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }
    this._automateOn = value;
  }

  get isPinned(): boolean {
    return this._isPinned;
  }

  set isPinned(value: boolean) {
    if (typeof value !== 'boolean') {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }
    this._isPinned = value;
  }

  public toJS(): IInsightSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        automateOn: this.automateOn,
        isPinned: this.isPinned,
      },
    );
  }
}
