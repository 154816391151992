import { EntityModel, IBaseModel } from './base.model';

// tslint:disable:naming-convention
export enum ReportTypes {
  ACCRUAL = 'accrual',
  ACCOUNT = 'account',
  DEPOT = 'depot',
  HIRE_MOVEMENT = 'hire_movement',
  INSIGHT = 'insight',
  INVOICE = 'invoice',
  LIVE_HIRE = 'live_hire',

}

// tslint:enable:naming-convention
export interface IReport extends EntityModel<IReportDTO> {}

export interface IReportDTO extends IBaseModel {
  reportType: ReportTypes;
  addressLine1: string;
  addressLine2: string;
  fleetNumber: string;
  contractNumber: string;
  customerNumber: string;
  syncKey?: string;
}
