// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { IGapPortalEnvironment } from './environment.interface';

const buildMetadata = require('../../../../build-metadata.json');

export const environment: IGapPortalEnvironment = {
  apiUrl: 'https://api.dev.gap.waracle.dev',
  automationCron: '0 12 * * 4',
  buildNumber: buildMetadata.build,
  cdnBaseUrl: 'https://cdn.dev.gap.waracle.dev',
  dateFormat: 'd MMM y',
  enabledCantrack: false,
  environment: 'dev',
  gtm: {
    id: 'GTM-TDWP4RT',
  },
  jwkKeyId: '2',
  jwksUri: 'https://jwks.dev.gap.waracle.dev/.well-known/jwks.json',
  jwtIssuer: 'extranet.gap-group.co.uk',
  name: buildMetadata.name,
  reportFetchLimit: 1000,
  production: false,
  trackingUrl: 'https://telematics.gap-group.co.uk/tracking',
  certificatesUrl: 'https://telematics.gap-group.co.uk/certificates',
  version: buildMetadata.version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
