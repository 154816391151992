import {
  ISearchFilter,
  SearchFilter,
} from '../../search/search.filter';
import { ReportTypes } from '../report.model';
import { DocumentTypes } from './document.types';

export interface IDocumentSearchFilter extends ISearchFilter {
  contractNumber: string;
  customerNumber: string;
  reportType?: ReportTypes;
  type?: DocumentTypes;
}

export interface IDocumentSearchConstructor {
  new(contractNumber: string, type?: DocumentTypes | DocumentTypes[]): IDocumentSearchFilter;
}

export class DocumentSearchFilter extends SearchFilter implements IDocumentSearchFilter {

  private _contractNumber: string;
  private _customerNumber: string;
  private _reportType: ReportTypes;
  private _type?: DocumentTypes;

  get contractNumber(): string {
    return this._contractNumber;
  }

  get customerNumber(): string {
    return this._customerNumber;
  }

  set customerNumber(value: string) {
    if (value && typeof value === 'string') {
      this._customerNumber = value;
    }
  }

  set contractNumber(value: string) {
    if (value && typeof value === 'string') {
      this._contractNumber = value;
    }
  }

  get reportType(): ReportTypes {
    return this._reportType;
  }

  set reportType(value: ReportTypes) {
    if (Object.values(ReportTypes).includes(value)) {
      this._reportType = value;
    }
  }

  get type(): DocumentTypes {
    return this._type;
  }

  set type(value: DocumentTypes) {
    if (Object.values(DocumentTypes).includes(value)) {
      this._type = value;
    }
  }

  public toJS(): IDocumentSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        contractNumber: this.contractNumber,
        customerNumber: this.customerNumber,
        type: this.type,
      },
    );
  }
}
