import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DefaultInputComponent } from '../default/default.component';
import { setupInputProviders } from '../_helpers';
import { IReportEnumFilterContext } from '../../_state/generic/filter';

@Component({
  selector: 'report-filter-choice',
  template: `
    <gap-ui-look-ahead #rawInputRef
      [formControl]="inputControl"
      [options]="optionsArray$ | async"
      [optionLimit]="limit"
      (lookupQueryChanged)="updateOptions($event)"
      (selected)="choiceSelected($event)"
      placeholder="{{ placeholder | i18n:'REPORT.FILTERS' | translate }}">
    </gap-ui-look-ahead>
  `,
  styles: [],
  providers: setupInputProviders(ChoiceInputComponent),
})
export class ChoiceInputComponent extends DefaultInputComponent implements OnInit {
  @Input() options$: (query: string) => Observable<string[]>;
  @Input() limit: number;
  // translation string
  @Input() placeholder: string = 'NONE';

  optionsArray$: Observable<string[]> = of([]);

  setProps(data: {
    placeholder?: string,
    activeFilter: IReportEnumFilterContext,
    limit: number,
  }) {
    this.options$ = data.activeFilter.choices$;
    this.limit = data.limit || 0;
    super.setProps(data);
  }

  ngOnInit() {
  }

  choiceSelected(value) {
    this._onChange(value);
  }

  updateOptions(value) {
    this.optionsArray$ = this.options$(value);
  }

}
