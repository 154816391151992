import { IHireReport } from './hireReport.model';

export enum GaptrackTypes {
  INCLUDE = 'true',
  EXCLUDE = 'false',
}

export interface ILiveHire extends IHireReport {
  parentAccountId: string;
  pretext: string;
  gaptrack: GaptrackTypes;
}
