import { NgModule } from '@angular/core';
import { I18nPipe } from '../_pipes/i18n.pipe';
import { CdnPipe } from '../_pipes/cdn.pipe';
import { KeyContactDetailsComponent } from './key-contacts-details/key-contact-details.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NoContentDirective } from '../_directives/no-content.directive';
import { ReportListComponent } from './_components/report-list/report-list.component';
import { InsightTypeComponent } from '../_components/insight-type/insight-type.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [TranslateModule, CommonModule, RouterModule],
  declarations: [
    I18nPipe,
    CdnPipe,
    ProfileDetailsComponent,
    NoContentDirective,
    ReportListComponent,
    InsightTypeComponent,
    KeyContactDetailsComponent,
  ],
  exports: [
    I18nPipe,
    CdnPipe,
    ProfileDetailsComponent,
    NoContentDirective,
    TranslateModule,
    ReportListComponent,
    InsightTypeComponent,
    KeyContactDetailsComponent,
  ],
  providers: [I18nPipe, CdnPipe],
})
export class SharedModule {}
