import { Component, OnInit, Input } from '@angular/core';
import { IUser } from '@waracle/gap-sdk';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { matchPasswordValidator, multiInputValidator } from '@waracle/gap-extranet/angular-validation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  hasNUppercaseValidator,
  hasNLowercaseValidator,
  hasNNumberValidator,
  ExistingPasswordChecker,
} from './validators';
import { PasswordService } from '../../_services/password.service';
import { UserService } from '../../_services/user.service';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../_services/alert.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { ErrorModalComponent } from '../error/error-modal.component';
import { ErrorModalService } from '../../_services/error-modal.service';

interface IRequirement {
  key: string;
  isFailure: boolean;
  isSuccess: boolean;
  isClean: boolean;
  message: string;
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {

  @Input()
  user:IUser;

  public requirements: IRequirement[] = [
    {
      key: 'minlength',
      isFailure: false,
      isSuccess: false,
      isClean: true,
      message: 'At least 8 characters',
    },
    {
      key: 'uppercase',
      isFailure: false,
      isSuccess: false,
      isClean: true,
      message: 'At least 1 upper case (A-Z)',
    },
    {
      key: 'lowercase',
      isFailure: false,
      isSuccess: false,
      isClean: true,
      message: 'At least 1 lower case (a-z)',
    },
    {
      key: 'numeric',
      isFailure: false,
      isSuccess: false,
      isClean: true,
      message: 'At least 1 number (0-9)',
    },
  ];

  resetForm: FormGroup;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    private _userService: UserService,
    private _passwordService: PasswordService,
    private _authService: AuthenticationService,
    public activeModal: NgbActiveModal,
    private _alertService: AlertService,
    private _errorModalService: ErrorModalService,
  ) { }

  ngOnInit() {
    const passChecker = new ExistingPasswordChecker(this._passwordService.validate.bind(this._passwordService));
    this.resetForm = new FormGroup(
      {
        currentPassword: new FormControl(this.currentPassword, [
          Validators.required,
        ],                               [
          passChecker.run(this._userService.me().email),
        ]),
        newPassword: new FormControl(this.newPassword, multiInputValidator({
          required: Validators.required,
          minlength: Validators.minLength(8),
          lowercase: hasNLowercaseValidator(),
          uppercase: hasNUppercaseValidator(),
          numeric: hasNNumberValidator(),
        })),
        confirmPassword: new FormControl(this.confirmPassword, [
        ]),
      },
      [
        matchPasswordValidator('newPassword', 'confirmPassword'),
      ],
    );
  }

  updateValidationMessages(e: any) {
    const newPass = this.resetForm.get('newPassword');
    this.requirements.map((req) => {
      req.isClean = !(newPass.dirty) || newPass.value === '';
      const fails = (newPass.errors && newPass.errors[req.key]) ? true : false;
      req.isFailure = fails;
      req.isSuccess = (!req.isClean && !fails);
      return req;
    });
  }

  submit() {
    const userId = this._userService.me().id;
    this._userService.updatePassword(userId, this.resetForm.get('newPassword').value).pipe(
      tap((_) => {
        this.activeModal.close();
      }),
    ).subscribe(
      (user: IUser) => {
        this._alertService.success('USER.ALERTS.PASSWORD_RESET', {}, true);
        this._alertService.clearAfterTime(3000);
        setTimeout(
          () => {
            this._authService.logout();
          },
          3000,
        );
      },
      async (err: any) => {
        this._errorModalService.open(this, ErrorModalComponent, this.submit, err.status);
      },
    );
  }
}
