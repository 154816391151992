import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchFilter } from '@waracle/gap-sdk';
import { IRoleEntity } from './role.model';

export enum RoleActionTypes {
  FETCH_ROLES = '[Role] Fetch Roles',
  FETCH_ROLES_FAILED = '[Role] Fetch Roles Failed',
  LOAD_ROLES = '[Role] Load Roles',
  ADD_ROLE = '[Role] Add Role',
  UPDATE_ROLE = '[Role] Update Role',
  UPDATE_ROLES = '[Role] Update Roles',
  DELETE_ROLE = '[Role] Delete Role',
  DELETE_ROLES = '[Role] Delete Roles',
  CLEAR_ROLES = '[Role] Clear Roles',
}

export class FetchRoles implements Action {
  readonly type = RoleActionTypes.FETCH_ROLES;

  constructor(public payload?: SearchFilter) {}
}

export class FetchRolesFailed implements Action {
  readonly type = RoleActionTypes.FETCH_ROLES_FAILED;

  constructor(public payload: HttpErrorResponse) {}
}

export class LoadRoles implements Action {
  readonly type = RoleActionTypes.LOAD_ROLES;

  constructor(public payload: { roles: IRoleEntity[] }) {}
}

export class AddRole implements Action {
  readonly type = RoleActionTypes.ADD_ROLE;

  constructor(public payload: { role: IRoleEntity }) {}
}

export class UpdateRole implements Action {
  readonly type = RoleActionTypes.UPDATE_ROLE;

  constructor(public payload: { role: Update<IRoleEntity> }) {}
}

export class UpdateRoles implements Action {
  readonly type = RoleActionTypes.UPDATE_ROLES;

  constructor(public payload: { roles: Update<IRoleEntity>[] }) {}
}

export class DeleteRole implements Action {
  readonly type = RoleActionTypes.DELETE_ROLE;

  constructor(public payload: { id: string }) {}
}

export class DeleteRoles implements Action {
  readonly type = RoleActionTypes.DELETE_ROLES;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearRoles implements Action {
  readonly type = RoleActionTypes.CLEAR_ROLES;
}

export type RoleActions =
 LoadRoles
 | AddRole
 | UpdateRole
 | UpdateRoles
 | DeleteRole
 | DeleteRoles
 | ClearRoles
 | FetchRoles
 | FetchRolesFailed;
