import { AppError } from './app.error';

export class MissingParameterError extends AppError {

  constructor(message: string, error?: Error) {
    super(message, error);
    // Set the prototype explicitly.
    // @ts-ignore
    Object.setPrototypeOf(this, MissingParameterError.prototype);
  }
}
