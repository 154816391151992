import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
} from '@angular/core';

export interface IChipItem {
  id: number | string;
  label: string;
  heading?: string;
  selected?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export interface IChipListComponent {
  resetChips(): void;
}

@Component({
  selector: 'gap-ui-chips',
  template: `
    <span *ngFor="let item of items"
      [ngClass]="{
        'badge': true,
        'badge-pill': true,
        'mb-2': true,
        'badge-primary': !item.disabled,
        'badge-disabled': item.disabled
      }">
        <div class="badge-content">
          <label *ngIf="item.heading">{{item.heading}}</label>
          <span>{{item.label}}</span>
        </div>
        <div *ngIf="editable" class="icon-wrapper">
          <i class="material-icons" (click)="toggleSelectChip(item)">edit</i>
        </div>
        <div *ngIf="removable && !item.required" class="icon-wrapper badge-suffix">
          <i class="material-icons" (click)="remove(item)">close</i>
        </div>
      </span>
  `,
  styles: [`
    .badge.badge-pill {
      display: inline-flex;
      border-radius: 3px;
      padding: 0 10px;
      margin-right: 6px;
      text-align: left;
      //box-shadow: 2px 5px 15px -4px rgba(0,0,0,0.75);
      box-shadow-none;
      line-height: 1.15rem;
    }
    .badge-content {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
    }
    .badge-suffix {
      border-left: 0;
      margin-left: 0;
    }
    i.material-icons {
      font-size: 16px;
      font-weight: 700;
      margin-left: 15px;
      cursor: pointer;
    }
    .badge-suffix i.material-icons {
      margin-left: 10px;
    }
    label {
      display: block;
      font-size: 0.8rem;
      opacity: 0.8;
      font-weight: 100;
      color:#fff;
    }
    span{
      font-size: .9rem;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsComponent implements IChipListComponent {

  constructor(private _changeDetector: ChangeDetectorRef) {}

  @Input() set items(items: IChipItem[]) {
    if (items) {
      this._items = items;
    }
  }
  private _items: IChipItem[] = [];
  get items() {
    return this._items;
  }

  @Input() editable: boolean = false;
  @Input() removable: boolean = false;

  @Output() removal: EventEmitter<IChipItem> = new EventEmitter<IChipItem>();
  @Output() edited: EventEmitter<IChipItem> = new EventEmitter<IChipItem>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  remove(chip: IChipItem) {
    this.resetChips();
    this.removal.emit(chip);
  }

  edit(chip: IChipItem) {
    this.edited.emit(chip);
  }

  toggleSelectChip(chip: IChipItem) {
    if (this.editable) {
      chip.selected = !chip.selected;
      if (chip.selected) {
        this.edit(chip);
      }
      else {
        this.reset.emit();
      }
      this._items.map((item) => {
        if (item.id === chip.id) {
          item.selected = chip.selected;
          item.disabled = false;
        }
        else {
          item.disabled = chip.selected;
          item.selected = false;
        }
        return item;
      });
    }
  }

  resetChips() {
    this._items.map((item) => {
      item.disabled = false;
      item.selected = false;
      return item;
    });
    this._changeDetector.markForCheck();
  }
}
