export class AppError extends Error {
  private readonly _error: Error;

  constructor(message: string, error?: Error) {
    // Calling parent constructor of base Error class.
    super(message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AppError.prototype);

    // Saving class name in the property of our custom error as a shortcut.
    this.name = this.constructor.name;
    this._error = error;

    // Capturing stack trace, excluding constructor call from it.
    Error.captureStackTrace(this, (error as any) || this.constructor);
  }

  get error(): Error {
    return this._error;
  }
}
