import { IReportState } from './state';
import {
  ReportAction,
  ReportActionTypes,
  IReportLoadData,
  IReportAddFilter,
  IReportRemoveFilter,
  IReportSortChange,
  IReportUiPageTransition,
  IReportUiViewSizeChange,
  IReportApiPageTransition,
  IReportSetActiveAccount,
  IReportApplyFilters,
} from './actions';
import { IReportDTO, IReportSearchFilter } from './model';
import { IReportEntityAdapter } from './adapter';

export function createReportReducers
  <T extends IReportDTO, V extends IReportSearchFilter>(
    actionTypes: ReportActionTypes,
    adapter: IReportEntityAdapter<T, V>,
    ) {
  function defaultReducers(
    state: IReportState<T>,
    action: ReportAction<T>,
  ) {
    switch (action.type) {
      case actionTypes.ReportFetchData:
        return adapter.isLoading(state);
      case actionTypes.ReportFetchDataFailed:
        return adapter.loadFailed(state);
      case actionTypes.ReportLoadData:
        return adapter.loadData(state, action as IReportLoadData<T>);
      case actionTypes.ReportClearData:
        return adapter.clearData(state);

      case actionTypes.ReportAddFilter:
        return adapter.addReportFilter(state, action as IReportAddFilter);
      case actionTypes.ReportApplyFilters:
        return adapter.applyReportFilters(state, action as IReportApplyFilters);
      case actionTypes.ReportRemoveFilter:
        return adapter.removeReportFilter(state, action as IReportRemoveFilter);
      case actionTypes.ReportClearFilters:
        return adapter.clearReportFilters(state);

      case actionTypes.ReportSortChange:
        return adapter.sortReport(state, action as IReportSortChange<T>);
      case actionTypes.ReportFetchSortData:
        return adapter.startProcessing(state);
      case actionTypes.ReportFetchSortComplete:
        return adapter.finishProcessing(state);

      case actionTypes.ReportUiPageTransition:
        return adapter.changePageView(state, action as IReportUiPageTransition);
      case actionTypes.ReportUiViewSizeChange:
        return adapter.changePageSize(state, action as IReportUiViewSizeChange);

      case actionTypes.ReportApiPageTransition:
        return adapter.changeApiPage(state, action as IReportApiPageTransition);

      case actionTypes.ReportSetActiveAccount:
        return adapter.setActiveAccount(state, action as IReportSetActiveAccount);

      case actionTypes.ReportResetState:
        const dataCleared = adapter.clearData(state);
        const filtersCleared = adapter.clearReportFilters(dataCleared);
        return adapter.clearQueryHistory(filtersCleared);

      default:
        return state;
    }
  }

  return defaultReducers;
}
