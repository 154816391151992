import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchFilter } from '@waracle/gap-sdk';
import { IAccount } from './account.model';

export enum AccountActionTypes {
  FETCH_ACCOUNTS = '[Account] Fetch Accounts',
  FETCH_ACCOUNTS_FAILED = '[Account] Fetch Accounts Failed',
  LOAD_ACCOUNTS = '[Account] Load Accounts',
  ADD_ACCOUNT = '[Account] Add Account',
  UPDATE_ACCOUNT = '[Account] Update Account',
  UPDATE_ACCOUNTS = '[Account] Update Accounts',
  DELETE_ACCOUNT = '[Account] Delete Account',
  DELETE_ACCOUNTS = '[Account] Delete Accounts',
  CLEAR_ACCOUNTS = '[Account] Clear Accounts',
  SET_ACTIVE_ACCOUNT = '[Account] Set Active Account',
}

export class FetchAccounts implements Action {
  readonly type = AccountActionTypes.FETCH_ACCOUNTS;

  constructor(public payload?: SearchFilter) {}
}

export class FetchAccountsFailed implements Action {
  readonly type = AccountActionTypes.FETCH_ACCOUNTS_FAILED;

  constructor(public payload: HttpErrorResponse) {}
}

export class LoadAccounts implements Action {
  readonly type = AccountActionTypes.LOAD_ACCOUNTS;

  constructor(public payload: { accounts: IAccount[] }) {}
}

export class AddAccount implements Action {
  readonly type = AccountActionTypes.ADD_ACCOUNT;

  constructor(public payload: { account: IAccount }) {}
}

export class UpdateAccount implements Action {
  readonly type = AccountActionTypes.UPDATE_ACCOUNT;

  constructor(public payload: { account: Update<IAccount> }) {}
}

export class UpdateAccounts implements Action {
  readonly type = AccountActionTypes.UPDATE_ACCOUNTS;

  constructor(public payload: { accounts: Update<IAccount>[] }) {}
}

export class DeleteAccount implements Action {
  readonly type = AccountActionTypes.DELETE_ACCOUNT;

  constructor(public payload: { id: string }) {}
}

export class DeleteAccounts implements Action {
  readonly type = AccountActionTypes.DELETE_ACCOUNTS;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearAccounts implements Action {
  readonly type = AccountActionTypes.CLEAR_ACCOUNTS;

  constructor(public payload?: any) {}
}

export class SetActiveAccount implements Action {
  readonly type = AccountActionTypes.SET_ACTIVE_ACCOUNT;
  constructor(public payload: IAccount) {}
}

export type AccountActions =
 LoadAccounts
 | AddAccount
 | UpdateAccount
 | UpdateAccounts
 | DeleteAccount
 | DeleteAccounts
 | ClearAccounts
 | FetchAccounts
 | FetchAccountsFailed
 | SetActiveAccount;
