import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

import { PasswordService } from '../../_services/password.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Login } from '../../_state/app.actions';

@Component({
  moduleId: module.id,
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})

export class AuthLoginComponent implements OnInit {

  hide = true;
  loading = false;
  loginButton = false;
  emailFocus = false;
  pwFocus = false;

  returnUrl: string;

  loginForm: FormGroup;
  email: FormControl;
  password: FormControl;
  loginFailed: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<any>,
    private _authenticationService: AuthenticationService,
    private _passwordService: PasswordService) {
  }

  ngOnInit() {

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/dashboard';

    // if authenticated, return the user to their previous page
    if (this._authenticationService.isAuthenticated()) {
      this._router.navigate([this.returnUrl]);
    }

    this.createFormControls();
    this.createForm();
  }

  login() {
    this.loading = true;
    const email = this.loginForm.value.email.trim();
    this._passwordService.login(email, this.loginForm.value.password)
      .subscribe((isAuthed: boolean) => {
        if (isAuthed) {
          this.loginFailed = false;
          this._store.dispatch(new Login());
          this._router.navigate([this.returnUrl]);
        }
        else {
          this.loginFailed = true;
          this.loading = false;
          this.loginButton = true;
        }
      });

    setTimeout(() => {
      this.loginFailed = false;
      this.loginButton = false;
    },         3000);
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('[^ @]*@[^ @]* ?'),
    ]);
    this.password = new FormControl('', [
      Validators.required,
    ]);
  }

  createForm() {
    this.loginForm = new FormGroup({
      email: this.email,
      password: this.password,
    });
  }

}
