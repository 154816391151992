import { HireMovementReportSearchFilter, HireMovementTypes, InvoiceReportSearchFilter } from '@waracle/gap-sdk';
import { IReportSearchFilter } from './_state/generic/model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { lastDayOfMonth } from 'date-fns';
import { formatDate } from '@angular/common';

export function isHireMovementReport(report: IReportSearchFilter): report is HireMovementReportSearchFilter {
  return Boolean((report as HireMovementReportSearchFilter).hireMovementType);
}

export function isInvoiceReport(report: IReportSearchFilter): report is InvoiceReportSearchFilter {
  return Boolean((report as InvoiceReportSearchFilter).invoiceType);
}

export function updateSearchWithHireMovementType(search: HireMovementReportSearchFilter)
  : HireMovementReportSearchFilter {
  const hireMovementReport = search;
  if (!hireMovementReport.hireMovementType) return search;
  const hireType = hireMovementReport.hireMovementType;
  hireMovementReport.hireMovementType = undefined;
  const to = hireMovementReport.offHireDateTo || new Date().toISOString();
  const from = hireMovementReport.onHireDateFrom || new Date(0, 0, 0).toISOString();
  switch (hireType.toLowerCase()) {
    case HireMovementTypes.SALE:
      hireMovementReport.lineType = '6';
      break;
    case HireMovementTypes.EXCHANGED:
      hireMovementReport.createdOrTerminatedReasonCode = 'EX1';
      break;
    case HireMovementTypes.OFF_HIRED:
      hireMovementReport.offHireDateFrom = from;
      hireMovementReport.onHireDateFrom = new Date(0, 0, 0).toISOString();
      hireMovementReport.onHireDateTo = from;
      break;
    case HireMovementTypes.ON_OFF_HIRED:
      hireMovementReport.onHireDateTo = to;
      hireMovementReport.offHireDateFrom = from;
      break;
  }
  return hireMovementReport;
}

export function getStartOfLastMonthNgbDate(): NgbDate {
  const now = new Date();
  const month = now.getUTCMonth() === 0 ? 11 : now.getUTCMonth();
  const year = now.getUTCMonth() === 0  ? now.getUTCFullYear() - 1 : now.getUTCFullYear();
  return new NgbDate(
    year,
    month,
    1,
  );
}

export function getEndOfLastMonthNgbDate(): NgbDate {
  const now = new Date();
  const month = now.getUTCMonth() === 0 ? 11 : now.getUTCMonth();
  const year = now.getUTCMonth() === 0  ? now.getUTCFullYear() - 1 : now.getUTCFullYear();
  const day = parseInt(formatDate(lastDayOfMonth(new Date(year, month - 1)), 'd', 'en-US'), 10);
  return new NgbDate(
    year,
    month,
    day,
  );
}
