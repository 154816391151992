import * as https from 'https';
import { IncomingMessage, OutgoingHttpHeaders } from 'http';
import * as querystring from 'querystring';
import { IApi } from './api.interface';
import { Config } from '../config';
import { AppError, MissingParameterError } from '../errors';
import { Logger } from '@waracle/gap-extranet/logger';

export class API implements IApi {
  private static __INSTANCE: IApi;
  private _logger: Logger;

  private constructor() {
    this._logger = new Logger('service:api');
    this._logger.debug('Creating new API singleton');

    if (!Config.API_URL) {
      throw new MissingParameterError('API URL not set');
    }
  }

  public static instance(): IApi {
    if (!this.__INSTANCE) {
      this.__INSTANCE = new API();
    }
    return this.__INSTANCE;
  }

  get<T>(endpoint: string, params?: any, authToken?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const queryString: string = querystring.stringify(params);

      const options: https.RequestOptions = {
        host: Config.API_URL,
        path: `${endpoint}?${queryString}`,
        headers: this.getHeaders(authToken),
        rejectUnauthorized: false,
      };

      const request = https.get(options, (response: IncomingMessage) => {
        if (response.statusCode < 200 || response.statusCode > 299) {
          return reject(
            // tslint:disable-next-line:max-line-length
            new AppError(
              `API Responded with: ${
                response.statusCode
              } for GET ${endpoint}, ${JSON.stringify(
                params,
                null,
                0
              )}, with auth: ${!!authToken}`
            )
          );
        }
        // temporary data holder
        const body = [];
        // on every content chunk, push it to the data array
        response.on('data', (chunk) => body.push(chunk));
        // we are done, resolve promise with those joined chunks
        response.on('end', () => resolve(JSON.parse(body.join(''))));
      });
      // handle connection errors of the request
      request.on('error', (err: Error) => reject(err));
    });
  }

  post<T>(endpoint: string, body: any, authToken?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const postData = JSON.stringify(body);

      const options: https.RequestOptions = {
        host: Config.API_URL,
        path: endpoint,
        method: 'POST',
        rejectUnauthorized: false,
        headers: Object.assign(
          {} as OutgoingHttpHeaders,
          this.getHeaders(authToken),
          {
            'content-length': Buffer.byteLength(postData),
            'content-type': 'application/json',
          }
        ),
      };

      const request = https.request(options, (response: IncomingMessage) => {
        if (response.statusCode < 200 || response.statusCode > 299) {
          return reject(
            new AppError(
              `API Responded with: ${
                response.statusCode
              } for POST ${endpoint}, ${JSON.stringify(
                postData,
                null,
                0
              )}, with auth: ${!!authToken}`
            )
          );
        }
        // temporary data holder
        const body = [];
        // on every content chunk, push it to the data array
        response.on('data', (chunk) => body.push(chunk));
        // we are done, resolve promise with those joined chunks
        response.on('end', () => resolve(JSON.parse(body.join(''))));
      });
      // handle connection errors of the request
      request.on('error', (err: Error) => reject(err));

      // write data to request body
      request.write(postData);
      request.end();
    });
  }

  put<T>(endpoint: string, body: any, authToken?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const putData = JSON.stringify(body);

      const options: https.RequestOptions = {
        host: Config.API_URL,
        path: endpoint,
        method: 'PUT',
        rejectUnauthorized: false,
        headers: Object.assign(
          {} as OutgoingHttpHeaders,
          this.getHeaders(authToken),
          {
            'content-length': Buffer.byteLength(putData),
            'content-type': 'application/json',
          }
        ),
      };

      const request = https.request(options, (response: IncomingMessage) => {
        if (response.statusCode < 200 || response.statusCode > 299) {
          return reject(
            // tslint:disable-next-line:max-line-length
            new AppError(
              `API Responded with: ${
                response.statusCode
              } for POST ${endpoint}, ${JSON.stringify(
                putData,
                null,
                0
              )}, with auth: ${!!authToken}`
            )
          );
        }
        // temporary data holder
        const body = [];
        // on every content chunk, push it to the data array
        response.on('data', (chunk) => body.push(chunk));
        // we are done, resolve promise with those joined chunks
        response.on('end', () => resolve(JSON.parse(body.join(''))));
      });
      // handle connection errors of the request
      request.on('error', (err: Error) => reject(err));

      // write data to request body
      request.write(putData);
      request.end();
    });
  }

  delete<T>(endpoint: string, params: any, authToken?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const queryString: string = querystring.stringify(params || {});

      const options: https.RequestOptions = {
        host: Config.API_URL,
        path: `${endpoint}${queryString}`,
        method: 'DELETE',
        rejectUnauthorized: false,
        headers: this.getHeaders(authToken),
      };

      const request = https.request(options, (response: IncomingMessage) => {
        if (response.statusCode < 200 || response.statusCode > 299) {
          return reject(
            // tslint:disable-next-line:max-line-length
            new AppError(
              `API Responded with: ${
                response.statusCode
              } for DELETE ${endpoint}, ${JSON.stringify(
                params,
                null,
                0
              )}, with auth: ${!!authToken}`
            )
          );
        }
        // temporary data holder
        const body = [];
        // on every content chunk, push it to the data array
        response.on('data', (chunk) => body.push(chunk));
        // we are done, resolve promise with those joined chunks
        response.on('end', () => resolve(JSON.parse(body.join(''))));
      });
      // handle connection errors of the request
      request.on('error', (err: Error) => reject(err));
    });
  }

  getHeaders = (authToken?: string): OutgoingHttpHeaders => {
    const defaultHeaders: OutgoingHttpHeaders = {
      Accept: 'application/json',
    } as OutgoingHttpHeaders;

    if (authToken) {
      defaultHeaders.Authorization = `JWT ${authToken}`;
    }
    return defaultHeaders;
  };
}
