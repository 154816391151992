import {
  ReportTypes,
  HireMovementReportSearchFilter,
} from '@waracle/gap-sdk';
import { createSelector } from '@ngrx/store';

import { getReportManagementState } from '../../_state/report-management.selectors';
import { IReportManagementState } from '../../_state/report-management.model';

import {
  getReportActionTypes,
  getReportActions,
  ReportAction,
} from '../../_state/generic/actions';
import { getInitialReportState, IReportState } from '../../_state/generic/state';
import { IReportDTO } from '../../_state/generic/model';
import { IReportFilterOptions, SupportedFilterTypes } from '../../_state/generic/filter';
import { createReportReducers } from '../../_state/generic/reducers';
import { createReportEntityAdapter, IReportEntityAdapter } from '../../_state/generic/adapter';
import { IReportEntitySelectors } from '../../_state/generic/selectors';
import { createFilterOption } from '../../_filters/_factories';
import { hireReportFilterOptions, hireCustomFilterOptions, offHireDateToFilter } from '../../_config/hire-report';

export const additionalFilterOptions = [
  ...hireReportFilterOptions,
  'hireMovementType',
  'offHireDateTo',
];

export interface IHireMovementReport extends IReportDTO {
  reportType: ReportTypes.HIRE_MOVEMENT;
  hireMovementType?: string;
  lineType: number;
  terminatedReasonCode: string;
  createdReasonCode: string;
  offHireDate: string;
  onHireDate: string;
  quantityOnHire: number;
}

export const HIRE_MOVEMENT_ACTION_TYPES = getReportActionTypes('hire-movement');

export const HIRE_MOVEMENT_ACTIONS = getReportActions<IHireMovementReport>(HIRE_MOVEMENT_ACTION_TYPES);

const hireMovementTypeFilter = createFilterOption('hireMovementType', SupportedFilterTypes.DROPDOWN);

offHireDateToFilter.context.required = true;
export const customFilterOptions: IReportFilterOptions = {
  ...hireCustomFilterOptions,
  offHireDateTo: offHireDateToFilter,
  hireMovementType: hireMovementTypeFilter,
};

export const initialState: IReportState<IHireMovementReport> = getInitialReportState<IHireMovementReport>({
  filters: {
    customOptions: customFilterOptions,
    additionalOptions: additionalFilterOptions,
  },
});

const adapter: IReportEntityAdapter<IHireMovementReport, HireMovementReportSearchFilter> =
  createReportEntityAdapter<IHireMovementReport, HireMovementReportSearchFilter>();

export function reducer(
  state = initialState,
  action: ReportAction<IHireMovementReport>,
): IReportState<IHireMovementReport> {
  return createReportReducers<IHireMovementReport, HireMovementReportSearchFilter>(
    HIRE_MOVEMENT_ACTION_TYPES,
    adapter,
  )(state, action);
}

export const getReportState = createSelector(
  getReportManagementState,
  (state: IReportManagementState) => state.hireMovement,
);

export const reportSelectors: IReportEntitySelectors<IHireMovementReport, HireMovementReportSearchFilter> =
  adapter.getSelectors(getReportState);
