import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: '[gap-ui-primary-button]',
  template: '<ng-content></ng-content>',
  styles: [`:host {
    border: 0;
    color: #fff;
    padding: 12px 75px;
    transition: all .2s ease-in-out;
    font-weight: bold;
    border-radius: 3.84px;
    background-color: #E32526;
    box-shadow: 0 7px 12px 4px rgba(0, 0, 0, 0.17);
    font-size:1rem;
  }
  :host.small {
    padding: 10px 25px;
  }
  :host.alternative {
    background-color: #212C65;
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrimaryButtonComponent extends MatButton {}
