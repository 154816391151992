import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService } from '../../_services/account.service';
import { CertificatesService } from '../../_services/certificates.service';
import { SetActiveAccount } from '../../_state/app.actions';
import { IAccount, IUser, Utils, ROLE_ADMIN } from '@waracle/gap-sdk';
import { Store } from '@ngrx/store';
import { UserService } from '../../_services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestNewUserComponent } from '../../_modals/request-new-user/request-new-user.component';
import { PasswordResetComponent } from '../../_modals/password-reset/password-reset.component';

@Component({
  selector: 'app-admin-account',
  templateUrl: './admin-account.component.html',
  styleUrls: ['./admin-account.component.scss'],
})
export class AdminAccountComponent implements OnInit {
  private _user: IUser;
  public accounts: IAccount[];

  public certificatesUrl$: Observable<string | null>;

  // closeResult: string;

  constructor(
    private _store: Store<any>,
    private _modal: NgbModal,
    private _accountService: AccountService,
    private _userService: UserService,
    private _certificatesService: CertificatesService
  ) {
    this._user = this._userService.me();
    this.accounts = this._accountService.accounts;
    this.certificatesUrl$ = this._certificatesService.getCertificatesUrl();
  }

  private _activeAccount: IAccount;

  get activeAccount() {
    return this._activeAccount;
  }

  get isAdminOrGreater() {
    return Utils.isUserAllowedAccess(this.user, ROLE_ADMIN);
  }

  requestUser(user: IUser) {
    if (this.isAdminOrGreater) {
      // if user is admin+ don't open the modal
      return;
    }
    const ref = this._modal.open(RequestNewUserComponent, {
      centered: true,
      size: 'lg',
    });
    ref.componentInstance.user = user;
  }

  requestPwReset(user: IUser) {
    const ref = this._modal.open(PasswordResetComponent, {
      centered: true,
      windowClass: 'reset-password',
    });
    ref.componentInstance.user = user;
  }

  ngOnInit() {
    this._accountService.activeAccount$.subscribe(
      (account: IAccount) => (this._activeAccount = account)
    );
  }

  get user(): IUser {
    return this._user;
  }

  onChangeActiveAccount(account: string) {
    this._store.dispatch(new SetActiveAccount(account));
  }

  /**
   * Temporary hack for account NBIW0002 to display additional information for demo
   *
   * @deprecated
   */
  get showKeyContactDetails(): boolean {
    return this.activeAccount && this.activeAccount.accountId === 'NBIW0002';
  }
}
