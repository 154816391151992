import { DateFilterModel } from '../_filters/date/date-filter.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SupportedFilterTypes } from '../_state/generic/filter';
import { Validators } from '@angular/forms';
import { createFilterOption } from '../_filters/_factories';

export const sharedReportFilters = [
  'orderNumber',
  'contractNumber',
  'customerNumber',
];

export const basicStringRegex = /^[^\*,^\,]*$/;

export const orderNumberFilter = createFilterOption(
  'orderNumber',
  SupportedFilterTypes.STRING
);
orderNumberFilter.context.addValidator(Validators.pattern(basicStringRegex));
orderNumberFilter.context.addValidator(Validators.minLength(4));

export const contractNumberFilter = createFilterOption(
  'contractNumber',
  SupportedFilterTypes.CHOICE
);
contractNumberFilter.context.addValidator(
  Validators.pattern(/^[A-Z][0-9]{6}$/)
);

export const customerNumberFilter = createFilterOption(
  'customerNumber',
  SupportedFilterTypes.CHOICE
);

export const sharedCustomFilterOptions = {
  orderNumber: orderNumberFilter,
  contractNumber: contractNumberFilter,
  customerNumber: customerNumberFilter,
};
