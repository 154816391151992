import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-footer',
  templateUrl: './dash-footer.component.html',
  styleUrls: ['./dash-footer.component.scss'],
})
export class DashFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  currentYear(){
      return new Date().getUTCFullYear();
  }

}
