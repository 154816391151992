import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  private _menuIsOpen: boolean = false;
  get menuIsOpen() {
    return this._menuIsOpen;
  }

  toggleSidebar(_: boolean) {
    this._menuIsOpen = !this._menuIsOpen;
  }

}
