import {
  ITextSearchFilter,
  TextSearchFilter,
} from './textSearch.filter';

export interface IUserSearchFilter extends ITextSearchFilter {
  email: string;
  role: string;
  username: string;
}

export class UserSearchFilter extends TextSearchFilter implements IUserSearchFilter {

  constructor(limit: number = 25, page: number = 1) {
    // deliberate non-checking of type so '0' can be coerced into 0
    super(!limit ? 25 : Math.min(limit, 50), page);
    this.enabled = undefined;
  }

  private _email: string;
  private _role: string;
  private _accountId: string;
  private _username: string;

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = value.toUpperCase();
    }
    this._role = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = value.toLowerCase();
    }
    this._email = value;
  }

  get accountId(): string {
    return this._accountId;
  }

  set accountId(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = value.toLowerCase();
    }
    this._accountId = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  public toJS(): ITextSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        accountId: this.accountId,
        email: this.email,
        role: this.role,
        username: this.username,
      },
    );
  }
}
