import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkTableModule } from '@angular/cdk/table';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { NavGroupComponent } from './nav-group/nav-group.component';
import { MinorNavItemComponent } from './nav-group/minor-nav-item/minor-nav-item.component';
import { MajorNavItemComponent } from './nav-group/major-nav-item/major-nav-item.component';
import { PrimaryButtonComponent } from './buttons/primary-button.component';
import { ClearButtonComponent } from './buttons/clear-button.component';
import { ReportCardComponent } from './cards/report-card.component';
import { InsightCardComponent } from './cards/insight-card.component';
import { ChipsComponent } from './chips/chips.component';
import { IconicCardComponent } from './cards/iconic-card.component';
import { ScoreCardComponent } from './cards/score-card.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ProgressComponent } from './progress/progress.component';
import { LookAheadComponent } from './look-ahead/look-ahead.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    CdkTableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [
    NavGroupComponent,
    MinorNavItemComponent,
    MajorNavItemComponent,
    PrimaryButtonComponent,
    ClearButtonComponent,
    ReportCardComponent,
    InsightCardComponent,
    IconicCardComponent,
    ScoreCardComponent,
    ChipsComponent,
    SpinnerComponent,
    ProgressComponent,
    LookAheadComponent,
  ],
  exports: [
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    CdkTableModule,
    NgbModule,
    NgMultiSelectDropDownModule,
    NavGroupComponent,
    MinorNavItemComponent,
    MajorNavItemComponent,
    PrimaryButtonComponent,
    ClearButtonComponent,
    ReportCardComponent,
    InsightCardComponent,
    IconicCardComponent,
    ScoreCardComponent,
    ChipsComponent,
    SpinnerComponent,
    ProgressComponent,
    LookAheadComponent,
  ],
})
export class UiKitModule { }
