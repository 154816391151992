import { Injectable, OnDestroy } from '@angular/core';
import { IInsightQuestionNode, InsightsInitialQuestionId } from '../_models';
import { INSIGHTS } from './insights.mock';
import { Observable, of, Subscription } from 'rxjs';
import {
  IReportFilterOption,
  IReportFilterOptions,
} from '../../report-management/_state/generic/filter';
import { map, switchMap, filter, take, tap } from 'rxjs/operators';
import { ReportControllerService } from '../../report-management/_services/report.controller';
import {
  ReportTypes,
  Utils,
  IInsightDTO,
  IAppResponse,
  IUser,
} from '@waracle/gap-sdk';
import { getFilterComponentByType } from '../../report-management/_filters/_factories';
import { AccountService } from '../../_services/account.service';
import { ApiService } from '../../_services/api.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { IAccount } from '../../user-management/_state/accounts/account.model';
import * as deepmerge from 'deepmerge';
import { environment } from '../../../environments/environment';

const SUM_OF_INVOICE_AMOUNT = 'transactions_hire_charges';

@Injectable({
  providedIn: 'root',
})
export class InsightsService implements OnDestroy {
  source$ = of(INSIGHTS);
  isParentAccount: boolean = false;

  private _accountId$ = this._account.activeAccount$.pipe(
    filter(Boolean),
    map((account: IAccount) => account.accountId)
  );
  private _accountSub: Subscription;

  constructor(
    private _reportCtrl: ReportControllerService,
    private _account: AccountService,
    private _auth: AuthenticationService,
    private _api: ApiService
  ) {
    this._accountSub = this._account.activeAccount$
      .pipe(filter(Boolean))
      .subscribe((account: IAccount) => {
        // need to assume the active account is a parent account one now
        this.isParentAccount = true;
      });
  }

  getQuestionById(id: string): Observable<IInsightQuestionNode> {
    return this.source$.pipe(
      map((insights) => {
        return insights.find((insight) => insight.id === id);
      })
    );
  }

  getQuestionsForInsightType(
    type: InsightsInitialQuestionId
  ): Observable<IInsightQuestionNode[]> {
    return this.source$.pipe(
      map((insights) => {
        return insights.filter((insight) => insight.insightType === type);
      })
    );
  }

  getFiltersForQuestion(id: string): Observable<IReportFilterOptions> {
    return this.getQuestionById(id).pipe(
      map((insight) => {
        const report = insight.reportType || ReportTypes.LIVE_HIRE;
        const filters =
          insight.filters
            .filter((filter) => {
              switch (filter.id) {
                case 'customerNumber':
                  return this.isParentAccount;
                default:
                  return true;
              }
            })
            .map((filter) => {
              return {
                context: filter,
                component: getFilterComponentByType(filter.type),
              };
            })
            .reduce((acc, next) => {
              acc[next.context.id] = next;
              return acc;
            }, {}) || {};
        return this._reportCtrl.getMappedOptions(report, filters);
      })
    );
  }

  getFilterForQuestionById(
    question: string,
    id: string
  ): Observable<IReportFilterOption> {
    return this.getFiltersForQuestion(question).pipe(
      map((filters: IReportFilterOptions) => {
        return filters[id];
      })
    );
  }

  runQuery(question: string, props: { [k: string]: string }) {
    return this.source$.pipe(
      map((insights) => {
        return insights.find((insight) => insight.id === question);
      }),
      map((insight) => {
        return {
          ...insight,
          reportType: insight.reportType || ReportTypes.LIVE_HIRE,
        };
      }),
      map((insight) => {
        return insight;
      }),
      switchMap((insight) => {
        const search = {
          ...insight.baseQuery,
        };
        for (const key in props) {
          search[key] = props[key];
        }
        return this._reportCtrl
          .runQueryForType(insight.reportType, search)
          .pipe(
            map((response) => {
              if (insight.id === SUM_OF_INVOICE_AMOUNT) {
                return deepmerge(response, {
                  rows: {
                    count: response.rows.results
                      .reduce((count, next) => {
                        return count + (next as any).invoiceAmount;
                      }, 0)
                      .toFixed(2),
                  },
                });
              }
              return response;
            })
          );
      })
    );
  }

  pinInsight(insight: IInsightDTO) {
    return this._accountId$.pipe(
      take(1),
      switchMap((activeAccount) => {
        return this._api
          .put<IAppResponse<IUser>>('/v1/user/insights/', {
            ...insight,
            customerNumber: activeAccount,
          })
          .pipe(
            take(1),
            map((response) => response.response.user),
            tap((user: IUser) => this._auth.refreshAuthenticatedUser(user))
          );
      })
    );
  }

  removeInsight(insightId: string) {
    return this._api.delete(`/v1/user/insights/${insightId}`).pipe(take(1));
  }

  automateInsight(insightId: string) {
    return this._api
      .post<IAppResponse<IUser>>(`/v1/user/insights/${insightId}`, {
        automateOn: environment.automationCron, // might need to make this dynamic in the future
      })
      .pipe(
        take(1),
        map((response) => response.response.user),
        tap((user: IUser) => this._auth.refreshAuthenticatedUser(user))
      );
  }

  removeAutomation(insightId: string) {
    return this._api.post(`/v1/user/insights/${insightId}`, {}).pipe(take(1));
  }

  ngOnDestroy() {
    this._accountSub.unsubscribe();
  }
}
