import { AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ValidatorParams } from '@waracle/gap-extranet/angular-validation';
import { Observable, of } from 'rxjs';
import { map, catchError, startWith, debounceTime } from 'rxjs/operators';

export function hasNUppercaseValidator(limit:number = 1): ValidatorFn {
  return (control: AbstractControl): ValidatorParams => {
    const regex = new RegExp(`[A-Z]{${limit},}`);
    return regex.test(control.value) ? null :
      {
        uppercase: {
          value: control.value,
        },
      };
  };
}

export function hasNLowercaseValidator(limit:number = 1): ValidatorFn {
  return (control: AbstractControl): ValidatorParams => {
    const regex = new RegExp(`[a-z]{${limit},}`);
    return regex.test(control.value) ? null :
      {
        lowercase: {
          value: control.value,
        },
      };
  };
}

export function hasNNumberValidator(limit:number = 1): ValidatorFn {
  return (control: AbstractControl): ValidatorParams => {
    const regex = new RegExp(`[0-9]{${limit},}`);
    return regex.test(control.value) ? null :
      {
        numeric: {
          value: control.value,
        },
      };
  };
}

export class ExistingPasswordChecker {
  constructor(private _validator: (email: string, password: string) => Observable<boolean>) {}
  run(email: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidatorParams> => {
      control.markAsPending();
      const value = control.value;
      const error = { validPassword: true };
      if (!value || value.trim().length === 0) {
        return of(error);
      }
      return this._validator(email, control.value)
        .pipe(
            startWith(error),
            debounceTime(500),
            map((res) => res ? null : error),
            catchError((err) => of(error)),
        );
    };
  }
}
