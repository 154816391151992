import { Injectable } from '@angular/core';
import { IAccount } from '../user-management/_state/accounts/account.model';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchFilter, ISearchResponse, IAppResponse } from '@waracle/gap-sdk';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as queryString from 'querystring';
import * as _ from 'lodash';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private _api: ApiService,
    private _userService: UserService,
    private _authService: AuthenticationService) {
    this._authService.authChange.subscribe((isAuth) => {
      if (isAuth) {
        this.reset();
      }
    });
    this.reset();
  }

  private _activeAccount: BehaviorSubject<IAccount> = new BehaviorSubject(null);
  private _accounts: IAccount[];

  public activeAccount$ = this._activeAccount.asObservable();

  /**
   * Method to set the currently active account
   * @param accountId The unique identifer for the new active account
   */
  updateActiveAccount(accountId: string) {
    this._activeAccount.next(this.accounts.find((account) => account.id === accountId));
  }

  get accounts(): IAccount[] {
    return this._accounts;
  }

  search(filter?: SearchFilter): Observable<ISearchResponse<IAccount>> {
    const qs = filter ? queryString.stringify(_.omitBy(filter.toJS(), _.isUndefined)) : '';
    return this._api.get<IAppResponse<ISearchResponse<IAccount>>>(`/v1/account/?${qs}`)
      .pipe(
        map((data) => data.response.users),
      );
  }

  reset() {
    try {
      this._accounts = this._userService.me().accounts;
    }
    catch (e) {
      this._accounts = [];
    }

    if (this._accounts && this._accounts.length > 0) {
      // set previously active account
      let activateAccount: IAccount;
      if (this._activeAccount.value) {
        activateAccount = this._accounts.find((account) => account.id === this._activeAccount.value.id);
      }
      this._activeAccount.next(activateAccount || this._accounts[0]);
    }
    else {
      this._activeAccount.next(null);
    }
  }
}
