import * as cronParser from 'cron-parser';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';

export class Insight {
  public details: string;
  constructor(
    public id: string,
    public description: string,
    public date?: string, // cron date
    public selected: boolean = false,
  ) {
    if (date) {
      const parsed = cronParser.parseExpression(date);
      const interval = parsed.next();
      this.details = formatDate(interval.toDate(), environment.dateFormat, 'en-US');
    }
  }
}
