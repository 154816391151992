import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfigurableModal } from '..';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorModalComponent implements IConfigurableModal {
  @Input() title: string;
  @Input() description: string;
  @Input() cancel: string;
  @Input() confirm: string;
  @Input() params: any;
  constructor(public activeModal: NgbActiveModal) {}
  setUp(params: any = {}) {
    this.title = 'ERROR_MODAL.TITLE';
    this.description = 'ERROR_MODAL.DESCRIPTION';
    this.cancel = 'ERROR_MODAL.CANCEL';
    this.confirm = 'ERROR_MODAL.CONFIRM';
    this.params = params;
  }
}
