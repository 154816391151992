import * as queryString from 'querystring';
import * as _ from 'lodash';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IReportSearchFilter } from './model';

export function getActiveReportQueryString(filter?: IReportSearchFilter): string {
  return filter ? queryString.stringify(_.omitBy(filter.toJS(), _.isUndefined)) : '';
}

export function getCacheQueryString(filter?: IReportSearchFilter): string {
  return stripFromQueryString(
    getActiveReportQueryString(filter)
  , 'page');
}

export function getPageFromQueryString(qs: string): number {
  const query = queryString.parse(qs);
  return parseInt(query.page as string, 10) || 1;
}

export function stripFromQueryString(qs: string, key: string): string {
  const query = queryString.parse(qs);
  delete query[key];
  return queryString.stringify(query);
}

export type PartialReportState<T> = {
  [P in keyof T]?: PartialReportState<T[P]>;
};

export function genericAscSort(a, b): number {
  return a === b ? 0 : (a > b || b === null) ? 1 : -1;
}

export function genericDescSort(a, b): number {
  return -1 * genericAscSort(a, b);
}

export function ngbDateToISO(date: NgbDate | NgbDateStruct): string {
  return new Date(Date.UTC(date.year, (date.month - 1) , date.day)).toISOString();
}

export function iSOtoNgbDate(iso: string): NgbDate {
  const date = new Date(iso);
  return new NgbDate(
    date.getUTCFullYear(),
    date.getUTCMonth() + 1,
    date.getUTCDate(),
  );
}
