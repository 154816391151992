import { IReport } from '../report.model';

export enum InvoiceTypes {
  HIRE = 'hire',
  LOSS = 'loss',
  MANUAL = 'manual',
  NONE = 'none',
}

export enum ChargeTypes {
  HIRE = 'hire',
  TRANSPORT = 'transport',
  CLEAN = 'clean',
  FUEL = 'fuel',
  SALE = 'sale',
  DAMAGE = 'damage',
  NONE = 'none', // valid charge type
}

export interface IInvoice extends IReport {
  additionalCharge: number;
  addressLine3: string;
  addressLine4: string;
  customerName: string;
  customerOrderReference: string;
  damageDetails: string;
  /**
   * @deprecated
   */
  ecode?: string;
  fleetNumber: string;
  invoiceAmount: number;
  invoiceDate: string;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  invoiceNumber: string;
  invoiceType: string;
  item: string;
  itemDescription: string;
  itemName: string;
  itemType: string;
  lineNumber: number;
  lineText: string;
  lineType: string;
  offHireDate: string;
  payer: string;
  postalCode: string;
  productCode: string;
  quantity: number;
  queryCode: string;
  queryCodeDescription: string;
  queriedDate?: string;
  serviceItem: string;
  vatAmount: number;
  vatRate: number;
  weekRate: number;
  weeksCharged: number;
}
