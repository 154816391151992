import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent {

  @Output() menuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  closeMenu() {
    this.menuClosed.emit(false);
  }
}
