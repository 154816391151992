import { Type } from '@angular/core';
import {
  SupportedFilterTypes,
  IReportFilterContext,
  IReportFilterInput,
  IReportFilterOption,
  IReportFilterContextDTO,
} from '../_state/generic/filter';
import { DefaultFilterModel } from './default/default-filter.model';
import { DefaultInputComponent } from './default/default.component';
import { DateFilterModel } from './date/date-filter.model';
import { CurrencyFilterModel } from './currency/currency-filter.model';
import { NumberFilterModel } from './number/number-filter.model';
import { DateInputComponent } from './date/date.component';
import { CurrencyInputComponent } from './currency/currency.component';
import { NumberInputComponent } from './number/number.component';
import { ChoiceInputComponent } from './choice/choice.component';
import { ChoiceFilterModel } from './choice/choice-filter.model';
import { DropdownInputComponent } from './dropdown/dropdown.component';
import { DropdownFilterModel } from './dropdown/dropdown-filter.model';

export function rehydrateFilterContext(filterContextDTO: IReportFilterContextDTO): IReportFilterContext {
  const contextConstructor = getFilterModelByType(filterContextDTO.type);
  const context = new contextConstructor(filterContextDTO.id);
  return Object.assign(context, filterContextDTO);
}

export function getFilterModelByType(type: SupportedFilterTypes):
  new (label: string, type?: SupportedFilterTypes) => IReportFilterContext {
  switch (type) {
    case SupportedFilterTypes.NUMBER:
      return NumberFilterModel;
    case SupportedFilterTypes.CURRENCY:
      return CurrencyFilterModel;
    case SupportedFilterTypes.DATE:
      return DateFilterModel;
    case SupportedFilterTypes.CHOICE:
      return ChoiceFilterModel;
    case SupportedFilterTypes.DROPDOWN:
      return DropdownFilterModel;
    default:
      return DefaultFilterModel;
  }
}

export function getFilterComponentByType(type: SupportedFilterTypes):
  Type<IReportFilterInput> {
  switch (type) {
    case SupportedFilterTypes.NUMBER:
      return NumberInputComponent;
    case SupportedFilterTypes.CURRENCY:
      return CurrencyInputComponent;
    case SupportedFilterTypes.DATE:
      return DateInputComponent;
    case SupportedFilterTypes.CHOICE:
      return ChoiceInputComponent;
    case SupportedFilterTypes.DROPDOWN:
      return DropdownInputComponent;
    default:
      return DefaultInputComponent;
  }
}

export function createFilterOption(
  property: string,
  type: SupportedFilterTypes)
  : IReportFilterOption {
  const contextConstructor = getFilterModelByType(type);
  const context = new contextConstructor(property);
  const component = getFilterComponentByType(type);
  return {
    context,
    component,
  };
}

export function getEmptyFilter() {
  return {
    context: new DefaultFilterModel(undefined),
    component: DefaultInputComponent,
  };
}
