export interface ISearchFilter {
  enabled: boolean;
  readonly fields: string[];
  limit: number;
  page: number;
  sort: string;
}

export class SearchFilter implements ISearchFilter {

  constructor(limit: number = 100, page: number = 1, maxLimit: number = 100) {
    this._maxLimit = maxLimit;
    this.limit = limit === 0 ? 100 : limit;
    this.page = page;
    this.enabled = true;
    this._fields = [];
  }

  private _enabled: boolean;
  private _fields: string[];
  private _limit: number;
  private _maxLimit: number;
  private _page: number;
  private _sort: string;

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
  }

  get limit(): number {
    return this._limit;
  }

  set limit(value: number) {
    if (typeof value === 'string') {
      // tslint:disable-next-line:no-parameter-reassignment
      value = parseInt(value, 10);
    }

    this._limit = Math.min(value, this._maxLimit);
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    if (typeof value === 'string') {
      // tslint:disable-next-line:no-parameter-reassignment
      value = parseInt(value, 10);
    }
    if (value === 0) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = 1;
    }
    this._page = value;
  }

  get sort(): string {
    return this._sort;
  }

  set sort(value: string) {
    this._sort = value;
  }

  /**
   * Holds a list of fields to return from a Search.
   *
   * Leave blank for all
   */
  get fields(): string[] {
    return this._fields;
  }

  /**
   * Add a new field to be returned in the search results
   *
   * @param fieldName String to add to result set
   */
  addField(fieldName: string): SearchFilter {
    if (fieldName) {
      this.fields.push(fieldName);
    }
    return this;
  }

  public toJSON(): string {
    return JSON.stringify(this.toJS());
  }

  public toJS(): ISearchFilter {
    return {
      enabled: this.enabled,
      fields: this.fields,
      limit: this.limit,
      page: this.page,
      sort: this.sort,
    };

  }
}
