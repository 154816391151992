import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gap-ui-nav-group',
  template: `
    <h3 class="head-titles">
      <a *ngIf="link" [routerLink]="link">{{title}}</a>
      <ng-container *ngIf="!link">{{title}}</ng-container>
    </h3>
    <ul [ngClass]="showBorder?'bordered':''">
      <ng-content></ng-content>
    </ul>
  `,
  styles: [
    `h3 {
      font-size: 1.1rem;
      margin-bottom: 1.0rem;
      font-weight: 600;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      margin: 0rem 0 2rem;
    }
    ul.bordered {
      border-left: 5px solid #5D6CC7;
    }
    @media screen and (max-width: 991px) {
      h3 {
        font-size: 1.0rem;
      }
    }`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavGroupComponent implements OnInit {

  @Input() title: string = 'Nav Group';
  @Input() link: string | null = null;
  @Input() showBorder: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
