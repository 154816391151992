import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { take, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ISearchResponse, IDepot, IAppResponse } from '@waracle/gap-sdk';

@Injectable({
  providedIn: 'root',
})
export class DepotService {
  constructor(private _api: ApiService) {
  }
  search(): Observable<IDepot[]> {
    return this._api.get<IAppResponse<ISearchResponse<IDepot>>>('/v1/depot').pipe(
      take(1),
      map((data) => {
        return data.response.depots.results;
      }),
      catchError((err) => of([])),
    );
  }
}
