import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfigurableModal, IModalConfig } from '..';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, IConfigurableModal {
  @Input() title: string;
  @Input() description: string;
  @Input() cancel: string;
  @Input() confirm: string;
  @Input() params: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

  setUp(translationPrefix: string, params: any = {}) {
    this.title = `${translationPrefix}.TITLE`;
    this.description = `${translationPrefix}.DESCRIPTION`;
    this.cancel = `${translationPrefix}.CANCEL`;
    this.confirm = `${translationPrefix}.CONFIRM`;
    this.params = params;
  }

}
