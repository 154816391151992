import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as deepmerge from 'deepmerge';

import { IReportPaginationState, initialReportPaginationState } from './pagination';
import { IReportFilterState, initialReportFilterState } from './filter';
import { IReportSortState, getInitialReportSortState } from './sort';
import { IReportDTO } from './model';
import { IReportHistoryState } from './history';
import { PartialReportState } from './helpers';

export interface IReportState<T extends IReportDTO> extends EntityState<T> {
  pagination: IReportPaginationState;
  filters: IReportFilterState;
  sort: IReportSortState<T>;
  history: IReportHistoryState;
  loading: boolean;
  processing: boolean;
  activeAccount?: string;
}

export function getInitialReportState<T extends IReportDTO>(
    state: PartialReportState<IReportState<T>> = {},
  ): IReportState<T> {
  const adapter = createEntityAdapter<T>();
  const initialState = adapter.getInitialState({
    pagination: initialReportPaginationState,
    filters: initialReportFilterState,
    sort: getInitialReportSortState<T>(),
    history: {
      logs: [],
    },
    loading: false,
    processing: false,
  });
  return deepmerge(initialState, state);
}
