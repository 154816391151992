import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { UserSearchFilter, IUser } from '@waracle/gap-sdk';

export enum UserActionTypes {
  FETCH_USERS = '[User] Fetch Users',
  FETCH_USERS_FAILED = '[User] Fetch Users Failed',
  LOAD_USERS = '[User] Load Users',
  ADD_USER = '[User] Add User',
  UPDATE_USER = '[User] Update User',
  UPDATE_USERS = '[User] Update Users',
  DELETE_USER = '[User] Delete User',
  DELETE_USERS = '[User] Delete Users',
  CLEAR_USERS = '[User] Clear Users',
  FETCH_USER = '[User] Fetch Single User',
}

export class FetchUsers implements Action {
  readonly type = UserActionTypes.FETCH_USERS;

  constructor(public payload?: UserSearchFilter) {}
}

export class FetchUsersFailed implements Action {
  readonly type = UserActionTypes.FETCH_USERS_FAILED;

  constructor(public payload: HttpErrorResponse) {}
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LOAD_USERS;

  constructor(public payload: { users: IUser[], total: number }) {}
}

export class AddUser implements Action {
  readonly type = UserActionTypes.ADD_USER;

  constructor(public payload: { user: IUser }) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UPDATE_USER;

  constructor(public payload: { user: Update<IUser> }) {}
}

export class UpdateUsers implements Action {
  readonly type = UserActionTypes.UPDATE_USERS;

  constructor(public payload: { users: Update<IUser>[] }) {}
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DELETE_USER;

  constructor(public payload: { id: string }) {}
}

export class DeleteUsers implements Action {
  readonly type = UserActionTypes.DELETE_USERS;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearUsers implements Action {
  readonly type = UserActionTypes.CLEAR_USERS;
}

export class FetchUser implements Action {
  readonly type = UserActionTypes.FETCH_USER;

  constructor(public payload?:  string) {}
}

export type UserActions =
 LoadUsers
 | AddUser
 | UpdateUser
 | UpdateUsers
 | DeleteUser
 | DeleteUsers
 | ClearUsers
 | FetchUsers
 | FetchUsersFailed
 | FetchUser;
