import { IHireReport } from './hireReport.model';

// tslint:disable:naming-convention
export enum HireMovementTypes {
  SALE = 'sale',
  EXCHANGED = 'exchanged',
  OFF_HIRED = 'off_hired',
  ON_OFF_HIRED = 'on_off_hired',
}

export enum ExchangeTypes {
  BOTH = 'on_off_hired',
  ON = 'on',
  OFF = 'off',
  NONE = 'none',
}

// tslint:enable:naming-convention

export interface IHireMovement extends IHireReport {
  createdReasonCode: string;
  exchangeType: ExchangeTypes;
  hireMovementType: HireMovementTypes;
  lineType: string;
  offHireDate: string;
  pretext: string;
  quantityOnHire: number; // calculated
  quantityReturned: number;
  siteContact: string;
  terminatedReasonCode: string;
}
