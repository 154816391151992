/*
 * Public API Surface of ui-kit
 */

export * from './lib/ui-kit.module';
export * from './lib/interfaces';

// allow access from parent components via ViewChild
export {
  ChipsComponent,
} from './lib/chips/chips.component';
export {
  LookAheadComponent,
} from './lib/look-ahead/look-ahead.component';
