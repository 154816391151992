import { Injectable } from '@angular/core';
import { ROLE_USER } from '@waracle/gap-sdk';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { checkAuth } from './auth-check';

@Injectable()
export class UserAuthGuard implements CanActivate {
  protected _requiredRole = ROLE_USER;

  constructor(private _router: Router,
              private _authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return checkAuth(this._requiredRole, this._authenticationService).
      then((result) => {
        if (!result) {
          this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return result;
      });
  }
}
