import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import { appActionTypes } from '../app.actions';

export interface ISessionState {
  activeAccount?: string;
}
export interface IState {
  session: ISessionState;
}

export const initialState: IState = {
  session: {},
};

export function sessionReducer(state: ISessionState = initialState.session, action: any) {
  switch (action.type) {
    case appActionTypes.SetActive:
      return {
        ...state,
        activeAccount: action.payload,
      };
    case appActionTypes.Logout:
      return {
        ...state,
        activeAccount: undefined,
      };
    default:
      return state;
  }
}

export const reducers: ActionReducerMap<IState> = {
  session: sessionReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['session'],
    rehydrate: true,
    removeOnUndefined: true,
  })(reducer);
}
const commonMetaReducers: MetaReducer<IState>[] = [localStorageSyncReducer];

export const metaReducers: MetaReducer<IState>[] = !environment.production ?
  [...commonMetaReducers] :
    [...commonMetaReducers];
