import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[gap-ui-major-nav-item]',
  template: '<ng-content></ng-content>',
  styles: [
    `:host {
      font-size: 1rem;
      margin-bottom: 1.0rem;
      padding-left: 0px;
      transition: border 0.2s ease, padding 0.2s linear;
    }
    :host:hover, :host.active {
      padding-left: 15px;
      border-left: 5px solid #5D6CC7;
    }`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MajorNavItemComponent {}
