import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { environment } from '../../environments/environment';

/*
 * Prefix a path with the CDN URL
 * Takes no arguments
 * Usage:
 *   '/absolute/path/to/file.jpg' | cdnPath
 *   'relative/path/to/file.jpg' | cdnPath
*/
@Pipe({ name: 'cdnPath' })
export class CdnPipe implements PipeTransform {
  transform(value: string): string {
    const absolute = value.startsWith('/') ? value : `/${value}`;
    return `${environment.cdnBaseUrl}${absolute}`;
  }
}
