import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AuthenticationService } from './authentication.service';

import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';

import { ApiService } from './api.service';
import {
  IAppResponse,
  ISearchResponse,
  IUser,
  SearchFilter,
} from '@waracle/gap-sdk';
import { map, take, catchError, tap } from 'rxjs/operators';
import * as queryString from 'querystring';
import { IUserService } from './user.service.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService implements IUserService {

  totalUserCounter$ = new BehaviorSubject<number>(0);

  constructor(
    private _api: ApiService,
    private _authenticationService: AuthenticationService) {}

  search(filter?: SearchFilter): Observable<ISearchResponse<IUser>> {
    const qs = filter ? queryString.stringify(_.omitBy(filter.toJS(), _.isUndefined)) : '';
    return this._api.get<IAppResponse<ISearchResponse<IUser>>>(`/v1/user/?${qs}`)
      .pipe(
        take(1),
        map((data) => {
          if (!filter) {
            this.totalUserCounter$.next(data.response.users.total);
          }
          return data.response.users;
        }),
      );
  }

  isEmailAvailable(email: string): Observable<boolean> {
    return this._api.get<IAppResponse<{enabled: boolean}>>(`/v1/register/${email}`)
      .pipe(
        take(1),
        map((_) => {
          return true;
        }),
        catchError((_) => {
          return of(false);
        }),
      );
  }

  getById(_id: string): Observable<IUser> {
    return this._api.get<IAppResponse<IUser>>(`/v1/user/${_id}`)
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  create(user: Partial<IUser>): Observable<IUser> {
    return this._api.put<IAppResponse<IUser>>('/v1/register', { ...user, allowRoleSetting: true })
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  meAsync(): Observable<IUser> {
    return this._api.get<IAppResponse<IUser>>('/v1/user/me')
      .pipe(
        take(1),
        map((data) => data.response.user),
        tap((user: IUser) => this._authenticationService.refreshAuthenticatedUser(user)),
      );
  }

  me(): IUser {
    try {
      return this._authenticationService.authentication.user;
    }
    catch (e) {
      this._authenticationService.logout();
    }
  }

  update(_id: string, user: Partial<IUser>): Observable<IUser> {
    return this._api.post<IAppResponse<IUser>>(`/v1/user/${_id}`, user)
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  delete(_id: string): Observable<IUser> {
    return this._api.delete<IAppResponse<IUser>>(`/v1/user/${_id}`)
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  assignAccount(_id: string, _account: string): Observable<IUser> {
    return this._api.put<IAppResponse<IUser>>(`/v1/user/${_id}/account/${_account}`, {})
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  unassignAccount(_id: string, _account: string): Observable<IUser> {
    return this._api.delete<IAppResponse<IUser>>(`/v1/user/${_id}/account/${_account}`)
      .pipe(
        take(1),
        map((data) => data.response.user),
      );
  }

  updatePassword(_id: string, password: string): Observable<IUser> {
    return this.update(_id, { password });
  }

  requestNew(request: Partial<IUser>): Observable<any> {
    return this._api.put('/v1/user/request-new', {
      firstName: request.firstName,
      lastName: request.lastName,
      accounts: request.accounts,
      contactNumber: request.contactNumber,
      email: request.email,
    }).pipe(
      take(1),
    );
  }
}
