import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { IReportFilter } from './filter';
import { IReportSort } from './sort';
import { IReportDTO } from './model';

export const enum REPORT_ACTION_TYPES {
  REPORT_INIT = 'ReportInit',

  REPORT_UI_PAGE_TRANSITION = 'ReportUiPageTransition',
  REPORT_UI_VIEW_SIZE_CHANGE = 'ReportUiViewSizeChange',
  REPORT_API_PAGE_TRANSITION = 'ReportApiPageTransition',

  REPORT_ADD_FILTER = 'ReportAddFilter',
  REPORT_VALIDATE_FILTERS = 'ReportValidateFilters',
  REPORT_APPLY_FILTERS = 'ReportApplyFilters',
  REPORT_REMOVE_FILTER = 'ReportRemoveFilter',
  REPORT_CLEAR_FILTERS = 'ReportClearFilters',

  REPORT_SORT_CHANGE = 'ReportSortChange',
  REPORT_FETCH_SORT_DATA = 'ReportFetchSortData',
  REPORT_FETCH_SORT_COMPLETE = 'ReportFetchSortComplete',

  REPORT_FETCH_AUTOCOMPLETE = 'ReportFetchAutocomplete',
  REPORT_FETCH_AUTOCOMPLETE_COMPLETE = 'ReportFetchAutocompleteComplete',
  REPORT_FETCH_AUTOCOMPLETE_FAILED = 'ReportFetchAutocompleteFailed',

  REPORT_FETCH_DATA = 'ReportFetchData',
  REPORT_FETCH_DATA_FAILED = 'ReportFetchDataFailed',
  REPORT_LOAD_DATA = 'ReportLoadData',
  REPORT_CLEAR_DATA = 'ReportClearData',

  REPORT_SET_ACTIVE_ACCOUNT = 'ReportSetActiveAccount',

  REPORT_RESET_STATE = 'ReportResetState',
}

export type ReportActionTypes = {
  [k in REPORT_ACTION_TYPES]: string;
};

export type ReportActions<T extends IReportDTO> = {
  ReportInit: new (payload?: any) => IReportInit,

  ReportUiPageTransition: new (payload?: any) => IReportUiPageTransition,
  ReportUiViewSizeChange: new (payload?: any) => IReportUiViewSizeChange,
  ReportApiPageTransition: new (payload?: any) => IReportApiPageTransition,

  ReportAddFilter: new (payload?: any) => IReportAddFilter,
  ReportValidateFilters: new (payload: any) => IReportValidateFilters,
  ReportApplyFilters: new (payload?: any) => IReportApplyFilters,
  ReportRemoveFilter: new (payload?: any) => IReportRemoveFilter,
  ReportClearFilters: new (payload?: any) => IReportClearFilters,

  ReportSortChange: new (payload?: any) => IReportSortChange<T>,
  ReportFetchSortData: new (payload?: any) => IReportFetchSortData<T>,
  ReportFetchSortComplete: new (payload?: any) => IReportFetchSortComplete<T>,

  ReportFetchAutocomplete: new (payload?: any) => IReportFetchAutocomplete,
  ReportFetchAutocompleteComplete: new (payload?: any) => IReportFetchAutocompleteComplete,
  ReportFetchAutocompleteFailed: new (payload?: any) => IReportFetchAutocompleteFailed,

  ReportFetchData: new (payload?: any) => IReportFetchData,
  ReportFetchDataFailed: new (payload?: any) => IReportFetchDataFailed,
  ReportLoadData: new (payload?: any) => IReportLoadData<T>,
  ReportClearData: new (payload?: any) => IReportClearData,

  ReportSetActiveAccount: new (payload?: any) => IReportSetActiveAccount,

  ReportResetState: new (payload?: any) => IReportResetReportState,
};

export function getReportActionTypes(namespace: string): ReportActionTypes {
  return {
    ReportInit: `[Report] [${namespace}] Initialise Report Page`,

    ReportUiPageTransition: `[Report] [${namespace}] Change Report Pagination View Page`,
    ReportUiViewSizeChange: `[Report] [${namespace}] Change Report Pagination View Size`,
    ReportApiPageTransition: `[Report] [${namespace}] Change Report API Pagination`,

    ReportAddFilter: `[Report] [${namespace}] Add Report Filter`,
    ReportValidateFilters: `[Report] [${namespace}] Validate Report Filters`,
    ReportApplyFilters: `[Report] [${namespace}] Apply Report Filters`,
    ReportRemoveFilter: `[Report] [${namespace}] Remove Report Filter`,
    ReportClearFilters: `[Report] [${namespace}] Clear Report Filters`,

    ReportSortChange: `[Report] [${namespace}] Sort Reports`,
    ReportFetchSortData: `[Report] [${namespace}] Sort Reports Fetch`,
    ReportFetchSortComplete: `[Report] [${namespace}] Sort Reports Completed`,

    ReportFetchAutocomplete: `[Report] [${namespace}] Fetch Report Autocomplete`,
    ReportFetchAutocompleteComplete: `[Report] [${namespace}] Fetch Report Autocomplete done`,
    ReportFetchAutocompleteFailed: `[Report] [${namespace}] Fetch Report Autocomplete failed`,

    ReportFetchData: `[Report] [${namespace}] Fetch Reports`,
    ReportFetchDataFailed: `[Report] [${namespace}] Fetch Reports Failed`,
    ReportLoadData: `[Report] [${namespace}] Load Reports`,
    ReportClearData: `[Report] [${namespace}] Clear Reports`,

    ReportSetActiveAccount: `[Report] [${namespace}] Set Active Account`,

    ReportResetState: `[Report] [${namespace}] Reset All Report State`,
  };
}

export function getReportActions<T extends IReportDTO>(types: ReportActionTypes): ReportActions<T> {
  return {
    ReportInit: class implements IReportInit {
      readonly type: string = types.ReportInit;
    },

    ReportUiPageTransition: class implements IReportUiPageTransition {
      readonly type: string = types.ReportUiPageTransition;
      constructor(public payload: number) {}
    },
    ReportUiViewSizeChange: class implements IReportUiViewSizeChange {
      readonly type: string = types.ReportUiViewSizeChange;
      constructor(public payload: number) {}
    },
    ReportApiPageTransition: class implements IReportApiPageTransition {
      readonly type: string = types.ReportApiPageTransition;
      constructor(public payload: number) {}
    },

    ReportAddFilter: class implements IReportAddFilter {
      readonly type: string = types.ReportAddFilter;
      constructor(public payload: IReportFilter) {}
    },

    ReportValidateFilters: class implements IReportValidateFilters {
      readonly type: string = types.ReportValidateFilters;
      constructor(public payload: IReportFilter[]) {}
    },

    ReportApplyFilters: class implements IReportApplyFilters {
      readonly type: string = types.ReportApplyFilters;
      constructor(public payload: IReportFilter[]) {}
    },

    ReportRemoveFilter: class implements IReportRemoveFilter {
      readonly type: string = types.ReportRemoveFilter;
      constructor(public payload: IReportFilter) {}
    },
    ReportClearFilters: class implements IReportClearFilters {
      readonly type: string = types.ReportClearFilters;
    },

    ReportSortChange: class implements IReportSortChange<T> {
      readonly type: string = types.ReportSortChange;
      constructor(public payload: IReportSort<T>) {}
    },
    ReportFetchSortData: class implements IReportFetchSortData<T> {
      readonly type: string = types.ReportFetchSortData;
      constructor(public payload: IReportSort<T>) {}
    },
    ReportFetchSortComplete: class implements IReportFetchSortComplete<T> {
      readonly type: string = types.ReportFetchSortComplete;
      constructor(public payload: IReportSort<T>) {}
    },

    ReportFetchAutocomplete: class implements IReportFetchAutocomplete {
      readonly type: string = types.ReportFetchAutocomplete;
      constructor(public payload: { property: string, value: string }) {}
    },

    ReportFetchAutocompleteComplete: class implements IReportFetchAutocompleteComplete {
      readonly type: string = types.ReportFetchAutocompleteComplete;
      constructor(public payload: string[]) {}
    },

    ReportFetchAutocompleteFailed: class implements IReportFetchAutocompleteFailed {
      readonly type: string = types.ReportFetchAutocompleteFailed;
    },

    ReportFetchData: class implements IReportFetchData {
      readonly type: string = types.ReportFetchData;
    },
    ReportFetchDataFailed: class implements IReportFetchDataFailed {
      readonly type: string = types.ReportFetchDataFailed;
      constructor(public payload: HttpErrorResponse) {}
    },
    ReportLoadData: class implements IReportLoadData<T> {
      readonly type: string = types.ReportLoadData;
      constructor(public payload: { reports: T[], total: number, query: string }) {}
    },
    ReportClearData: class implements IReportClearData {
      readonly type: string = types.ReportClearData;
    },

    ReportSetActiveAccount: class implements IReportSetActiveAccount {
      readonly type: string = types.ReportSetActiveAccount;
      constructor(public payload: { active: string }) {}
    },

    ReportResetState: class implements IReportResetReportState {
      readonly type: string = types.ReportResetState;
    },
  };
}

export interface IReportInit extends Action {}

// UI Pagination
export interface IReportUiPageTransition extends Action {
  payload: number;
}

export interface IReportUiViewSizeChange extends Action {
  payload: number;
}

// API Pagination
export interface IReportApiPageTransition extends Action {
  payload: number;
}

export type ReportPaginationActions =
  IReportUiPageTransition
  | IReportUiViewSizeChange
  | IReportApiPageTransition;

// Filters
export interface IReportAddFilter extends Action {
  payload: IReportFilter;
}

export interface IReportValidateFilters extends Action {
  payload: IReportFilter[];
}

export interface IReportApplyFilters extends Action {
  payload: IReportFilter[];
}

export interface IReportRemoveFilter extends Action {
  payload: IReportFilter;
}

export interface IReportClearFilters extends Action {}

export type ReportFilterActions =
  IReportAddFilter
  | IReportValidateFilters
  | IReportApplyFilters
  | IReportRemoveFilter
  | IReportClearFilters;

// Sorting
export interface IReportSortChange<T extends IReportDTO> extends Action {
  payload: IReportSort<T>;
}
export interface IReportFetchSortData<T extends IReportDTO> extends Action {
  payload: IReportSort<T>;
}
export interface IReportFetchSortComplete<T extends IReportDTO> extends Action {
  payload?: IReportSort<T>;
}

// Autocomplete
export interface IReportFetchAutocomplete extends Action {
  payload: { property: string, value: string };
}

export interface IReportFetchAutocompleteComplete extends Action {
  payload: string[];
}

export interface IReportFetchAutocompleteFailed extends Action {
}

// Report Data
export interface IReportFetchData extends Action {}

export interface IReportFetchDataFailed extends Action {
  payload: HttpErrorResponse;
}

export interface IReportLoadData<T> extends Action {
  payload: { reports: T[], total: number, query: string};
}

export interface IReportClearData extends Action {}

export type ReportDataActions<T> =
  IReportFetchData
  | IReportFetchDataFailed
  | IReportLoadData<T>
  | IReportClearData;

export interface IReportSetActiveAccount extends Action {
  payload: { active: string };
}

export interface IReportResetReportState extends Action {}

export type ReportAction<T extends IReportDTO> =
  IReportInit
  | ReportPaginationActions
  | ReportFilterActions
  | IReportSortChange<T>
  | ReportDataActions<T>
  | IReportSetActiveAccount
  | IReportResetReportState;
