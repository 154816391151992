import { ITextSearchFilter, TextSearchFilter } from './textSearch.filter';

export interface IReportSearchFilter extends ITextSearchFilter {
  accountName: string;
  /**
   * @deprecated
   */
  addressLine1: string;
  /**
   * @deprecated
   * @see address
   */
  addressLine2: string;
  /**
   * Search for (part of) the concatenated addressLines
   * @type {string}
   * @since 0.32.0
   */
  address: string;
  contractNumber: string;
  customerNumber: string;
  depotCode: string;
  depotName: string;
  fleetNumber: string;
  onHireDateFrom: string; // yyyy-mm-dd (ISO8601)
  onHireDateTo: string; // yyyy-mm-dd (ISO8601)
  orderNumber: string;
  price: number;
  productCode: string;
  productName: string;
  quantityDelivered: number;
  /**
   * The sub account/customer number to filter results by
   * @type {string}
   * @since 0.45.0
   */
  subAccountNumber: string;
}

export class ReportSearchFilter
  extends TextSearchFilter
  implements IReportSearchFilter
{
  constructor(
    limit: number = 1000,
    page: number = 1,
    maxLimit: number = 1000000
  ) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000000
    super(limit, page, maxLimit);
  }

  private _accountName: string;
  /**
   * @deprecated
   * @see _address
   */
  private _addressLine1: string;
  /**
   * @deprecated
   * @see _address
   */
  private _addressLine2: string;
  /**
   * Search a concatenated string of addressLines
   * @type {string}
   * @since 0.32.0
   */
  private _address: string;
  private _contractNumber: string;
  private _customerNumber: string;
  private _depotCode: string;
  private _depotName: string;
  private _fleetNumber: string;
  private _onHireDateFrom: string;
  private _onHireDateTo: string;
  private _orderNumber: string;
  private _parentAccountId: string;
  private _price: number;
  private _productCode: string;
  private _productName: string;
  private _quantityDelivered: number;
  private _subAccountNumber: string;

  get accountName(): string {
    return this._accountName;
  }

  set accountName(value: string) {
    this._accountName = value;
  }

  get addressLine1(): string {
    return this._addressLine1;
  }

  set addressLine1(value: string) {
    this._addressLine1 = value;
  }

  get addressLine2(): string {
    return this._addressLine2;
  }

  set addressLine2(value: string) {
    this._addressLine2 = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get contractNumber(): string {
    return this._contractNumber;
  }

  set contractNumber(value: string) {
    this._contractNumber = value;
  }

  get customerNumber(): string {
    return this._customerNumber;
  }

  set customerNumber(value: string) {
    this._customerNumber = value;
  }

  get depotCode(): string {
    return this._depotCode;
  }

  set depotCode(value: string) {
    this._depotCode = value;
  }

  get depotName(): string {
    return this._depotName;
  }

  set depotName(value: string) {
    this._depotName = value;
  }

  get fleetNumber(): string {
    return this._fleetNumber;
  }

  set fleetNumber(value: string) {
    this._fleetNumber = value;
  }

  get onHireDateFrom(): string {
    return this._onHireDateFrom;
  }

  set onHireDateFrom(value: string) {
    this._onHireDateFrom = value;
  }

  get onHireDateTo(): string {
    return this._onHireDateTo;
  }

  set onHireDateTo(value: string) {
    this._onHireDateTo = value;
  }

  get orderNumber(): string {
    return this._orderNumber;
  }

  set orderNumber(value: string) {
    this._orderNumber = value;
  }

  get parentAccountId(): string {
    return this._parentAccountId;
  }

  set parentAccountId(value: string) {
    this._parentAccountId = value;
  }

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  get productCode(): string {
    return this._productCode;
  }

  set productCode(value: string) {
    this._productCode = value;
  }

  get productName(): string {
    return this._productName;
  }

  set productName(value: string) {
    this._productName = value;
  }

  get quantityDelivered(): number {
    return this._quantityDelivered;
  }

  set quantityDelivered(value: number) {
    this._quantityDelivered = value;
  }

  get subAccountNumber(): string {
    return this._subAccountNumber;
  }

  set subAccountNumber(value: string) {
    this._subAccountNumber = value;
  }

  public toJS(): IReportSearchFilter {
    return Object.assign(super.toJS(), {
      accountName: this.accountName,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      address: this.address,
      contractNumber: this.contractNumber,
      customerNumber: this.customerNumber,
      depotCode: this.depotCode,
      depotName: this.depotName,
      fleetNumber: this.fleetNumber,
      onHireDateFrom: this.onHireDateFrom,
      onHireDateTo: this.onHireDateTo,
      orderNumber: this.orderNumber,
      parentAccountId: this.parentAccountId,
      price: this.price,
      productCode: this.productCode,
      productName: this.productName,
      quantityDelivered: this.quantityDelivered,
      subAccountNumber: this.subAccountNumber,
    });
  }
}
