import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-index',
  templateUrl: './dash-index.component.html',
  styleUrls: ['./dash-index.component.scss'],
})
export class DashIndexComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
