import { EntitySelectors } from '@ngrx/entity/src/models';
import { IReportDTO, IReportSearchFilter } from './model';
import { IReportState } from './state';
import {
  IReportPaginationState,
  IReportUiPaginationState,
  IReportApiPaginationState,
} from './pagination';
import {
  IReportSortState,
  SortDirections,
  getInitialReportSortState,
} from './sort';
import {
  IReportFilterState,
  IReportFilter,
  IReportFilterOption,
  IReportFilterOptions,
  isRangeFilter,
  IReportFilterContext,
} from './filter';
import { IReportHistoryState } from './history';
import { createSelector } from '@ngrx/store';
import { Utils } from '@waracle/gap-sdk';
import {
  getCacheQueryString,
  genericAscSort,
  genericDescSort,
} from './helpers';
import { rehydrateFilterContext } from '../../_filters/_factories';
import { DefaultInputComponent } from '../../_filters/default/default.component';
import { DefaultFilterModel } from '../../_filters/default/default-filter.model';

/*
 * Overrides for ngrx entity selectors
 */
export function selectIds<T extends IReportDTO>(
  state: IReportState<T>
): string[] | number[] {
  // get data and ids
  let ids = [...(state.ids as string[])];
  const entities = state.entities;

  // handle sort
  const sortState = state.sort;
  const direction = sortState.direction === SortDirections.ASCENDING ? 1 : -1;
  const comparison = sortState.sortMap[sortState.property]
    ? sortState.sortMap[sortState.property]
    : sortState.defaultFunction(sortState.property);
  ids = ids.sort((a, b) => {
    const current = entities[a];
    const next = entities[b];
    return direction * comparison(current, next);
  });

  return ids;
}

export function createReportEntityOverrideSelectors<T extends IReportDTO>(
  selectState?: (state: any) => IReportState<T>
): IReportEntityOverrideSelectors<T> {
  const selectIds = (state: IReportState<T>) => {
    return selectIds(state);
  };

  if (!selectState) {
    return {
      selectIds,
    };
  }

  return {
    selectIds: createSelector(selectState, selectIds),
  };
}

export interface IReportEntityOverrideSelectors<T extends IReportDTO>
  extends Partial<EntitySelectors<T, IReportState<T>>> {
  selectIds(state: IReportState<T>): string[] | number[];
}

/*
 * Report specific entity selectors (TODO: move some to more generic place (paginate users/accounts etc...))
 */

// pagination selectors (exported for isolated testing purposes)
export function selectPagination<T extends IReportDTO>(
  state: IReportState<T>
): IReportPaginationState {
  return state.pagination;
}
export function selectUiPagination<T extends IReportDTO>(
  state: IReportState<T>
): IReportUiPaginationState {
  const pagination = state.pagination.ui;
  const ids = selectIds(state);
  return {
    ...pagination,
    count: ids.length,
    total: pagination.total,
  };
}
export function selectApiPagination<T extends IReportDTO>(
  state: IReportState<T>
): IReportApiPaginationState {
  return state.pagination.api;
}
export function selectApiPageForCurrentView<T extends IReportDTO>(
  state: IReportState<T>
): number {
  const pagination = selectPagination(state);
  return Math.ceil(
    (pagination.ui.pageNumber * pagination.ui.viewSize) / pagination.api.limit
  );
}

export interface IReportPaginationSelectors<T extends IReportDTO> {
  selectPagination(state: IReportState<T>): IReportPaginationState;
  selectUiPagination(state: IReportState<T>): IReportUiPaginationState;
  selectApiPagination(state: IReportState<T>): IReportApiPaginationState;
  selectApiPageForCurrentView(state: IReportState<T>): number;
}

export function selectSort<T extends IReportDTO>(
  state: IReportState<T>
): IReportSortState<T> {
  return state.sort;
}

export function selectHasSort<T extends IReportDTO>(
  state: IReportState<T>
): boolean {
  const defaultSort = getInitialReportSortState();
  const sort = selectSort(state);
  return (
    sort.direction !== defaultSort.direction ||
    sort.property !== defaultSort.property
  );
}

export interface IReportSortSelectors<T extends IReportDTO> {
  selectSort(state: IReportState<T>): IReportSortState<T>;
  selectHasSort(state: IReportState<T>): boolean;
}

export function selectFilters<T extends IReportDTO>(
  state: IReportState<T>
): IReportFilterState {
  return state.filters;
}

export function selectActiveFilters<T extends IReportDTO>(
  state: IReportState<T>
): IReportFilter[] {
  return selectFilters(state).active;
}

export function selectIgnoredOptions<T extends IReportDTO>(
  state: IReportState<T>
): string[] {
  return state.filters.ignoredOptions;
}

export function selectAdditionalOptions<T extends IReportDTO>(
  state: IReportState<T>
): string[] {
  return state.filters.additionalOptions;
}

export function selectCustomOptions<T extends IReportDTO>(
  state: IReportState<T>
): { [k: string]: IReportFilterContext } {
  const keys = Object.keys(state.filters.customOptions);
  const filters = state.filters.customOptions;
  const options = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    options[key] = rehydrateFilterContext(filters[key].context);
  }
  return options;
}

export function selectAvailableFilters<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(
  customFilterOptions: IReportFilterOptions = {}
): (state: IReportState<T>) => IReportFilterOption[] {
  return (state: IReportState<T>): IReportFilterOption[] => {
    const filters = selectActiveFilters(state);
    const additionalOptions = selectAdditionalOptions(state);
    const filterArray = [...additionalOptions];
    for (let i = 0; i < filters.length; i += 1) {
      const filter = filters[i];
      if (filterArray.indexOf(filter.id as string) === -1) {
        filterArray.push(filter.id as string);
      }
    }

    return filterArray.filter(Boolean).map((filter) => {
      if (customFilterOptions[filter]) {
        return customFilterOptions[filter];
      }
      return {
        context: new DefaultFilterModel(filter),
        component: DefaultInputComponent,
      };
    });
  };
}

export function selectActiveSearchFilter<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(search: new () => V): (state: IReportState<T>) => V {
  return (state: IReportState<T>): V => {
    const query = new search();
    const pagination = selectApiPagination(state);
    query.page = pagination.page;
    query.limit = pagination.limit;
    if (selectHasActiveAccount(state)) {
      query.customerNumber = selectActiveAccount(state);
    }
    const filters = selectActiveFilters(state);
    const customFilters = selectCustomOptions(state);
    const ignoredFilters = selectIgnoredOptions(state);

    for (let i = 0; i < filters.length; i += 1) {
      if (ignoredFilters.indexOf(filters[i].id as string) > -1) {
        continue;
      }
      // if range handle appropriately
      const custom = customFilters[filters[i].id];
      if (custom && isRangeFilter(custom)) {
        const fromId = `${filters[i].id}From`;
        const toId = `${filters[i].id}To`;
        const fromValue = filters[i].value.split('&')[0];
        const toValue = filters[i].value.split('&')[1];
        query[fromId] = fromValue;
        query[toId] = toValue;
      } else {
        query[filters[i].id] = filters[i].value;
      }
    }

    // customerNumber will be removed via ignored options so readd only if required
    const hasSubAccountFilter = filters.find(
      (filter) => filter.id === 'customerNumber'
    );
    if (hasSubAccountFilter) {
      query.customerNumber = hasSubAccountFilter.label;
    }

    return query;
  };
}

export interface IReportFilterSelector<
  T extends IReportDTO,
  V extends IReportSearchFilter
> {
  selectFilters(state: IReportState<T>): IReportFilterState;
  selectActiveFilters(state: IReportState<T>): IReportFilter[];
  selectAvailableFilters(
    customFilterOptions?: IReportFilterOptions
  ): (state: IReportState<T>) => IReportFilterOption[];
  selectActiveSearchFilter(search: new () => V): (state: IReportState<T>) => V;
}

export function selectHistory<T extends IReportDTO>(
  state: IReportState<T>
): IReportHistoryState {
  return state.history;
}

export interface IReportHistorySelector<T extends IReportDTO> {
  selectHistory(state: IReportState<T>): IReportHistoryState;
}

export function selectLoading<T extends IReportDTO>(
  state: IReportState<T>
): boolean {
  return state.loading;
}

export interface IReportLoadingSelector<T extends IReportDTO> {
  selectLoading(state: IReportState<T>): boolean;
}

export function selectProcessing<T extends IReportDTO>(
  state: IReportState<T>
): boolean {
  return state.processing;
}

export interface IReportProcessingSelector<T extends IReportDTO> {
  selectProcessing(state: IReportState<T>): boolean;
}

export function selectById<T extends IReportDTO>(id: string | number) {
  return (state: IReportState<T>) => {
    return state.entities[id];
  };
}

export function selectIdsForCustomQuery<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(search: V): (state: IReportState<T>) => string[] | number[] {
  return (state: IReportState<T>) => {
    const ids = selectIds(state);
    const qs = getCacheQueryString(search);
    const entities = state.entities;
    return (ids as string[]).filter((id: string): boolean => {
      return Boolean(entities[id].uiPages[qs]);
    });
  };
}

export function selectIdsForCurrentQuery<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(search: new () => V): (state: IReportState<T>) => string[] | number[] {
  return (state: IReportState<T>) => {
    let ids = selectIds(state);
    const entities = state.entities;

    const query = selectActiveSearchFilter(search)(state);
    const qs = getCacheQueryString(query);
    ids = (ids as string[]).filter((id: string): boolean => {
      return entities[id].uiPages[qs] !== undefined;
    });
    return ids;
  };
}

export function selectIdsForCurrentView<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(search: new () => V): (state: IReportState<T>) => string[] | number[] {
  return (state: IReportState<T>) => {
    const hasSort = selectHasSort(state);
    const pagination = selectPagination(state);
    const viewSize = pagination.ui.viewSize;
    let apiStart = 0;

    if (!hasSort) {
      const activeApiPage = selectApiPageForCurrentView(state) - 1;
      apiStart = Math.floor((activeApiPage * pagination.api.limit) / viewSize);
    }

    const start = (pagination.ui.pageNumber - 1 - apiStart) * viewSize;
    const end = start + viewSize;

    const ids = selectIdsForCurrentQuery(search)(state);
    return ids.slice(start, end);
  };
}

export interface IReportSearchSelectors<
  T extends IReportDTO,
  V extends IReportSearchFilter
> {
  selectById(id: string | number): (state: IReportState<T>) => T;
  selectIdsForCurrentQuery(
    search: new () => V
  ): (state: IReportState<T>) => string[] | number[];
  selectIdsForCurrentView(
    search: new () => V
  ): (state: IReportState<T>) => string[] | number[];
  selectIdsForCustomQuery(
    search: V
  ): (state: IReportState<T>) => string[] | number[];
}

export function selectValuesByKey<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(key: string, search: new () => V): (state: IReportState<T>) => string[] {
  return (state: IReportState<T>) => {
    const query = selectActiveSearchFilter(search)(state);
    delete query[key];
    const ids = selectIdsForCustomQuery(query)(state) as string[];
    const entities = state.entities;
    return Array.from(
      new Set(ids.map((id: string): string => entities[id][key]))
    );
  };
}

export interface IReportLookaheadValues<
  T extends IReportDTO,
  V extends IReportSearchFilter
> {
  selectValuesByKey(
    key: string,
    search: new () => V
  ): (state: IReportState<T>) => string[];
}

export function selectMinByProperty<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(key: string, search: new () => V): (state: IReportState<T>) => any {
  return (state: IReportState<T>) => {
    const values = selectValuesByKey(key, search)(state).sort(genericAscSort);
    return values[0];
  };
}

export function selectMaxByProperty<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(key: string, search: new () => V): (state: IReportState<T>) => any {
  return (state: IReportState<T>) => {
    const values = selectValuesByKey(key, search)(state).sort(genericDescSort);
    return values[0];
  };
}

export interface IReportPropertyRanges<
  T extends IReportDTO,
  V extends IReportSearchFilter
> {
  selectMinByProperty(
    key: string,
    search: new () => V
  ): (state: IReportState<T>) => any;
  selectMaxByProperty(
    key: string,
    search: new () => V
  ): (state: IReportState<T>) => any;
}

export function selectActiveAccount<T extends IReportDTO>(
  state: IReportState<T>
): string | null {
  return state.activeAccount;
}
export function selectHasActiveAccount<T extends IReportDTO>(
  state: IReportState<T>
): boolean {
  return Boolean(state.activeAccount);
}

export interface IReportActiveAccountSelectors<T extends IReportDTO> {
  selectActiveAccount(state: IReportState<T>): string | null;
  selectHasActiveAccount(state: IReportState<T>): boolean;
}

export function createReportEntitySelectors<
  T extends IReportDTO,
  V extends IReportSearchFilter
>(
  selectState?: (state: any) => IReportState<T>
): IReportEntityCustomSelectors<T, V> {
  // if no state return raw functions
  if (!selectState) {
    return {
      selectPagination,
      selectUiPagination,
      selectApiPagination,
      selectApiPageForCurrentView,
      selectSort,
      selectHasSort,
      selectFilters,
      selectActiveFilters,
      selectAvailableFilters,
      selectActiveSearchFilter,
      selectHistory,
      selectLoading,
      selectProcessing,
      selectById,
      selectIdsForCurrentQuery,
      selectIdsForCurrentView,
      selectIdsForCustomQuery,
      selectValuesByKey,
      selectActiveAccount,
      selectHasActiveAccount,
      selectMinByProperty,
      selectMaxByProperty,
    };
  }

  // if we have a root state create the selectors
  return {
    selectPagination: createSelector(selectState, selectPagination),
    selectUiPagination: createSelector(selectState, selectUiPagination),
    selectApiPagination: createSelector(selectState, selectApiPagination),
    selectApiPageForCurrentView: createSelector(
      selectState,
      selectApiPageForCurrentView
    ),
    selectSort: createSelector(selectState, selectSort),
    selectHasSort: createSelector(selectState, selectHasSort),
    selectFilters: createSelector(selectState, selectFilters),
    selectActiveFilters: createSelector(selectState, selectActiveFilters),
    selectAvailableFilters: (customFilterOptions?: IReportFilterOptions) =>
      createSelector(selectState, selectAvailableFilters(customFilterOptions)),
    selectActiveSearchFilter: (search: new () => V) =>
      createSelector(selectState, selectActiveSearchFilter(search)),
    selectHistory: createSelector(selectState, selectHistory),
    selectLoading: createSelector(selectState, selectLoading),
    selectProcessing: createSelector(selectState, selectProcessing),
    selectById: (id: string | number) =>
      createSelector(selectState, selectById(id)),
    selectIdsForCurrentQuery: (search: new () => V) =>
      createSelector(selectState, selectIdsForCurrentQuery(search)),
    selectIdsForCurrentView: (search: new () => V) =>
      createSelector(selectState, selectIdsForCurrentView(search)),
    selectIdsForCustomQuery: (search: V) =>
      createSelector(selectState, selectIdsForCustomQuery(search)),
    selectValuesByKey: (key: string, search: new () => V) =>
      createSelector(selectState, selectValuesByKey(key, search)),
    selectActiveAccount: createSelector(selectState, selectActiveAccount),
    selectHasActiveAccount: createSelector(selectState, selectHasActiveAccount),
    selectMinByProperty: (key: string, search: new () => V) =>
      createSelector(selectState, selectMinByProperty(key, search)),
    selectMaxByProperty: (key: string, search: new () => V) =>
      createSelector(selectState, selectMaxByProperty(key, search)),
  };
}

export interface IReportEntityCustomSelectors<
  T extends IReportDTO,
  V extends IReportSearchFilter
> extends IReportPaginationSelectors<T>,
    IReportSortSelectors<T>,
    IReportFilterSelector<T, V>,
    IReportHistorySelector<T>,
    IReportLoadingSelector<T>,
    IReportProcessingSelector<T>,
    IReportSearchSelectors<T, V>,
    IReportLookaheadValues<T, V>,
    IReportActiveAccountSelectors<T>,
    IReportPropertyRanges<T, V> {}

export interface IReportEntitySelectors<
  T extends IReportDTO,
  V extends IReportSearchFilter
> extends EntitySelectors<T, IReportState<T>>,
    IReportEntityCustomSelectors<T, V> {}
