import { Component } from '@angular/core';

@Component({
  selector: 'app-reports-nav',
  templateUrl: './reports-nav.component.html',
  styles: [],
})
export class ReportsNavComponent {

  constructor() { }

}
