import { Injectable, InjectionToken } from '@angular/core';
import { ApiService } from './api.service';
import {
  IAppResponse,
  ILiveHire,
  IReportResponse,
  LiveHireReportSearchFilter,
  IQueryInvoice,
  IHireReport,
  IReportLost,
  IRequestOffHire,
  IHireMovement,
  HireMovementReportSearchFilter,
  ReportTypes,
  IAccrual,
  AccrualReportSearchFilter,
  InvoiceReportSearchFilter,
  IInvoice,
} from '@waracle/gap-sdk';
import { Observable, of } from 'rxjs';
import * as queryString from 'querystring';
import { map, catchError, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { IReportSearchFilter } from '../report-management/_state/generic/model';
import { IInvoiceReport } from '../report-management/_pages/invoice/invoice.model';

// angular can't handle generic service declarations (yet)
export interface IInjectableReportService {
  baseName: string;
  type: ReportTypes;
  search(filter?: any): Observable<IReportResponse<any>>;
  lost(
    report: any,
    quantity: number,
    comment?: string
  ): Observable<IReportLost>;
  offHire(
    report: any,
    quantity: number,
    address: string,
    comment?: string
  ): Observable<IRequestOffHire>;
  queryInvoice(
    report: any,
    invoiceCode: string,
    comment?: string
  ): Observable<IQueryInvoice>;
}
export const REPORT_SERVICE = new InjectionToken<IInjectableReportService>(
  'REPORT_SERVICE'
);
export interface IReportService<
  T extends IHireReport | IInvoice,
  V extends IReportSearchFilter
> extends IInjectableReportService {
  baseName: string;
  type: ReportTypes;
  search(filter?: V): Observable<IReportResponse<T>>;
  autocomplete(
    property: string,
    value: string,
    filter: V,
    activeAccount?: string
  ): Observable<string[]>;
  lost(report: T, quantity: number, comment?: string): Observable<IReportLost>;
  offHire(
    report: T,
    quantity: number,
    address: string,
    comment?: string
  ): Observable<IRequestOffHire>;
  queryInvoice(
    report: T,
    invoiceCode: string,
    comment?: string
  ): Observable<IQueryInvoice>;
}

export class AbstractReportService<
  T extends IHireReport | IInvoice,
  V extends IReportSearchFilter
> implements IReportService<T, V>
{
  baseName: string;
  type: ReportTypes;
  constructor(private _api: ApiService) {}

  search(filter?: V): Observable<IReportResponse<T>> {
    const qs = filter
      ? queryString.stringify(_.omitBy(filter.toJS(), _.isUndefined))
      : '';
    return this._api
      .get<IAppResponse<IReportResponse<T>>>(
        `/v1/report/${this.baseName}/?${qs}`
      )
      .pipe(
        map((data) => {
          return data.response.report;
        })
      );
  }

  autocomplete(
    property: string,
    value: string,
    filter: V,
    activeAccount: string
  ) {
    const query = _.omitBy(filter.toJS(), _.isUndefined);
    // remove unused filter options
    delete query.enabled;
    delete query.limit;
    delete query.page;
    delete query.sort;
    // Do not remove the customer number EVER
    if (property !== 'customerNumber') {
      delete query[property];
    } else {
      query[property] = activeAccount || query[property];
    }
    const qs = filter ? queryString.stringify(query) : '';
    if (!Boolean(value)) return of([]);
    return this._api
      .get<IAppResponse<string[]>>(
        `/v1/report/${this.baseName}/${property}/${encodeURIComponent(
          value
        )}/?${qs}`
      )
      .pipe(
        startWith({ response: { values: [] } }),
        map((result: IAppResponse<string[]>) => {
          return result.response.values;
        })
      );
  }

  lost(report: T, quantity: number, comment?: string) {
    return this._api
      .put<IAppResponse<IReportLost>>(`/v1/report/${report.id}/lost`, {
        customerNumber: report.customerNumber,
        quantity,
        comment,
        reportType: this.type,
      })
      .pipe(
        map((result: IAppResponse<IReportLost>) => {
          return result.response.reportLost;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  offHire(report: T, quantity: number, address: string, comment?: string) {
    return this._api
      .put<IAppResponse<IRequestOffHire>>(`/v1/report/${report.id}/offhire`, {
        customerNumber: report.customerNumber,
        quantity,
        comment,
        site: address,
        reportType: this.type,
      })
      .pipe(
        map((result: IAppResponse<IRequestOffHire>) => {
          return result.response.requestOffHire;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  queryInvoice(report: T, invoiceCode: string, comment?: string) {
    return this._api
      .put<IAppResponse<IQueryInvoice>>(
        `/v1/report/${report.id}/queryinvoice`,
        {
          customerNumber: report.customerNumber,
          invoiceCode,
          comment,
          reportType: this.type,
        }
      )
      .pipe(
        map((result: IAppResponse<IQueryInvoice>) => {
          return result.response.queryInvoice;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class LiveHireReportService extends AbstractReportService<
  ILiveHire,
  LiveHireReportSearchFilter
> {
  baseName = 'livehire';
  type = ReportTypes.LIVE_HIRE;
  constructor(api: ApiService) {
    super(api);
  }
}

@Injectable({
  providedIn: 'root',
})
export class HireMovementReportService extends AbstractReportService<
  IHireMovement,
  HireMovementReportSearchFilter
> {
  baseName = 'hiremovement';
  type = ReportTypes.HIRE_MOVEMENT;
  constructor(api: ApiService) {
    super(api);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AccrualReportService extends AbstractReportService<
  IAccrual,
  AccrualReportSearchFilter
> {
  baseName = 'accrual';
  type = ReportTypes.ACCRUAL;
  constructor(api: ApiService) {
    super(api);
  }
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceReportService extends AbstractReportService<
  IInvoice,
  InvoiceReportSearchFilter
> {
  baseName = 'invoice';
  type = ReportTypes.INVOICE;
  constructor(api: ApiService) {
    super(api);
  }
}
