export interface IRole {
  authority: string;
  level: number;
}

export const ROLE_SUPER_ADMIN: IRole = { authority: 'SUPER', level: 100 };
export const ROLE_ADMIN: IRole = { authority: 'ADMIN', level: 90 };
export const ROLE_ACCOUNT_MANAGER: IRole = { authority: 'ACCOUNT_MANAGER', level: 50 };
export const ROLE_USER: IRole = { authority: 'USER', level: 10 };
export const ROLE_ANONYMOUS: IRole = { authority: 'ANONYMOUS', level: 0 };
