import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorParams } from './interface';

export function matchPasswordValidator(key: string, matchKey: string): ValidatorFn {
  return (control: AbstractControl): ValidatorParams => {
    const password = control.get(key);
    const confirm = control.get(matchKey);

    if (password.value === confirm.value) {
      confirm.setErrors(null);
      return;
    }

    confirm.setErrors(
      {
        matchPassword: true,
      },
        );

    return;
  };
}
