import {
  Directive,
  Input,
  TemplateRef,
  AfterContentChecked,
  ElementRef,
  ViewContainerRef,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[noContent]',
})
export class NoContentDirective implements AfterContentChecked {

  @Input()
  noContent: TemplateRef<any>;

  private _element: HTMLElement;
  private _hasContent = true;

  constructor(
    element: ElementRef,
    private _container: ViewContainerRef,
    private _renderer: Renderer2) {
    this._element = element.nativeElement;
  }

  ngAfterContentChecked(): void {
    let hasContent = false;
    for (let i = this._element.childNodes.length - 1; i >= 0; i = i - 1) {
      const node = this._element.childNodes[i];
      if (node.nodeType === 1 || node.nodeType === 3) {
        hasContent = true;
        break;
      }
    }
    if (hasContent !== this._hasContent) {
      this._hasContent = hasContent;
      if (hasContent) {
        this._renderer.removeClass(this._element, 'is-empty');
        this._container.clear();
      }
      else {
        this._renderer.addClass(this._element, 'is-empty');
        this._container.createEmbeddedView(this.noContent);
      }
    }
  }
}
