import { IReportSearchFilter, ReportSearchFilter } from './reportSearch.filter';
import { GaptrackTypes, ILiveHire } from '../models/reports/liveHire.model';

export interface ILiveHireReportSearchFilter extends IReportSearchFilter {
  gaptrack: GaptrackTypes;

  /*
  The list of valid subaccounts to include with the parent live hire data
   */
  subaccounts: string[];
}

export class LiveHireReportSearchFilter
  extends ReportSearchFilter
  implements ILiveHireReportSearchFilter, Partial<ILiveHire>
{
  constructor(
    limit: number = 1000,
    page: number = 1,
    maxLimit: number = 1000000
  ) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000000
    super(limit, page, maxLimit);
    // set the default sort for the live hire reports to be the onHireDate
    this.sort = '-onHireDate';
  }

  private _gaptrack: GaptrackTypes;
  private _subaccounts: string[];

  get gaptrack(): GaptrackTypes {
    return this._gaptrack;
  }

  set gaptrack(value: GaptrackTypes) {
    this._gaptrack = value;
  }

  get subaccounts(): string[] {
    return this._subaccounts;
  }

  set subaccounts(value: string[]) {
    this._subaccounts = value;
  }

  public toJS(): ILiveHireReportSearchFilter {
    return Object.assign(super.toJS(), {
      accountName: this.accountName,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      contractNumber: this.contractNumber,
      customerNumber: this.customerNumber,
      depotCode: this.depotCode,
      depotName: this.depotName,
      fleetNumber: this.fleetNumber,
      gaptrack: this.gaptrack,
      orderNumber: this.orderNumber,
      price: this.price,
      productCode: this.productCode,
      productName: this.productName,
      quantityDelivered: this.quantityDelivered,
      subaccounts: this.subaccounts,
    });
  }
}
