import debug from 'debug';

export default class Logger {

  public readonly log: debug.IDebugger;

  public readonly info: debug.IDebugger;

  public readonly debug: debug.IDebugger;

  public readonly error: debug.IDebugger;

  public readonly warn: debug.IDebugger;

  constructor(namespace?: string) {
    this.log = debug(`gap-extranet:${namespace}:log`);
    this.info = debug(`gap-extranet:${namespace}:info`);
    this.debug = debug(`gap-extranet:${namespace}:debug`);
    this.error = debug(`gap-extranet:${namespace}:error`);
    this.warn = debug(`gap-extranet:${namespace}:warn`);
  }
}

/**
 * To maintain backwards compatibility
 * @deprecated since version 1.3.2
 * @see {@link Logger}
 */
export { Logger }
