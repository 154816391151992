import { ITextSearchFilter, TextSearchFilter } from './textSearch.filter';

export interface IAccountSearchFilter extends ITextSearchFilter {
  accountId: string;
  parentAccountId: string;
  role: string;
}

export class AccountSearchFilter
  extends TextSearchFilter
  implements IAccountSearchFilter
{
  constructor(limit: number = 100, page: number = 1, maxLimit: number = 1000) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000
    super(limit, page, maxLimit);
  }

  private _role: string;
  private _parentAccountId: string;
  private _accountId: string;

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = value.toUpperCase();
    }
    this._role = value;
  }

  get accountId(): string {
    return this._accountId;
  }

  set accountId(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      value = value.toLowerCase();
    }
    this._accountId = value;
  }

  get parentAccountId(): string {
    return this._parentAccountId;
  }

  set parentAccountId(value: string) {
    if (typeof value !== 'string' || !value) {
      value = undefined;
    }
    this._parentAccountId = value;
  }

  public toJS(): IAccountSearchFilter {
    return Object.assign(super.toJS(), {
      accountId: this.accountId,
      parentAccountId: this.parentAccountId,
      role: this.role,
    });
  }
}
