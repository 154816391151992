import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../_services/account.service';
import { UserService } from '../../_services/user.service';
import { AlertService } from '../../_services/alert.service';
import { ErrorModalService } from '../../_services/error-modal.service';
import { ErrorModalComponent } from '../error/error-modal.component';

export function behaviorValidator(subject: BehaviorSubject<boolean>) {
  return (control: AbstractControl) => {
    return subject.value ?
      null : { exists: control.value };
  };
}

@Component({
  selector: 'app-request-new-user',
  templateUrl: './request-new-user.component.html',
  styleUrls: ['./request-new-user.component.scss'],
})
export class RequestNewUserComponent {

  dropdownSettings = {
    singleSelection: false,
    idField: 'accountId',
    textField: 'text',
    enableCheckAll: false,
  };
  dropdownList: any[] = this.accounts.map((account) => {
    return {
      ...account,
      text: `${account.name} - ${account.accountId}`,
    };
  });
  requestNewUserForm: FormGroup;
  isUniqueEmail: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isSubmitting: boolean = false;
  private _sub$: Subscription;

  constructor(
    private _accountService: AccountService,
    public activeModal: NgbActiveModal,
    private _alertService: AlertService,
    private _user: UserService,
    private _errorModalService: ErrorModalService,
  ) {
    this.requestNewUserForm = new FormGroup(
      {
        firstName: new FormControl('', [
          Validators.required,
        ]),
        lastName: new FormControl('', [
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          behaviorValidator(this.isUniqueEmail),
        ]),
        telephone: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[\d]*$/),
          Validators.maxLength(15),
        ]),
        accountSelector: new FormControl('', [
          Validators.required,
        ]),
      },
    );
    this._sub$ = this.requestNewUserForm.get('email').valueChanges.subscribe((email) => {
      this.isUniqueEmail.next(true);
    });
  }

  get accounts() {
    return this._accountService.accounts;
  }

  submit() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    this.isUniqueEmail.next(true);
    const request = this.requestNewUserForm.value;
    const data = {
      firstName: request.firstName,
      lastName: request.lastName,
      accounts: request.accountSelector.map((account) => {
        return this.accounts.find((acc) => acc.accountId === account.accountId);
      }),
      contactNumber: request.telephone,
      email: request.email,
    };
    this._user.requestNew(data).subscribe(
      (result) => {
        this.activeModal.close();
        this._alertService.success(
          'USER.ALERTS.REQUEST_NEW',
          {
            name: `${request.firstName} ${request.lastName}`,
          },
          true);
        this._alertService.clearAfterTime(3000);
        this.isSubmitting = false;
      },
      (error) => {
        this._errorModalService.open(this, ErrorModalComponent, this.submit, error.status);
        this.isUniqueEmail.next(false);
        this.isSubmitting = false;
        this.requestNewUserForm.controls['email'].updateValueAndValidity();
      });
  }

  ngOnDestroy() {
    this._sub$.unsubscribe();
  }

}
