import { AppError } from './app.error';

export class ValidationError extends AppError {

  constructor(message: string, error?: Error) {
    super(message, error);
    // @ts-ignore
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}
