import { ValidatorFn, Validators } from '@angular/forms';
import { IReportFilterContext, SupportedFilterTypes } from '../../_state/generic/filter';
import { genericDescSort } from '../../_state/generic/helpers';

export class DefaultFilterModel implements IReportFilterContext {
  validators: ValidatorFn[] = [Validators.required];
  default?: any;
  required?: boolean = false;
  constructor(public id: string, public type: SupportedFilterTypes = SupportedFilterTypes.STRING) {}

  addValidator(validator: ValidatorFn) {
    this.validators.push(validator);
  }

  format(value: any) {
    if (!value) {
      return '';
    }
    return value.toString().trim();
  }
  toSearch(value: any) {
    if (!value) {
      return '';
    }
    return value.toString().trim();
  }

  sort(a: any, b: any): number {
    return genericDescSort(a, b);
  }
}
