import { Config } from '../config';
import {
  IRole,
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_USER,
} from '../models';
import { addMonths, addWeeks, addYears, format, parseISO } from 'date-fns';
import { mergeWith, snakeCase } from 'lodash';
import { ISearchFilter, SearchFilter } from '../search/search.filter';
import { IUser, TimePeriods } from '../models';
import { Logger } from '@waracle/gap-extranet/logger';

const logger = new Logger('utils');

export class Utils {
  // tslint:disable-next-line:naming-convention
  public static toSearchKey = (...keys: any[]) => {
    if (!keys) {
      throw new Error('Key(s) cannot be null');
    }

    let searchKey = '';
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (!key) {
        continue;
      }
      searchKey += keys[i];
    }

    return searchKey
      .toLowerCase()
      .replace(
        /[\u2000-\u206F,\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~ ]/gi,
        ''
      );
  };

  // tslint:disable-next-line:naming-convention
  public static isUserAllowedAccess = (
    user: IUser,
    requiredRole: IRole
  ): boolean => {
    if (!user || !user.role) {
      logger.debug('isUserAllowedAccess: no user or user.role provided');
      return false;
    }

    if (!requiredRole || typeof requiredRole.authority === 'undefined') {
      logger.debug('isUserAllowedAccess: no required role provided');
      return false;
    }

    // const requiredRoleLevel: number = activeRoles.find((role: Role) => role == requiredRole).level
    const userRole: IRole = Utils.findRoleFromRoles(user.role);

    return userRole.level >= requiredRole.level;
  };

  // tslint:disable-next-line:naming-convention
  public static isValidRole = (role: string): boolean => {
    return role && typeof Utils.findRoleFromRoles(role) !== 'undefined';
  };

  // tslint:disable-next-line:naming-convention
  public static isValidPassword = (password: string): boolean => {
    let valid: boolean = true;

    for (const rule of Config.PASSWORD_RULES) {
      if (rule.test(password)) {
        continue;
      }
      logger.warn(`Password missing: ${rule}`);
      valid = false;
    }

    return valid;
  };

  // tslint:disable-next-line:naming-convention
  public static activeRoles: IRole[] = [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_ACCOUNT_MANAGER,
    ROLE_USER,
  ];

  // tslint:disable-next-line:naming-convention
  public static findRoleFromRoles = (roleName: string): IRole => {
    return Utils.activeRoles.find(
      (role: IRole) => role.authority.toUpperCase() === roleName.toUpperCase()
    );
  };

  // tslint:disable-next-line:naming-convention
  public static objectToArray = (obj: Object) =>
    Object.keys(obj).map((key) => obj[key]);

  // tslint:disable-next-line:naming-convention
  public static capitalise = (str: string): string => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    if (str.length === 1) {
      return str.charAt(0).toUpperCase();
    }
    const strWithRemovedChars = str.replace(/[^a-zA-Zd]/g, '');
    return `${strWithRemovedChars.charAt(0).toUpperCase()}${strWithRemovedChars
      .slice(1)
      .toLowerCase()}`;
  };

  /**
   * Formats a given date-like variable to the full ISO 8601 format
   *
   * @param date
   */
  // tslint:disable-next-line:naming-convention
  public static toISOString = (date: string | number | Date) => {
    // 0 is a valid date but would be considered 'falsy' so handle properly
    if (!date && typeof date !== 'number') {
      return null;
    }

    try {
      // if date is a string parse first
      const d = typeof date === 'string' ? parseISO(date) : new Date(date);
      const formattedDate = format(d, Config.ISO_DATE_FORMAT);

      if (formattedDate === 'Invalid Date') {
        return null;
      }

      return formattedDate;
    } catch (e) {
      return null;
    }
  };

  public static getSearchFilterFromRequest<T extends ISearchFilter>(
    req,
    maxLimit = 100,
    // tslint:disable-next-line:variable-name
    SearchFilterClass = SearchFilter
  ): T {
    let limit = maxLimit;
    let page = 1;
    try {
      limit = parseInt(req.limit, 10) || maxLimit;
      page = parseInt(req.page, 10) || 1;
    } catch (e) {
      logger.error(
        e,
        'Failed to parse limit, page from req',
        JSON.stringify(req)
      );
    }
    delete req.limit;
    delete req.page;

    if (typeof req.enabled !== 'undefined') {
      req.enabled = req.enabled === 'true';
    }

    if (req.fields && typeof req.fields === 'string') {
      req.fields = (req.fields as string)
        .split(',')
        .map((field: string) => field.trim());
    }

    return mergeWith(
      new SearchFilterClass(limit, page, maxLimit),
      req,
      (a, b) => (b === null ? a : undefined)
    );
  }

  /**
   * Converts a valid string to a Uppercased snake-cased string
   *
   * @param str
   */
  public static toI18N(str) {
    if (!str || typeof str !== 'string') {
      return '';
    }
    return snakeCase(str).toUpperCase();
  }

  /**
   * Validates an account id against the parent id format
   *
   * @param customerNumber  String to validate against the customer number format
   * @return Boolean if customerNumber is a valid parent account id
   */
  public static isParentAccount(customerNumber: string): boolean {
    const format = /^[A-Z]{4}0{3}[0-9]$/;
    return format.test(customerNumber);
  }

  /**
   * Validate a Customer Number (account number) is valid format
   *
   * @param customerNumber  String to validate against the customer number format
   * @return Boolean if customerNumber is a valid accountId
   */
  public static isValidAccountNumber(customerNumber: string): boolean {
    const format = /^[A-Z]{4}[0-9]{4}$/;
    return format.test(customerNumber);
  }

  /**
   * Calculate the start date for a given time period
   */
  public static getRangeStartForPeriod(
    period: TimePeriods,
    start: Date = new Date()
  ): Date {
    switch (period) {
      case TimePeriods.WEEK:
        return addWeeks(start, -1);
      case TimePeriods.MONTH:
        return addMonths(start, -1);
      case TimePeriods.QUARTER:
        return addMonths(start, -3);
      case TimePeriods.HALF:
        return addMonths(start, -6);
      case TimePeriods.YEAR:
        return addYears(start, -1);
      case TimePeriods.BIENNIAL:
        return addYears(start, -2);
      case TimePeriods.EVER:
      default:
        return new Date(1, 1, 1);
    }
  }
}
