import {
  ISearchFilter,
  SearchFilter,
} from './search.filter';
import { Utils } from '../utils/utils';

export interface ITextSearchFilter extends ISearchFilter {
  text: string;
}

export class TextSearchFilter extends SearchFilter implements ITextSearchFilter {

  private _text: string;

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    if (typeof value !== 'string' || !value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = undefined;
    }

    if (value) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = Utils.toSearchKey(value);
    }
    this._text = value;
  }

  public toJS(): ITextSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        text: this.text,
      });
  }
}
