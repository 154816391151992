import { EntityModel, IBaseModel } from './base.model';
import { IUser } from './user.model';
import { ReportTypes } from './report.model';

export interface IQueryInvoice extends EntityModel<IQueryInvoiceDTO> {}

export interface IQueryInvoiceDTO extends IBaseModel {
  code: QueryInvoiceCode;
  comment?: string;
  contractNumber: string;
  invoiceNumber: number;
  queryCode: string;
  parentAccountId: string;
  productCode: string;
  recipient: IUser;
  reporter: IUser;
  reportId: string;
  reportType: ReportTypes;
  site: string;
  timestamp: string;
}
export enum QueryInvoiceCode {
  NOT_IN = 'NOT_IN',
  NUMBER_MISSING = 'NUMBER_MISSING',
  INCORRECT_RATE = 'INCORRECT_RATE',
  OTHER = 'OTHER',
  PAPERWORK_REQUIRED = 'PAPERWORK_REQUIRED',
  FUEL_OR_GAS_OIL = 'FUEL_OR_GAS_OIL',
  TRANSPORT = 'TRANSPORT',
  HIRE_PERIOD = 'HIRE_PERIOD',
  WRONG_ACCOUNT_NUMBER = 'WRONG_ACCOUNT_NUMBER',
  INTERNAL_QUERY = 'INTERNAL_QUERY',
}
