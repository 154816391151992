import { IReportDTO } from './model';
import { genericDescSort } from './helpers';

export enum SortDirections {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export type ReportSortDirections = SortDirections.ASCENDING | SortDirections.DESCENDING;

export type SortFunction = (a: any, b: any) => number;

export const defaultSort: (key: string) => SortFunction =
  (key: string) => (a: any, b: any) => genericDescSort(a[key], b[key]);

export interface IReportSort<T extends IReportDTO> {
  direction: ReportSortDirections;
  property: string;
  defaultFunction: (key: string) => SortFunction;
  sortMap: {[k: string]: SortFunction};
}

export interface IReportSortState<T extends IReportDTO> extends IReportSort<T> {}

export function getInitialReportSortState<T extends IReportDTO>(): IReportSortState<T> {
  return {
    direction: SortDirections.ASCENDING,
    property: 'onHireDate',
    defaultFunction: defaultSort,
    sortMap: {},
  };
}
