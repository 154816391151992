import { AuthenticationService } from '../_services/authentication.service';
import { IRole } from '@waracle/gap-sdk';

export function checkAuth(
  requiredRole: IRole,
  authenticationService: AuthenticationService): Promise<boolean> {
  return authenticationService.isAuthenticated()
      .then((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          return false;
        }
        return authenticationService.isAuthenticatedRoleValid(requiredRole);
      })
      .catch((err: any) => {
        return false;
      });
}
