import { Action } from '@ngrx/store';

export const appActionTypes = {
  Ready: '[Global] App Ready',
  Logout: '[Global] Logout',
  Login: '[Global] Login',
  SetActive: '[Global] Set Active Account',
  Noop: '[Global] No action here',
};

export class NoopAction implements Action {
  readonly type = appActionTypes.Noop;
  constructor(public payload?: any) {}
}

export class AppReady implements Action {
  readonly type = appActionTypes.Ready;
}

export class Logout implements Action {
  readonly type = appActionTypes.Logout;
}
export class Login implements Action {
  readonly type = appActionTypes.Login;
}
export class SetActiveAccount implements Action {
  readonly type = appActionTypes.SetActive;
  constructor(public payload: string) {}
}
