import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gap-ui-iconic-card',
  template: `
    <div class="col-10 seg-1" [routerLink]="link">
      <h2>{{title}}</h2>
      <div class="the-desc">{{description}}</div>
    </div>
    <div class="col-2 d-flex align-items-center text-center seg-2">
      <div class="w-100">
        <img [src]="icon" class="mw-100" />
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      box-shadow: 0px 0px 15px 1px lightgrey;
      border-radius: 5px;
      height: 100%;
      background-color: white;
      margin-right: 0;
      margin-left: 0;
    }
    .seg-1 {
      padding: 25px;
    }
    .seg-2 {
      box-shadow: -2px 0px 0px 0px lightgrey;
      padding: 0 25px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconicCardComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() link: string | null = null;
  @Input() icon: string = '/assets/img/digger.svg';
}
