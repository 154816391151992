import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { appActionTypes, NoopAction, SetActiveAccount, Logout } from './app.actions';
import { switchMap, map, withLatestFrom, take } from 'rxjs/operators';
import { LIVE_HIRE_ACTIONS } from '../report-management/_pages/live-hire/live-hire.model';
import { HIRE_MOVEMENT_ACTIONS } from '../report-management/_pages/hire-movement/hire-movement.model';
import { ClearAccounts } from '../user-management/_state/accounts/account.actions';
import { ClearUsers } from '../user-management/_state/users/user.actions';
import { ClearRoles } from '../user-management/_state/roles/role.actions';
import { AccountService } from '../_services/account.service';
import { Store, select } from '@ngrx/store';
import { IState } from './reducers';
import { selectActiveAccount } from './app.selectors';
import { of } from 'rxjs';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { INVOICE_ACTIONS } from '../report-management/_pages/invoice/invoice.model';
import { ACCRUAL_ACTIONS } from '../report-management/_pages/accrual/accrual.model';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IState>,
    private _accounts: AccountService,
    private _user: UserService,
    private _router: Router,
  ) {}

  @Effect()
  init$ = this._actions$.pipe(
    ofType(appActionTypes.Ready, appActionTypes.Login),
    withLatestFrom(
      this._store.pipe(
        select(selectActiveAccount),
      ),
    ),
    switchMap(([_, existingAccount]: [any, string]) => {
      if (existingAccount) {
        return of(new SetActiveAccount(existingAccount));
      }
      return this._accounts.activeAccount$.pipe(
        map((account) => {
          if (!Boolean(account)) {
            // skip if no active account
            return new NoopAction();
          }
          return new SetActiveAccount(account.id);
        }),
        take(1),
      );
    }),
  );

  @Effect({ dispatch: false })
  refreshUser$ = this._actions$.pipe(
    ofType(appActionTypes.Ready),
    map((_) => {
      if (this._user.me()) {
        return this._user.meAsync().subscribe();
      }
    }),
  );

  @Effect({ dispatch: false })
  setActive$ = this._actions$.pipe(
    ofType(appActionTypes.SetActive),
    map((action: SetActiveAccount) => {
      this._accounts.updateActiveAccount(action.payload);
    }),
  );

  @Effect()
  loggedOut$ = this._actions$.pipe(
    ofType(appActionTypes.Logout),
    switchMap((_: any) => [
      new LIVE_HIRE_ACTIONS.ReportResetState(),
      new HIRE_MOVEMENT_ACTIONS.ReportResetState(),
      new INVOICE_ACTIONS.ReportResetState(),
      new ACCRUAL_ACTIONS.ReportResetState(),
      new ClearAccounts(),
      new ClearUsers(),
      new ClearRoles(),
    ]),
  );

  @Effect({ dispatch: false })
  redirectToLoginAfterLogout$ = this._actions$.pipe(
    ofType(appActionTypes.Logout),
    map((action: Logout) => {
      this._router.navigate(['login']);
    }),
  );

}
