import {
  RouterModule,
  Routes,
  PreloadAllModules,
} from '@angular/router';

import { DashIndexComponent } from './dashboard/dash-index/dash-index.component';
import { AdminAccountComponent } from './user-admin/admin-account/admin-account.component';
import { AdminAuthGuard, UserAuthGuard, AdminRedirectGuard } from './_guards';
import { DashboardComponent } from './_components/dashboard/dashboard.component';

export const appRoutes: Routes = [
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'password/forgotten',
    redirectTo: 'auth/forgotten',
    pathMatch: 'full',
  },
  {
    path: 'password/reset',
    redirectTo: 'auth/create',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [UserAuthGuard],
    children: [
      {
        path: 'reports',
        loadChildren: () => import('./report-management/report-management.module').then((m) => m.ReportManagementModule), // tslint:disable-line
        canActivate: [AdminRedirectGuard],
        data: { feature: 'report-management' },
      },
      {
        path: 'insight-builder',
        loadChildren: () => import('./insight-builder/insight-builder.module').then((m) => m.InsightBuilderModule),
        canActivate: [AdminRedirectGuard],
        data: { feature: 'insight-builder' },
      },
      {
        path: 'user-management',
        loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
        canActivate: [AdminAuthGuard],
        data: { feature: 'user-management' },
      },
      {
        path: 'dashboard',
        canActivate: [AdminRedirectGuard],
        component: DashIndexComponent,
      },
      {
        path: 'admin/account',
        component: AdminAccountComponent,
      },
      {
        path: 'client/account',
        component: AdminAccountComponent,
      },
      { path: '**', redirectTo: 'dashboard' },
    ],
  },

];

export function createRoutes() {
  return RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadAllModules });
}
