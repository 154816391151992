import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

import * as jwt from 'jsonwebtoken';

import * as jwksrsa from 'jwks-rsa';
import { environment } from '../../environments/environment';
import {
  IAuthentication,
  IRole,
  Utils,
  IUser,
} from '@waracle/gap-sdk';
import { Store } from '@ngrx/store';
import { Logout } from '../_state/app.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(private _store: Store<any>) {

    const jwksOpts = {
      strictSsl: false, // Default value
      cache: true,
      rateLimit: true,
      jwksRequestsPerMinute: 5,
      jwksUri: environment.jwksUri,
    } as jwksrsa.Options;

    this._jwksClient = jwksrsa(jwksOpts);
  }

  get jwksClient(): jwksrsa.JwksClient {
    return this._jwksClient;
  }

  /**
   * Returns, if it exists, the authentication object containing the user and their access token
   *
   * @returns IAuthentication
   */
  public get authentication(): IAuthentication {
    return JSON.parse(localStorage.getItem(AuthenticationService.KEY_AUTHENTICATION));
  }

  // tslint:disable-next-line
  public static readonly KEY_AUTHENTICATION = 'authentication';

  authChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _jwksClient: jwksrsa.JwksClient;

  /**
   *  Check if a user is currently authenticated
   *
   *  @Returns Promise<boolean>
   */
  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // no user, auth is definitely false
      if (!this.authentication) {
        return resolve(false);
      }

      // is the token valid?
      this.isTokenValid(this.authentication.token)
        .then((isAuthenticated: boolean) => {
          return resolve(true);
        })
        .catch((err: Error) => {
          return reject(err);
        });
    });

  }

  login(authentication: IAuthentication): Promise<boolean> {
    return new Promise((resolve) => {
      this.isTokenValid(authentication.token)
        .then((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            localStorage.setItem(AuthenticationService.KEY_AUTHENTICATION, JSON.stringify(authentication));
            this.authChange.next(true);
            return resolve(true);
          }
          return resolve(false);
        });
    });
  }

  refreshAuthenticatedUser(toSave: IUser) {
    const { token, user } = this.authentication;
    localStorage.setItem(AuthenticationService.KEY_AUTHENTICATION, JSON.stringify({ token, user: toSave }));
  }

  logout() {
    const previouslyAuthed = this.authChange.getValue();
    // set authChange false
    this.authChange.next(false);
    // only clear data and trigger redirect if the use WAS logged in previously
    if (previouslyAuthed) {
      this._store.dispatch(new Logout());
    }

    // remove user from local storage to log user out
    localStorage.removeItem(AuthenticationService.KEY_AUTHENTICATION);
  }

  isAuthenticatedRoleValid(requiredRole: IRole): boolean {
    if (!requiredRole || typeof requiredRole.authority === 'undefined') {
      new Error('No Required Role provided.  Assuming invalid');
    }

    const authenticatedRole = Utils.findRoleFromRoles(this.authentication.user.role);
    if (authenticatedRole.level >= requiredRole.level) {
      return true;
    }
    return false;

  }

  isTokenValid(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.jwksClient.getSigningKey(environment.jwkKeyId, (err: Error, key: jwksrsa.SigningKey) => {
        const signingKey = key.getPublicKey();

        jwt.verify(
          token,
          signingKey,
          // tslint:disable
          {
            algorithms: ['RS256'],
            issuer: environment.jwtIssuer,
          } as jwt.VerifyOptions, // tslint:enable
          (e: Error, decoded) => {
            // if issuer mismatch, err == invalid issuer
            if (e) {
              this.logout();
              return reject(e);
            }
            return resolve(true);
          },
        );
      });
    });
  }
}
