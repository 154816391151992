import {
  ReportTypes,
  AccrualReportSearchFilter,
} from '@waracle/gap-sdk';
import { createSelector } from '@ngrx/store';

import { getReportManagementState } from '../../_state/report-management.selectors';
import { IReportManagementState } from '../../_state/report-management.model';

import {
  getReportActionTypes,
  getReportActions,
  ReportAction,
} from '../../_state/generic/actions';
import { getInitialReportState, IReportState } from '../../_state/generic/state';
import { IReportDTO } from '../../_state/generic/model';
import { IReportFilterOptions } from '../../_state/generic/filter';
import { createReportReducers } from '../../_state/generic/reducers';
import { createReportEntityAdapter, IReportEntityAdapter } from '../../_state/generic/adapter';
import { IReportEntitySelectors } from '../../_state/generic/selectors';
import { hireReportFilterOptions, offHireDateToFilter, hireCustomFilterOptions } from '../../_config/hire-report';

export const additionalFilterOptions = [
  ...hireReportFilterOptions,
  'offHireDateTo',
];

export interface IAccrualReport extends IReportDTO {
  reportType: ReportTypes.ACCRUAL;
  lineType: number;
  terminatedReasonCode: string;
  createdReasonCode: string;
  offHireDate: string;
  onHireDate: string;
}

export const ACCRUAL_ACTION_TYPES = getReportActionTypes('accrual');

export const ACCRUAL_ACTIONS = getReportActions<IAccrualReport>(ACCRUAL_ACTION_TYPES);

offHireDateToFilter.context.required = true;
export const customFilterOptions: IReportFilterOptions = {
  ...hireCustomFilterOptions,
  offHireDateTo: offHireDateToFilter,
};

export const initialState: IReportState<IAccrualReport> = getInitialReportState<IAccrualReport>({
  filters: {
    customOptions: customFilterOptions,
    additionalOptions: additionalFilterOptions,
  },
});

const adapter: IReportEntityAdapter<IAccrualReport, AccrualReportSearchFilter> =
  createReportEntityAdapter<IAccrualReport, AccrualReportSearchFilter>();

export function reducer(
  state = initialState,
  action: ReportAction<IAccrualReport>,
): IReportState<IAccrualReport> {
  return createReportReducers<IAccrualReport, AccrualReportSearchFilter>(
    ACCRUAL_ACTION_TYPES,
    adapter,
  )(state, action);
}

export const getReportState = createSelector(
  getReportManagementState,
  (state: IReportManagementState) => state.accrual,
);

export const reportSelectors: IReportEntitySelectors<IAccrualReport, AccrualReportSearchFilter> =
  adapter.getSelectors(getReportState);
