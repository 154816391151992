import { IReportFilterContext, SupportedFilterTypes } from '../../_state/generic/filter';
import { DefaultFilterModel } from '../default/default-filter.model';
import { Observable, of } from 'rxjs';

export class DropdownFilterModel extends DefaultFilterModel implements IReportFilterContext {
  constructor(public id: string) {
    super(id, SupportedFilterTypes.DROPDOWN);
  }
  dropdownChoices$: (query: string) => Observable<string[]> = (query: string) => of([]);

  format(value: any) {
    if (!value || !value.label) {
      return '';
    }
    return value.label;
  }

  toSearch(value: any) {
    if (!value) {
      return '';
    }
    return value.value.toString().trim();
  }
}
