import { Component, OnInit } from '@angular/core';
import { map, switchMap, flatMap, mapTo, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { Observable, Subscription, from, empty, concat, of, BehaviorSubject } from 'rxjs';
import { IInsight } from '@waracle/gap-sdk';
import { Insight } from '../../insight-builder/_models';
import { UserService } from '../../_services/user.service';
import { ConfirmModalComponent } from '../../_modals/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsightsService } from '../../insight-builder/_services/insights.service';
import { AccountService } from '../../_services/account.service';
import { IAccount } from '../../user-management/_state/accounts/account.model';

@Component({
  selector: 'app-pinned-insights',
  template: `
  <ng-container *ngIf="pinnedInsights$ | async as pinnedInsights">
    <div class="row mt-5 mb-2">
      <div class="col-12 col-md-3">
        <h2>{{ 'DASHBOARD.PINNED_INSIGHTS.TITLE' | translate }}</h2>
      </div>
      <div class="col-12 col-md-9 text-md-right">
        <div class="mb-2 insight-action-bar">
          <button type="button"
          class="btn btn-sm btn-ghost shadow-none"
          (click)="addInsightToAutomation($event)"
          [disabled]="selectedCount === 0">
            {{ 'DASHBOARD.PINNED_INSIGHTS.ADD_AUTOMATION' | translate }}
          </button> -
          <button type="button"
          class="btn btn-sm btn-ghost shadow-none"
          (click)="removeInsights($event)"
          [disabled]="selectedCount === 0">
            {{ 'DASHBOARD.PINNED_INSIGHTS.REMOVE_INSIGHT' | translate:{plural: selectedCount <= 1 ? '' : 's'} }}
            <span *ngIf="selectedCount > 0"> ({{ selectedCount }})</span>
          </button> -
          <button type="button"
          class="btn btn-sm btn-ghost shadow-none"
          (click)="unscheduleInsight($event)"
          [disabled]="selectedCount === 0">
            {{ 'DASHBOARD.PINNED_INSIGHTS.REMOVE_AUTOMATION' | translate:{plural: selectedCount <= 1 ? '' : 's'} }}
            <span *ngIf="selectedCount > 0"> ({{ selectedCount }})</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="pinnedInsights.length === 0" class="no-pinned-insights">
      <h4>You have no pinned insights</h4>
      <p>
        Add insights by using the insight builder above
      </p>
    </div>
    <div *ngIf="pinnedInsights.length > 0"
      class="row row-eq-height mb-5 pinned-insights
        {{ pinnedInsights.length <= pinnedInsightsMaxIndex ?
            'all-insights-loaded' : '' }}">
      <div [hidden]="i >= pinnedInsightsMaxIndex"
        *ngFor="let insight of pinnedInsights; let i = index"
        class="col-12 col-lg-6 col-xl-4 mt-2 mb-2">
        <gap-ui-insight-card
          [selected]="insight.selected"
          [description]="insight.description"
          [details]="insight.details ? ('INSIGHT_BUILDER.SCHEDULED' | translate:{time: insight.details}) : ''"
          (toggled)="toggleSelection(insight)">
        </gap-ui-insight-card>
      </div>
    </div>
    <a *ngIf="pinnedInsights.length > 0 && pinnedInsights.length > pinnedInsightsMaxIndex"
      (click)="loadMorePinnedInsights()" class="load-pinned-insights">
      Load more
      <i class="material-icons">
        expand_more
      </i>
    </a>
  </ng-container>
  `,
  styles: [`
    .insight-action-bar {
      font-size: .9rem;
      font-weight: 400;
      color: $gap-blue;

      a, button {
        color: $gap-blue;
        cursor: pointer;
      }
    }
    .no-pinned-insights {
      margin: 0 0 40px 0;
      h4 {
        margin: 0 0 5px;
      }
      p {
        font-size: 15px;
      }
    }
    .pinned-insights {
      margin-bottom: 0 !important; /* override mb-5 */
      &.all-insights-loaded {
        margin-bottom: 30px !important;
      }
    }
    .load-pinned-insights {
      margin: 10px 0 30px;
      display: flex;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      span {
        margin: 10px 0 30px;
        text-align: center;
        display: block;
      }
      i {
        margin-top: -2px;
        font-size: 24px;
      }
    }
  `],
})
export class PinnedInsightsComponent implements OnInit {
  private _refresh$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  pinnedInsights$: Observable<Insight[]> = this._refresh$.asObservable().pipe(
    switchMap(() => this._user.meAsync()),
    map((user) => user.insights || []),
    withLatestFrom(this._account.activeAccount$),
    map(([insights, account]: [IInsight[], IAccount]) => {
      return insights.filter((insight) => insight.customerNumber === account.accountId);
    }),
    map((insights) => {
      return insights.map((insight: IInsight) => {
        const selected = this.selectedInsightIds.includes(insight.id);
        return new Insight(insight.id, insight.answerText, insight.automateOn, selected);
      });
    }),
  );
  selectedInsightIds: string[] = [];
  get selectedCount(): number {
    return this.selectedInsightIds.length;
  }
  selectedInsights$: Observable<Insight[]> = this.pinnedInsights$.pipe(
    map((insights) => insights.filter((i) => i.selected)),
  );
  readonly pinnedInsightsNumPerLoad: number = 3;
  pinnedInsightsMaxIndex: number = 3;

  constructor(
    private _user: UserService,
    private _insights: InsightsService,
    private _account: AccountService,
    private _modal: NgbModal) { }

  ngOnInit() {
  }

  private _sub$: Subscription[] = [];
  removeInsights($event: MouseEvent) {
    const ref = this._modal.open(ConfirmModalComponent, { centered: true });
    ref.componentInstance.setUp('DASHBOARD.PINNED_INSIGHTS.REMOVE_MODAL');

    const removalSub = from(ref.result).pipe(
      flatMap((confirm) => confirm ? this.selectedInsights$ : empty),
      flatMap((insights) => {
        return concat(...insights.map((insight) => {
          return this._insights.removeInsight(insight.id).pipe(
            mapTo(insight.id),
            catchError(() => of(insight.id)),
          );
        }));
      }),
    ).subscribe((_) => {
      // update user from api
      this.selectedInsightIds = [];
      this._refresh$.next(true);
    });

    // clean up
    this._sub$.push(removalSub);
  }

  loadMorePinnedInsights() {
    this.pinnedInsightsMaxIndex += this.pinnedInsightsNumPerLoad;
  }

  toggleSelection(insight: Insight) {
    const index = this.selectedInsightIds.indexOf(insight.id);
    if (index > -1) {
      this.selectedInsightIds.splice(index, 1);
    }
    else {
      this.selectedInsightIds.push(insight.id);
    }
  }

  addInsightToAutomation() {
    this.selectedInsights$.pipe(
      flatMap((insights) => {
        return concat(...insights.map((insight) => {
          return this._insights.automateInsight(insight.id).pipe(
            mapTo(insight.id),
            catchError(() => of(insight.id)),
          );
        }));
      }),
    ).subscribe((_) => {
      // update user from api
      this.selectedInsightIds = [];
      this._refresh$.next(true);
    });
  }

  unscheduleInsight() {
    this.selectedInsights$.pipe(
      flatMap((insights) => {
        return concat(...insights.map((insight) => {
          return this._insights.removeAutomation(insight.id).pipe(
            mapTo(insight.id),
            catchError(() => of(insight.id)),
          );
        }));
      }),
    ).subscribe((_) => {
      // update user from api
      this.selectedInsightIds = [];
      this._refresh$.next(true);
    });
  }

  ngOnDestroy() {
    this._sub$.forEach((sub) => sub.unsubscribe());
  }

}
