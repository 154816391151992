import {
  Component,
  OnInit,
} from '@angular/core';

import {
  Alert,
  AlertType,
} from '../_models';
import { AlertService } from '../_services/alert.service';

@Component({
  moduleId: module.id,
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss'],
})

export class AlertComponent implements OnInit {
  alerts: Alert[] = [];

  constructor(private _alertService: AlertService) {
  }

  ngOnInit() {
    this._alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.SUCCESS:
        return 'alert alert-success';
      case AlertType.ERROR:
        return 'alert alert-danger';
      case AlertType.INFO:
        return 'alert alert-info';
      case AlertType.WARNING:
        return 'alert alert-warning';
    }
  }
}
