import { Component, Input } from '@angular/core';

@Component({
  selector: 'gap-ui-progress',
  template: `
  <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
      [ngStyle]="inlineStyles" attr.aria-valuenow="{{current}}"
      aria-valuemin="0" attr.aria-valuemax="{{total}}">{{ percentage }}%</div>
  </div>
  `,
  styles: [`
    :host {
      display: block;
      width: 100%;
      text-align: center;
    }
  `],
})
export class ProgressComponent {
  @Input()
  current: number = 0;
  @Input()
  total: number = 100;

  get percentage() {
    return Math.floor((this.current / this.total) * 100);
  }

  get inlineStyles() {
    const styles = {};
    styles['width.%'] = this.percentage;
    return styles;
  }

}
