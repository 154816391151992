import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-external-links-nav',
  templateUrl: './external-links-nav.component.html',
  styles: [],
})
export class ExternalLinksNavComponent {
  @Input()
  hideUserFacing: boolean = false;
  @Input()
  enableCantrackLink: boolean = environment.enabledCantrack;
}
