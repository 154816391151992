import { IInsightQuestionNode, InsightsInitialQuestionId } from '../_models';
import { SupportedFilterTypes } from '../../report-management/_state/generic/filter';
import { ReportTypes, HireMovementTypes, TimePeriods } from '@waracle/gap-sdk';
import { Validators } from '@angular/forms';
import { basicStringRegex } from '../../report-management/_config/shared';

export const SHARED_FILTERS = [
  {
    id: 'customerNumber',
    type: SupportedFilterTypes.CHOICE,
    validators: [Validators.pattern(/^[A-Z]{4}[0-9]{4}$/)],
  },
  {
    id: 'orderNumber',
    type: SupportedFilterTypes.STRING,
    validators: [
      Validators.minLength(4),
      Validators.pattern(basicStringRegex),
    ],
  },
  {
    id: 'contractNumber',
    type: SupportedFilterTypes.CHOICE,
    validators: [Validators.pattern(/^[A-Z][0-9]{6}$/)],
  },
  {
    id: 'productCode',
    type: SupportedFilterTypes.STRING,
    validators: [Validators.pattern(basicStringRegex)],
  },
  {
    id: 'productName',
    type: SupportedFilterTypes.STRING,
    validators: [Validators.pattern(basicStringRegex)],
  },
  {
    id: 'addressLine1',
    type: SupportedFilterTypes.STRING,
    validators: [Validators.pattern(basicStringRegex)],
  },
  {
    id: 'all',
    type: SupportedFilterTypes.STRING,
    validators: [],
  },
];

export const ITEM_FILTERS = [
  {
    id: 'fleetNumber',
    type: SupportedFilterTypes.CHOICE,
    validators: [Validators.pattern(basicStringRegex)],
  },
  {
    id: 'depotName',
    type: SupportedFilterTypes.DROPDOWN,
    validators: [Validators.pattern(basicStringRegex)],
  },
  ...SHARED_FILTERS,
];

export const TRANSACTION_FILTERS = [
  {
    id: 'invoiceNumber',
    type: SupportedFilterTypes.CHOICE,
    validators: [],
  },
  {
    id: 'invoiceType',
    type: SupportedFilterTypes.DROPDOWN,
    validators: [],
  },
  ...SHARED_FILTERS,
];

export const INSIGHTS: IInsightQuestionNode[] = [
  {
    id: 'items_hired',
    text: 'What items are on hire?',
    insightType: InsightsInitialQuestionId.ITEMS,
    filters: ITEM_FILTERS,
    reportType: ReportTypes.LIVE_HIRE,
    baseQuery: {},
    periodProp: 'onHireDateFrom',
    ignorePeriods: [TimePeriods.EVER],
  },
  {
    id: 'items_off_hired',
    text: 'What items have been off-hired?',
    insightType: InsightsInitialQuestionId.ITEMS,
    filters: ITEM_FILTERS,
    reportType: ReportTypes.HIRE_MOVEMENT,
    baseQuery: {
      hireMovementType: HireMovementTypes.OFF_HIRED,
    },
    periodProp: 'onHireDateFrom',
    ignorePeriods: [TimePeriods.EVER],
  },
  {
    id: 'items_exchanged',
    text: 'What items have been exchanged?',
    insightType: InsightsInitialQuestionId.ITEMS,
    filters: ITEM_FILTERS,
    reportType: ReportTypes.HIRE_MOVEMENT,
    baseQuery: {
      hireMovementType: HireMovementTypes.EXCHANGED,
    },
    ignorePeriods: [TimePeriods.EVER],
  },
  {
    id: 'items_purchased',
    text: 'What items have been purchased?',
    insightType: InsightsInitialQuestionId.ITEMS,
    filters: ITEM_FILTERS,
    reportType: ReportTypes.HIRE_MOVEMENT,
    baseQuery: {
      hireMovementType: HireMovementTypes.SALE,
    },
    periodProp: 'onHireDateFrom',
    ignorePeriods: [TimePeriods.EVER],
  },
  {
    id: 'transactions_occurring',
    text: 'What transactions are occurring?',
    insightType: InsightsInitialQuestionId.TRANSACTIONS,
    filters: TRANSACTION_FILTERS,
    reportType: ReportTypes.INVOICE,
    periodProp: 'invoiceDateFrom',
    ignorePeriods: [TimePeriods.YEAR, TimePeriods.BIENNIAL, TimePeriods.EVER],
  },
];
