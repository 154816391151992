import { SearchResult } from './search.result';
import * as stream from 'stream';
import { IModel } from '../models/base.model';

export class CursorSearchResult<T extends IModel> extends SearchResult<T> {

  protected readonly _cursor: stream.Readable;

  constructor(total: number = 0, cursor: stream.Readable) {
    super(0, total, []);
    this._cursor = cursor;
  }

  get cursor(): stream.Readable {
    return this._cursor;
  }
}
