import { Type } from '@angular/core';
import { ValidatorFn, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';

export enum SupportedFilterTypes {
  STRING,
  NUMBER,
  CURRENCY,
  DATE,
  CHOICE,
  DROPDOWN,
}

export interface IReportFilter {
  id: string;
  label: string;
  value: any;
  required?: boolean;
}

// context relating to IReportFilter (stored in state)
export interface IReportFilterContextDTO {
  id: string;
  type: SupportedFilterTypes;
  validators: ValidatorFn[];
  default?: any;
  required?: boolean;
}

// functions to handle formating/outputting of data for a filter type
export interface IReportFilterContext extends IReportFilterContextDTO {
  format: (value: any) => string;
  toSearch: (value: any) => string;
  addValidator: (validator: ValidatorFn) => void;
  required?: boolean;
}
// component to render input for a filter type
export interface IReportFilterInput extends ControlValueAccessor {
  // need generic way to handle input element "@Input"'s
  // (called props here cause too many inputs)
  setProps(data: any): void;
  setValidators(validators: ValidatorFn[]): void;
  activeFilter: IReportFilterContext;
  setFocus();
}

export interface IReportEnumFilterContext extends IReportFilterContext {
  choices$: (query?: string) => Observable<string[]>;
}

export interface IReportDropdownFilterContext extends IReportFilterContext {
  dropdownChoices$: (query?: string) => Observable<{label: string, value: string}[]>;
}

export function isEnumFilter(
  filterOption: IReportEnumFilterContext | IReportFilterContext,
): filterOption is IReportEnumFilterContext {
  return Boolean((filterOption as IReportEnumFilterContext).choices$);
}

export function isDropdownFilter(
  filterOption: IReportDropdownFilterContext | IReportEnumFilterContext | IReportFilterContext,
): filterOption is IReportDropdownFilterContext {
  return Boolean((filterOption as IReportDropdownFilterContext).dropdownChoices$);
}

export interface IReportRangeFilterContext extends IReportFilterContextDTO {
  formatStart(value: any): string;
  formatEnd(value: any): string;
}

export function isRangeFilter(
  filterOption: IReportRangeFilterContext | IReportFilterContext,
): filterOption is IReportRangeFilterContext {
  return Boolean((filterOption as IReportRangeFilterContext).formatEnd);
}

// link context to filter component
export interface IReportFilterOption {
  context: IReportFilterContext;
  component: Type<IReportFilterInput>;
}

export interface IReportFilterOptions {
  [key: string]: IReportFilterOption;
}

export interface IReportFilterState {
  active: IReportFilter[];
  ignoredOptions?: string[];
  additionalOptions: string[];
  customOptions: IReportFilterOptions;
}

export const initialReportFilterState: IReportFilterState = {
  active: [],
  ignoredOptions: [],
  additionalOptions: [],
  customOptions: {},
};
