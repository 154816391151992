import { Component, ViewChild, OnInit } from '@angular/core';
import { setupInputProviders } from '../_helpers';
import { DefaultInputComponent } from '../default/default.component';
import { NgbInputDatepicker, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { iSOtoNgbDate } from '../../_state/generic/helpers';
import { Observable, of } from 'rxjs';
import { DateFilterModel } from './date-filter.model';

const todaysDate = iSOtoNgbDate(new Date().toISOString());

@Component({
  selector: 'report-filter-date',
  template: `
  <input #rawInputRef
    class="form-control mt-0 placeholder-with-icon"
    placeholder="{{ placeholder | i18n:'REPORT.FILTERS' | translate }}"
    [formControl]="inputControl"
    (click)="openRange()"
    (keypress)="blockPress($event)"
    type="text"
    autocomplete="off"/>
  <input type="text"
    ngbDatepicker
    placement="bottom-left"
    #d="ngbDatepicker"
    (dateSelect)="onDateSelection($event)"
    autoClose="outside"
    outsideDays="hidden"
    [minDate]="getFilterDateMinimum() | async"
    [maxDate]="getFilterDateMaximum() | async"
    style="visibility:hidden;height:0;margin:0;padding:0"
    />
  <ng-template #t let-date let-focused="focused">
    <div class="custom-day btn-light">
        {{ date.day }}
    </div>
  </ng-template>
  `,
  styles: [`
    :host {
      height: 3rem;
      display: block;
    }
    input.form-control {
      width: 100%;
    }
  `],
  providers: setupInputProviders(DateInputComponent),
})
export class DateInputComponent extends DefaultInputComponent implements OnInit {

  @ViewChild('d', { static: true }) rangePicker: NgbInputDatepicker;
  private _query: string;

  openRange(defaults: NgbDate = todaysDate) {
    this.setQueryValue(defaults);
    this.rangePicker.open();
  }

  getFilter(): DateFilterModel {
    return this.activeFilter as DateFilterModel;
  }
  getQueryValue() {
    return this._query;
  }
  setQueryValue(value: any): void {
    this._query = this.activeFilter.toSearch(value);
  }
  getFormattedValue() {
    return this.activeFilter.format(this._query);
  }

  onDateSelection(date: NgbDate) {
    this.setQueryValue(date);
    const formatted = this.getFormattedValue();
    this.inputControl.setValue(formatted);
    this.rangePicker.close();
    this._onChange(date);
  }

  getFilterDateMinimum(): Observable<NgbDateStruct> {
    return of({
      year: 0,
      month: 0,
      day: 0,
    });
  }

  getFilterDateMaximum(): Observable<NgbDateStruct> {
    return of({
      year: todaysDate.year,
      month: todaysDate.month,
      day: todaysDate.day,
    });
  }

  ngOnInit() {
    this.rangePicker.open();
  }

  blockPress(event: KeyboardEvent) {
    event.returnValue = false;
    event.preventDefault();
  }
}
