import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IAccount } from '../user-management/_state/accounts/account.model';
import { AccountService } from './account.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {

  constructor(
    private authService: AuthenticationService,
    private accountService: AccountService
  ) { }

  public getCertificatesUrl(): Observable<string | null> {
    return this.accountService.activeAccount$.pipe(
      map((account: IAccount) => {
        if (!account || !this.authService.authentication?.token || !environment.certificatesUrl) {
          return null;
        }

        const url = new URL(environment.certificatesUrl);
        url.searchParams.append('accountId', btoa(`${this.authService.authentication?.user?.id}_${account.accountId}`));
        url.searchParams.append('token', `JWT ${this.authService.authentication.token}`);
        return url.toString();
      })
    );
  }

}
