import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  IAccount,
  IUser,
} from '@waracle/gap-sdk';
import { UserService } from '../../_services/user.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { AccountService } from '../../_services/account.service';

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss'],
})
export class DashHeaderComponent implements OnInit {

  @Output() menuToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _userService: UserService,
              private _authenticationService: AuthenticationService,
              private _accountService: AccountService) {
    this._user = this._userService.me();
  }

  private _user: IUser;
  activeAccount: IAccount;

  ngOnInit() {
    this._accountService.activeAccount$.subscribe((account) => {
      this.activeAccount = account;
    });
  }

  openMenu() {
    this.menuToggled.emit(true);
  }

  get user(): IUser {
    return this._user;
  }

  logout() {
    this._authenticationService.logout();
  }

}
