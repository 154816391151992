import { ReportTypes } from './report.model';
import {
  EntityModel,
  IBaseModel,
} from './base.model';

export enum TimePeriods {
  WEEK = 'last week',
  MONTH = 'last month',
  QUARTER = 'last 3 months',
  HALF = 'last 6 months',
  YEAR = 'last 12 months',
  BIENNIAL = 'last 24 months',
  EVER = 'all available data',
}

export interface IInsight extends EntityModel<IInsightDTO> {
}

export interface IInsightDTO extends IBaseModel {
  answerText: string;
  automateOn?: string; // cron expression
  /**
   * The account/customer number
   * @type {string}
   */
  customerNumber: string;
  /**
   * Is the insight pinned?
   *
   * @type {boolean}
   */
  isPinned?: boolean;
  period?: TimePeriods;
  periodProperty?: string;
  property: string;
  query: string;
  questionId: string;
  questionText: string;
  reportType: ReportTypes;
  /**
   * The user that adds the insights
   * @type {string}
   */
  userId?: string;
}
