import { IInvoice } from '../models/reports/invoice.model';
import { ITextSearchFilter, TextSearchFilter } from './textSearch.filter';

export interface IInvoiceReportSearchFilter extends ITextSearchFilter {
  /**
   * Search for (part of) the concatenated addressLines
   * @type {string}
   * @since 0.32.0
   */
  address: string;
  contractNumber: string;
  customerName: string;
  customerNumber: string;
  customerOrderReference: string;
  // damageDetails: string;
  /**
   * @deprecated
   */
  ecode: string;
  invoiceDate?: string;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  invoiceNumber: string;
  invoiceType: string;
  itemName: string;
  itemType: string;
  lineText: string;
  lineType: string;
  offHireDateFrom: string;
  offHireDateTo: string;
  /**
   * @deprecated
   * @see {@link parentAccountId}
   */
  payer: string;
  parentAccountId: string;
  productCode: string;
  quantity: number;
  queryCode: string;
  serviceItem: string;
  /**
   * The sub account/customer number to filter results by
   * @type {string}
   * @since 0.48.0
   */
  subAccountNumber: string;
  // vatAmount: number;
  // vatRate: number;
  weekRate: number;
  // weeksCharged: string;
}

export class InvoiceReportSearchFilter
  extends TextSearchFilter
  implements IInvoiceReportSearchFilter, Partial<IInvoice>
{
  /**
   * Search a concatenated string of addressLines
   * @type {string}
   * @since 0.32.0
   */
  private _address: string;
  private _contractNumber: string;
  private _customerName: string;
  private _customerNumber: string;
  private _customerOrderReference: string;
  /**
   * @deprecated
   */
  private _ecode: string;
  private _invoiceDate?: string;
  private _invoiceDateFrom: string;
  private _invoiceDateTo: string;
  private _invoiceNumber: string;
  private _invoiceType: string;
  private _itemName: string;
  private _itemType: string;
  private _lineText: string;
  private _lineType: string;
  private _offHireDateFrom: string;
  private _offHireDateTo: string;
  private _payer: string;
  private _parentAccountId: string;
  private _productCode: string;
  private _quantity: number;
  private _queryCode: string;
  private _serviceItem: string;
  private _subAccountNumber: string;

  private _weekRate: number;

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get contractNumber(): string {
    return this._contractNumber;
  }

  set contractNumber(value: string) {
    this._contractNumber = value;
  }

  get customerName(): string {
    return this._customerName;
  }

  set customerName(value: string) {
    this._customerName = value;
  }

  get customerNumber(): string {
    return this._customerNumber;
  }

  set customerNumber(value: string) {
    this._customerNumber = value;
  }

  get customerOrderReference(): string {
    return this._customerOrderReference;
  }

  set customerOrderReference(value: string) {
    this._customerOrderReference = value;
  }

  get ecode(): string {
    return this._ecode;
  }

  set ecode(value: string) {
    this._ecode = value;
  }

  get invoiceDate(): string {
    return this._invoiceDate;
  }

  set invoiceDate(value: string) {
    this._invoiceDate = value;
  }

  get invoiceDateFrom(): string {
    return this._invoiceDateFrom;
  }

  set invoiceDateFrom(value: string) {
    this._invoiceDateFrom = value;
  }

  get invoiceDateTo(): string {
    return this._invoiceDateTo;
  }

  set invoiceDateTo(value: string) {
    this._invoiceDateTo = value;
  }

  get invoiceNumber(): string {
    return this._invoiceNumber;
  }

  set invoiceNumber(value: string) {
    this._invoiceNumber = value;
  }

  get invoiceType(): string {
    return this._invoiceType;
  }

  set invoiceType(value: string) {
    this._invoiceType = value;
  }

  get itemName(): string {
    return this._itemName;
  }

  set itemName(value: string) {
    this._itemName = value;
  }

  get itemType(): string {
    return this._itemType;
  }

  set itemType(value: string) {
    this._itemType = value;
  }

  get lineText(): string {
    return this._lineText;
  }

  set lineText(value: string) {
    this._lineText = value;
  }

  get lineType(): string {
    return this._lineType;
  }

  set lineType(value: string) {
    this._lineType = value;
  }

  get offHireDateFrom(): string {
    return this._offHireDateFrom;
  }

  set offHireDateFrom(value: string) {
    this._offHireDateFrom = value;
  }

  get offHireDateTo(): string {
    return this._offHireDateTo;
  }

  set offHireDateTo(value: string) {
    this._offHireDateTo = value;
  }

  get parentAccountId(): string {
    return this._parentAccountId;
  }

  set parentAccountId(value: string) {
    this._parentAccountId = value;
  }

  get payer(): string {
    return this._payer;
  }

  set payer(value: string) {
    this._payer = value;
  }

  get productCode(): string {
    return this._productCode;
  }

  set productCode(value: string) {
    this._productCode = value;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(value: number) {
    this._quantity = value;
  }

  get queryCode(): string {
    return this._queryCode;
  }

  set queryCode(value: string) {
    this._queryCode = value;
  }

  get serviceItem(): string {
    return this._serviceItem;
  }

  set serviceItem(value: string) {
    this._serviceItem = value;
  }

  get subAccountNumber(): string {
    return this._subAccountNumber;
  }

  set subAccountNumber(value: string) {
    this._subAccountNumber = value;
  }

  get weekRate(): number {
    return this._weekRate;
  }

  set weekRate(value: number) {
    this._weekRate = value;
  }

  constructor(limit = 1000, page = 1, maxLimit = 1000000) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000000
    super(limit, page, maxLimit);
    // set the default sort for the live hire reports to be the onHireDate
    this.sort = '-invoiceDate';
  }

  public toJS(): IInvoiceReportSearchFilter {
    return Object.assign(super.toJS(), {
      address: this.address,
      contractNumber: this.contractNumber,
      customerName: this.customerName,
      customerNumber: this.customerNumber,
      customerOrderReference: this.customerOrderReference,
      ecode: this.ecode,
      invoiceDateFrom: this.invoiceDateFrom,
      invoiceDateTo: this.invoiceDateTo,
      invoiceNumber: this.invoiceNumber,
      invoiceType: this.invoiceType,
      itemName: this.itemName,
      itemType: this.itemType,
      lineText: this.lineText,
      lineType: this.lineType,
      offHireDateFrom: this.offHireDateFrom,
      offHireDateTo: this.offHireDateTo,
      parentAccountId: this.parentAccountId,
      payer: this.payer,
      productCode: this.productCode,
      quantity: this.quantity,
      queryCode: this.queryCode,
      serviceItem: this.serviceItem,
      subAccountNumber: this.subAccountNumber,
      weekRate: this.weekRate,
    });
  }
}
