import {
  SupportedFilterTypes,
  IReportEnumFilterContext,
} from '../../_state/generic/filter';
import { DefaultFilterModel } from '../default/default-filter.model';
import { Observable, of } from 'rxjs';

export class ChoiceFilterModel extends DefaultFilterModel implements IReportEnumFilterContext {
  constructor(public id: string) {
    super(id, SupportedFilterTypes.CHOICE);
  }
  choices$: (query: string) => Observable<string[]> = (query: string) => of([]);
}
