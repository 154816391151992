import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';

const ALLOWED_401_URLS = [
  'v1/login',
  'v1/user/request-new',
];

function doesUrlMatchAllowSegments(url: string, allowedSegments: string[]): boolean {
  let allowed: boolean = false;
  for (let i = 0; i < allowedSegments.length; i += 1) {
    allowed = url.includes(allowedSegments[i]) || allowed;
  }
  return allowed;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthenticationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 && !doesUrlMatchAllowSegments(err.url, ALLOWED_401_URLS)) {
              this._auth.logout();
            }
          }
        },
      ),
    );
  }
}
