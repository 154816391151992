import {
  IReportSearchFilter,
  ReportSearchFilter,
} from './reportSearch.filter';
import { IAccrual } from '../models/reports/accrual.model';

export interface IAccrualReportSearchFilter extends IReportSearchFilter {
  contractDate: string; // yyyy-mm-dd (ISO8601)
  offHireDateFrom: string; // yyyy-mm-dd (ISO8601)
  offHireDateTo: string; // yyyy-mm-dd (ISO8601)
  originalContractNumber: string;
  quantityReturned: number; // yyyy-mm-dd (ISO8601)
  terminatedReasonCode: string;
}

export class AccrualReportSearchFilter
  extends ReportSearchFilter
  implements IAccrualReportSearchFilter, Partial<IAccrual> {

  constructor(limit: number = 1000, page: number = 1, maxLimit: number = 1000000) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000000
    super(limit, page, maxLimit);
    // set the default sort for the live hire reports to be the onHireDate
    this.sort = '-onHireDate';
  }

  private _contractDate: string;
  private _offHireDateFrom: string;
  private _offHireDateTo: string;
  private _originalContractNumber: string;
  private _quantityReturned: number;
  private _terminatedReasonCode: string;

  get contractDate(): string {
    return this._contractDate;
  }

  set contractDate(value: string) {
    this._contractDate = value;
  }

  get offHireDateFrom(): string {
    return this._offHireDateFrom;
  }

  set offHireDateFrom(value: string) {
    this._offHireDateFrom = value;
  }

  get offHireDateTo(): string {
    return this._offHireDateTo;
  }

  set offHireDateTo(value: string) {
    this._offHireDateTo = value;
  }

  get originalContractNumber(): string {
    return this._originalContractNumber;
  }

  set originalContractNumber(value: string) {
    this._originalContractNumber = value;
  }

  get quantityReturned(): number {
    return this._quantityReturned;
  }

  set quantityReturned(value: number) {
    this._quantityReturned = value;
  }

  get terminatedReasonCode(): string {
    return this._terminatedReasonCode;
  }

  set terminatedReasonCode(value: string) {
    this._terminatedReasonCode = value;
  }

  public toJS(): IAccrualReportSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        contractDate: this.contractDate,
        offHireDateFrom: this.offHireDateFrom,
        offHireDateTo: this.offHireDateTo,
        originalContractNumber: this.originalContractNumber,
        quantityReturned: this.quantityReturned,
        terminatedReasonCode: this.terminatedReasonCode,
      },
    );
  }
}
