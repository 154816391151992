import { AppError } from '../errors';

export class ApiError extends AppError {
  private readonly _endpoint: string;

  constructor(message: string, endpoint: string, error?: Error) {
    super(message, error);
    this._endpoint = endpoint;
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  get endpoint(): string {
    return this._endpoint;
  }
}
