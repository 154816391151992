import { Component, Input } from '@angular/core';
import { IAccount } from '@waracle/gap-sdk';

@Component({
  selector: 'app-key-contact-details',
  templateUrl: './key-contact-details.component.html',
  styleUrls: ['./key-contact-details.component.scss'],
})
export class KeyContactDetailsComponent {
  @Input() account: IAccount;
}
