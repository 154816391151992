import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PasswordService } from '../../_services/password.service';

@Component({
  selector: 'app-auth-forgotten',
  templateUrl: './auth-forgotten.component.html',
  styleUrls: ['./auth-forgotten.component.scss'],
})
export class AuthForgottenComponent implements OnInit {

  loading = false;
  isSent = false;
  resetButton = false;
  emailFocus = false;

  forgetForm: FormGroup;
  email: FormControl;

  constructor(
    private _passwordService: PasswordService) {
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  forget() {
    this.loading = true;
    this._passwordService.forget(this.forgetForm.value.email)
      .subscribe(
        (data) => {

          this.isSent = true;
          this.loading = false;
          this.resetButton = true;

          setTimeout(() => {
            this.isSent = false;
            this.resetButton = false;
          },         6000);

        });
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('[^ @]*@[^ @]* ?'),
    ]);
  }

  createForm() {
    this.forgetForm = new FormGroup({
      email: this.email,
    });
  }

}
