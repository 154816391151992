import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ErrorModalService {
  constructor(private _modal: NgbModal) {}
  async open(instance: any, componentClass: any, retryFn: Function, status: number, params: any = {}) {
    if (status !== 0 && status < 500) {
      return;
    }
    const modalRef = this._modal.open(componentClass, {
      centered: true,
      windowClass: 'error-modal',
    });
    modalRef.componentInstance.setUp(params);
    await modalRef.result.then(
      (confirm) => {
        retryFn.bind(instance)();
        return true;
      },
      (cancel) => {
        return false;
      },
    );
  }
}
