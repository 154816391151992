import {
  Component,
  Input,
} from '@angular/core';
import { IUser } from '@waracle/gap-sdk';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent {

  @Input() user: IUser;
}
