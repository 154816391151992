import { ApiError } from './api.error';
import { IncomingMessage } from 'http';
import { RequestOptions } from 'https';

export class ApiResponseError extends ApiError {

  constructor(message: string,
              endpoint: string,
              private _request: RequestOptions,
              private _response: IncomingMessage,
              error?: Error) {
    super(message, endpoint, error);
    Object.setPrototypeOf(this, ApiResponseError.prototype);
  }

  get request(): RequestOptions {
    return this._request;
  }

  get response(): IncomingMessage {
    return this._response;
  }
}
