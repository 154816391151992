import { IReportFilterContextDTO } from '../../report-management/_state/generic/filter';
import { ReportTypes, TimePeriods } from '@waracle/gap-sdk';

export enum InsightsInitialQuestionId {
  ITEMS = 'items',
  TRANSACTIONS = 'transactions',
}

export interface IInsightQuestionNode {
  id: string;
  text: string;
  insightType: InsightsInitialQuestionId;
  reportType: ReportTypes;
  filters?: IReportFilterContextDTO[];
  baseQuery?: {[k: string]: string};
  periodProp?: string;
  ignorePeriods?: TimePeriods[];
}

export interface IInsight {
  reportType: ReportTypes;
  questionId: string;
  answerText: string;
  property: string;
  query: string;
}
