import { Map } from 'immutable';
import { IModel } from '../models/base.model';
import { ISearchResult } from '../models/search.result.model';

export class SearchResult<T extends IModel> implements ISearchResult<IModel> {
  protected readonly _count: number = 0;
  protected readonly _total: number = 0;
  protected readonly _results: T[] = [] as T[];

  constructor(count: number = 0, total: number = 0, results: T[] = []) {
    this._count = count;
    this._total = total;
    this._results = results;
  }

  get count(): number {
    return this._count;
  }

  get total(): number {
    return this._total;
  }

  get results(): T[] {
    return this._results;
  }

  public toJSON(): string {
    return JSON.stringify(this.toJS());
  }

  public toJS = () => {
    return Map({
      count: this.count,
      total: this.total,
      results: this.results.map((result: T) => result.toJS()),
    }).toJS();
  };
}
