import { formatDate } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

import {
  IReportFilterContext,
  SupportedFilterTypes,
} from '../../_state/generic/filter';
import { DefaultFilterModel } from '../default/default-filter.model';
import { environment } from '../../../../environments/environment';
import { ngbDateToISO } from '../../_state/generic/helpers';

export class DateFilterModel
  extends DefaultFilterModel
  implements IReportFilterContext
{
  constructor(public id: string) {
    super(id, SupportedFilterTypes.DATE);
  }
  format(value: any) {
    if (!value) {
      return '';
    }

    if (typeof value !== 'string') {
      value = this.toSearch(value);
    }
    return formatDate(value, environment.dateFormat, 'en-US');
  }

  toSearch(value: NgbDate): string {
    return ngbDateToISO(value);
  }
}
