import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { IReportFilterInput, IReportFilterContext } from '../../_state/generic/filter';
import { FormControl, ValidatorFn } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { concat } from 'rxjs/operators';
import { setupInputProviders } from '../_helpers';

@Component({
  selector: 'report-input-default',
  template: `
    <input #rawInputRef
      class="form-control mt-0 placeholder-with-icon"
      placeholder="{{ placeholder | i18n:'REPORT.FILTERS' | translate }}"
      [formControl]="inputControl"
      (input)="onChange($event)"
      type="text"/>
  `,
  styles: [`
    input.form-control {
      width: 100%;
    }
  `],
  providers: setupInputProviders(DefaultInputComponent),
})
export class DefaultInputComponent implements IReportFilterInput {
  @Input() placeholder: string;
  @Input() activeFilter: IReportFilterContext;
  setFocus() {
    setTimeout(() => {
      this.inputRef.nativeElement.focus();
    });
  }

  @ViewChild('rawInputRef', { read: ElementRef, static: true }) inputRef: ElementRef;
  inputControl: FormControl = new FormControl();

  setProps(data: { placeholder?: string, activeFilter: IReportFilterContext }) {
    this.placeholder = data.placeholder || 'NONE';
    this.activeFilter = data.activeFilter;
  }

  setValidators(validators: ValidatorFn[]) {
    this.inputControl.setValidators(validators);
  }

  writeValue(obj: any): void {
    try {
      this.inputRef.nativeElement.value = obj;
      this.inputControl.setValue(obj);
    }
    catch (e) {
      throw new Error('Method not implemented.');
    }
  }
  onChange($event: any) {
    this._onChange($event.target.value);
  }
  // tslint:disable-next-line:no-console
  protected _onChange: (value: any) => any = (val) => console.log(val);
  registerOnChange(fn: any): void {
    try {
      this._onChange = fn;
    }
    catch (e) {
      throw new Error('Method not implemented.');
    }
  }
  private _touchSub: Subscription;
  registerOnTouched(fn: any): void {
    const focus$ = fromEvent(this.inputRef.nativeElement, 'focus');
    const click$ = fromEvent(this.inputRef.nativeElement, 'click');
    const touched$ = focus$.pipe(
      concat(click$),
    );
    try {
      this._touchSub = touched$.subscribe((event: any) => fn(event));
    }
    catch (e) {
      throw new Error('Method not implemented.');
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    try {
      if (isDisabled) {
        this.inputRef.nativeElement.setAttribute('disabled', isDisabled);
      }
      else {
        this.inputRef.nativeElement.removeAttribute('disabled');
      }
    }
    catch (e) {
      throw new Error('Method not implemented.');
    }
  }

  ngOnDestroy() {
    this._touchSub && this._touchSub.unsubscribe();
  }
}
