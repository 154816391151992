import { Component, Input } from '@angular/core';

export enum spinnerTypes {
  BORDER = 'border',
  GROW = 'grow',
}

export enum spinnerSizes {
  LARGE = 8,
  REGULAR = 4,
  SMALL = 'sm',
}

export enum spinnerColors {
  PRIMARY = 'text-primary',
  SECONDARY = 'text-secondary',
  SUCCESS = 'text-success',
  DANGER = 'text-danger',
  WARNING = 'text-warning',
  INFO = 'text-info',
  LIGHT = 'text-light',
  DARK = 'text-dark',
}

@Component({
  selector: 'gap-ui-spinner',
  template: `
    <div [attr.class]="classNames" [ngStyle]="inlineStyles" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      width: 100%;
      text-align: center;
    }
  `],
})
export class SpinnerComponent {
  @Input()
  type: spinnerTypes = spinnerTypes.BORDER;
  @Input()
  size: spinnerSizes = spinnerSizes.REGULAR;
  @Input()
  color: spinnerColors = spinnerColors.PRIMARY;

  get classNames() {
    const baseClass = `spinner-${this.type}`;
    const sizeClass = this.size === spinnerSizes.SMALL ? `${baseClass}-${this.size}` : '';
    const colorClass = this.color;
    return `${baseClass} ${sizeClass} ${colorClass}`;
  }

  get inlineStyles() {
    const styles = {};
    if (this.size !== spinnerSizes.SMALL) {
      styles['width.rem'] = this.size;
      styles['height.rem'] = this.size;
    }
    return styles;
  }

}
