import { ReportTypes, LiveHireReportSearchFilter } from '@waracle/gap-sdk';
import { createSelector } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { getReportManagementState } from '../../_state/report-management.selectors';
import { IReportManagementState } from '../../_state/report-management.model';
import {
  getReportActionTypes,
  getReportActions,
  ReportAction,
} from '../../_state/generic/actions';
import {
  getInitialReportState,
  IReportState,
} from '../../_state/generic/state';
import { IReportDTO } from '../../_state/generic/model';
import { IReportFilterOptions } from '../../_state/generic/filter';
import { createReportReducers } from '../../_state/generic/reducers';
import {
  createReportEntityAdapter,
  IReportEntityAdapter,
} from '../../_state/generic/adapter';
import { IReportEntitySelectors } from '../../_state/generic/selectors';
import {
  hireReportFilterOptions,
  hireCustomFilterOptions,
  gapTrackFilter,
} from '../../_config/hire-report';

export const additionalFilterOptions = [
  ...hireReportFilterOptions,
  environment.enabledCantrack ? 'gaptrack' : '',
];

export interface ILiveHireReport extends IReportDTO {
  reportType: ReportTypes.LIVE_HIRE;
  gaptrack: boolean;
}

export const LIVE_HIRE_ACTION_TYPES = getReportActionTypes('live-hire');

export const LIVE_HIRE_ACTIONS = getReportActions<ILiveHireReport>(
  LIVE_HIRE_ACTION_TYPES
);

export const customFilterOptions: IReportFilterOptions = {
  ...hireCustomFilterOptions,
  gaptrack: gapTrackFilter,
};

export const initialState: IReportState<ILiveHireReport> =
  getInitialReportState<ILiveHireReport>({
    filters: {
      customOptions: customFilterOptions,
      additionalOptions: additionalFilterOptions,
    },
  });

const adapter: IReportEntityAdapter<
  ILiveHireReport,
  LiveHireReportSearchFilter
> = createReportEntityAdapter<ILiveHireReport, LiveHireReportSearchFilter>();

export function reducer(
  state = initialState,
  action: ReportAction<ILiveHireReport>
): IReportState<ILiveHireReport> {
  return createReportReducers<ILiveHireReport, LiveHireReportSearchFilter>(
    LIVE_HIRE_ACTION_TYPES,
    adapter
  )(state, action);
}

export const getReportState = createSelector(
  getReportManagementState,
  (state: IReportManagementState) => state.liveHire
);

export const reportSelectors: IReportEntitySelectors<
  ILiveHireReport,
  LiveHireReportSearchFilter
> = adapter.getSelectors(getReportState);
