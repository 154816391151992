import { Component, Output, EventEmitter } from '@angular/core';
import { ROLE_ADMIN } from '@waracle/gap-sdk';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-dash-sidebar',
  templateUrl: './dash-sidebar.component.html',
  styleUrls: ['./dash-sidebar.component.scss'],
})
export class DashSidebarComponent {

  @Output() menuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  closeMenu(_: boolean) {
    this.menuClosed.emit(false);
  }
  isAdmin: boolean = false;

  constructor(private _authService: AuthenticationService) {
    this.isAdmin = this._authService.isAuthenticatedRoleValid(ROLE_ADMIN);
  }

}
