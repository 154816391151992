import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'gap-ui-insight-card',
  template: `
    <div class="col-12 seg-1">
      <h4 class="mt-0">{{description}}</h4>
      <div class="the-desc">
        {{details ? details: ''}}
        <input type="checkbox" [checked]="selected" [attr.id]="uuid" (change)="onToggled()"/>
        <label [attr.for]="uuid" class="selected-marker"></label>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      box-shadow: 0px 0px 15px 1px lightgrey;
      border-radius: 5px;
      height: 100%;
      background-color: white;
    }
    h4 {
      color: #212C65;
      font-weight: 600;
    }
    .the-desc {
      color: grey;
      font-size: .8rem;
      font-weight: 300;
      line-height: 2rem;
      display: flex;
      justify-content: space-between;
    }
    .seg-1 {
      padding: 25px 25px 50px 25px;
    }
    label {
      position: relative;
      background-color: #fff;
      border: 1px solid #212C65;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-top: 1rem;
    }
    label:after {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #212C65;
      content: '';
      border-radius: 50%;
      top: 2px;
      left: 2px;
      opacity: 0;
    }
    input {
      visibility: hidden;
    }
    input[type="checkbox"]:checked + label {
      border-color: #212C65;
    }
    input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightCardComponent {

  @Input() selected:boolean = false;
  @Input() details: string;
  @Input() description: string;
  @Output() toggled = new EventEmitter<boolean>();
  uuid: string = uuid();
  onToggled() {
    this.selected = !this.selected;
    this.toggled.emit(this.selected);
  }
}
