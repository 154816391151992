import { createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import * as deepmerge from 'deepmerge';

import { IReportState } from './state';
import {
  IReportLoadData,
  IReportAddFilter,
  IReportRemoveFilter,
  IReportUiViewSizeChange,
  IReportUiPageTransition,
  IReportSortChange,
  IReportApiPageTransition,
  IReportSetActiveAccount,
  IReportApplyFilters,
} from './actions';
import { IReportDTO, IReportSearchFilter } from './model';
import { getPageFromQueryString, stripFromQueryString } from './helpers';
import { IReportEntitySelectors, createReportEntitySelectors, createReportEntityOverrideSelectors } from './selectors';

export function createReportEntityAdapter
  <T extends IReportDTO, V extends IReportSearchFilter>(): IReportEntityAdapter<T, V> {
  // reusable private methods
  const _updateUiPaginationTotal = (state: IReportState<T>, total: number) => {
    return deepmerge(state, {
      pagination: {
        ui: {
          total,
        },
      },
    });
  };
  const _finishedLoading = (state: IReportState<T>) => {
    return {
      ...state,
      loading: false,
    };
  };
  const _mapQueryToReport = (report: T, qs: string, state: IReportState<T>) => {
    report.uiPages = state.entities[report.id] ? state.entities[report.id].uiPages : {};
    const pageless = stripFromQueryString(qs, 'page');

    report.uiPages[pageless] = getPageFromQueryString(qs);

    return report;
  };
  // original adapter methods
  const entityAdapter = createEntityAdapter<T>();
  // new public methods
  const customMethods = {
    isLoading(state: IReportState<T>) {
      return {
        ...state,
        loading: true,
      };
    },
    finishedLoading(state: IReportState<T>) {
      return _finishedLoading(state);
    },
    loadData(state: IReportState<T>, action: IReportLoadData<T>) {
      // update/insert records using ngrx/entity adapter
      const upserted = entityAdapter.upsertMany(
        action.payload.reports.map((report) => _mapQueryToReport(report, action.payload.query, state)),
        state,
      );
      const paginated = _updateUiPaginationTotal(upserted, action.payload.total);
      const logEntry = { query: action.payload.query, total: action.payload.total };
      const history = deepmerge(paginated, {
        history: {
          logs: [logEntry],
          active: logEntry,
        },
      });
      return {
        ..._finishedLoading(history),
      };
    },
    loadFailed(state: IReportState<T>) {
      return deepmerge(_finishedLoading(state), {
        pagination: {
          api: {
            page: 1,
          },
          ui: {
            pageNumber: 1,
            total: 0,
          },
        },
      });
    },
    clearData(state: IReportState<T>) {
      return {
        ...entityAdapter.removeAll(state),
        activeAccount: null,
      };
    },
    addReportFilter(state: IReportState<T>, action: IReportAddFilter) {
      return deepmerge(state, {
        pagination: {
          api: {
            page: 1,
          },
        },
      });
    },
    applyReportFilters(state: IReportState<T>, action: IReportApplyFilters) {
      return {
        ...state,
        filters: {
          ...state.filters,
          active: action.payload,
        },
      };
    },
    removeReportFilter(state: IReportState<T>, action: IReportRemoveFilter) {
      return deepmerge({
        ...state,
      },               {
        pagination: {
          api: {
            page: 1,
          },
        },
      });
    },
    clearReportFilters(state: IReportState<T>) {
      const resetPagination = deepmerge(state, {
        pagination: {
          api: {
            page: 1,
          },
        },
      });
      return {
        ...resetPagination,
        filters: {
          ...resetPagination.filters,
          active: [],
        },
      };
    },
    changePageSize(state: IReportState<T>, action: IReportUiViewSizeChange) {
      return deepmerge(state, {
        pagination: {
          ui: {
            pageNumber: 1,
            viewSize: action.payload,
          },
        },
      });
    },
    changePageView(state: IReportState<T>, action: IReportUiPageTransition) {
      return deepmerge(state, {
        pagination: {
          ui: {
            pageNumber: action.payload,
          },
        },
      });
    },
    changeApiPage(state: IReportState<T>, action: IReportApiPageTransition) {
      return deepmerge(state, {
        pagination: {
          api: {
            page: action.payload,
          },
        },
      });
    },
    sortReport(state: IReportState<T>, action: IReportSortChange<T>) {
      return deepmerge(state, {
        pagination: {
          api: {
            page: 1,
          },
          ui: {
            pageNumber: 1,
          },
        },
        sort: action.payload,
      });
    },
    startProcessing(state: IReportState<T>) {
      return {
        ...state,
        processing: true,
      };
    },
    finishProcessing(state: IReportState<T>) {
      return {
        ...state,
        processing: false,
      };
    },
    setActiveAccount(state: IReportState<T>, action: IReportSetActiveAccount) {
      return {
        ...state,
        activeAccount: action.payload.active,
      };
    },
    clearQueryHistory(state: IReportState<T>) {
      return {
        ...state,
        history: {
          logs: [],
        },
      };
    },
  };

  function getSelectors(): IReportEntitySelectors<T, V>;
  function getSelectors<S extends IReportDTO>(
    selectState: (state: S) => IReportState<S>,
  ): IReportEntitySelectors<T, V>;
  function getSelectors(
    selectState?: (state: any) => IReportState<T>,
  ): IReportEntitySelectors<T, V> {
    let entitySelectors = entityAdapter.getSelectors(selectState);
    entitySelectors = deepmerge(
      entitySelectors,
      createReportEntityOverrideSelectors(selectState),
    );
    const customSelectors = createReportEntitySelectors<T, V>(selectState);
    return deepmerge(entitySelectors, customSelectors);

  }

  const entitySelectors = {
    getSelectors,
  };

  const reportEntityAdapter = deepmerge(customMethods, entitySelectors);

  return deepmerge(entityAdapter, reportEntityAdapter);
}

export interface IReportEntityAdapter<T extends IReportDTO, V extends IReportSearchFilter> extends EntityAdapter<T> {
  isLoading(state: IReportState<T>): IReportState<T>;
  finishedLoading(state: IReportState<T>): IReportState<T>;
  loadFailed(state: IReportState<T>): IReportState<T>;
  loadData(state: IReportState<T>, action: IReportLoadData<T>): IReportState<T>;
  clearData(state:IReportState<T>): IReportState<T>;
  addReportFilter(state: IReportState<T>, action: IReportAddFilter): IReportState<T>;
  applyReportFilters(state: IReportState<T>, action: IReportApplyFilters): IReportState<T>;
  removeReportFilter(state: IReportState<T>, action: IReportRemoveFilter): IReportState<T>;
  clearReportFilters(state: IReportState<T>): IReportState<T>;
  changePageSize(state: IReportState<T>, action: IReportUiViewSizeChange): IReportState<T>;
  changePageView(state: IReportState<T>, action: IReportUiPageTransition): IReportState<T>;
  changeApiPage(state: IReportState<T>, action: IReportApiPageTransition): IReportState<T>;
  sortReport(state: IReportState<T>, action: IReportSortChange<T>): IReportState<T>;
  startProcessing(state: IReportState<T>): IReportState<T>;
  finishProcessing(state: IReportState<T>): IReportState<T>;
  setActiveAccount(state: IReportState<T>, action: IReportSetActiveAccount): IReportState<T>;
  clearQueryHistory(state: IReportState<T>): IReportState<T>;
  getSelectors(selectState?: (state: any) => IReportState<T>): IReportEntitySelectors<T, V>;
}
