import * as path from 'path';

export class Config {
  /**
   * The password for the API user
   */
  public static API_PASS = process.env.API_PASS;

  /**
   * The username for the API user
   */
  public static API_USER = process.env.API_USER || 'api@gap.extranet';

  /**
   * The API version
   */
  public static API_PREFIX = process.env.API_PREFIX;

  /**
   * @deprecated
   */
  public static API_PREFIX_USER = process.env.API_PREFIX_USER || '';

  /**
   * The API URL
   */
  public static API_URL =
    process.env.API_URL || 'https://gap-extranet.waracle.net';

  /**
   * The platform build number
   */
  public static APP_BUILD_NUMBER: number =
    parseInt(process.env.APP_BUILD_NUMBER || process.env.DEPlOYMENT_ID, 10) || 1;

  /**
   * The app name
   */
  public static APP_NAME: string = process.env.APP_NAME || '@waracle/gap-api';

  /**
   * The platform version
   */
  public static APP_VERSION: string = process.env.APP_VERSION || '0.1.0';

  /**
   * The number of rounds to encrypt passwords with
   */
  public static BCRYPT_ROUNDS: number =
    parseInt(process.env.BCRYPT_ROUNDS, 10) || 10;

  /**
   * The CDN link for static assets
   */
  public static CDN_BASE_URI: string =
    process.env.CDN_BASE_URI || 'https://extranet-cdn.gap-group.co.uk/';

  /**
   * The database connection string
   */
  public static DB_HOST: string = process.env.DB_HOST;

  /**
   * Enable debugging on the database connection
   */
  public static DB_DEBUG: string = process.env.DB_DEBUG;

  /**
   * Main root user's email address
   */
  public static DEFAULT_ADMIN_EMAIL: string =
    process.env.DEFAULT_ADMIN_EMAIL || 'gap@waracle.com';

  /**
   * Url for portal for welcome, password resets, etc
   */
  public static DEFAULT_WEB_URL: string =
    process.env.DEFAULT_WEB_URL || 'https://gap-extranet.test-app.link';

  /**
   * The GAP Document s3 bucket
   */
  public static DOCUMENT_BUCKET: string =
    process.env.DOCUMENT_BUCKET ||
    'gap-extranet-dev0-reportservice-t4-documentbucket-1xfox6qqzxf99';

  /**
   * The web address for the document server
   */
  public static DOCUMENT_SERVER_HOST: string =
    process.env.DOCUMENT_SERVER_HOST || 'http://100.100.0.156:8081';

  /**
   * Path to append to the document server host
   */
  public static DOCUMENT_SERVER_PATH: string =
    process.env.DOCUMENT_SERVER_PATH || '/oc/searchwindowservice';

  /**
   * The default email sender
   */
  public static EMAIL_SENDER_DEFAULT =
    process.env.DEFAULT_EMAIL_SENDER || 'team@extranet.gap-group.co.uk';

  /**
   * The password reset host link
   */
  public static EMAIL_SENDER_PASSWORD_RESET: string =
    process.env.EMAIL_SENDER_PASSWORD_RESET || Config.EMAIL_SENDER_DEFAULT;

  /**
   * The support email address
   */
  public static EMAIL_SUPPORT_HELPDESK: string =
    process.env.EMAIL_SUPPORT_HELPDESK || 'ithelpdesk@gap-group.co.uk';

  /**
   * The support email address for off hiring equipment
   */
  public static EMAIL_SUPPORT_GAP_ONE: string =
    process.env.EMAIL_SUPPORT_GAP_ONE || 'GapOne.T1@gap-group.co.uk';
  /**
   * The support email address for off hiring equipment
   */
  public static EMAIL_SUPPORT_CREDIT_CONTROL: string =
    process.env.EMAIL_SUPPORT_CREDIT_CONTROL ||
    'gaponecreditcontrol@gap-group.co.uk';

  /**
   * The support email address
   */
  public static EMAIL_SUPPORT_DEFAULT: string =
    process.env.EMAIL_SUPPORT_DEFAULT || Config.EMAIL_SUPPORT_HELPDESK;

  /**
   * Enable data importing
   */
  public static ENABLE_DATA_IMPORTER = Boolean(
    process.env.ENABLE_DATA_IMPORTER
  );

  /**
   * The location of the translation files
   */
  public static I18N_LOCATION: string =
    process.env.I18N_LOCATION ||
    path.join(__dirname, '../../../../', 'i18n/src/langs');

  /**
   * List of characters not permitted in the database
   */
  public static INJECTION_BLACKLIST: string[] = ['$', '{', '||'];

  /**
   * The default date format
   * @type {string}
   */
  public static ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXXXX";

  /**
   * The Url for the web key store
   */
  public static JWKS_URI: string =
    process.env.JWKS_URI ||
    'https://gap-jwks.waracle.net/.well-known/jwks.json';

  /**
   * The expiry time for JWT
   */
  public static JWT_EXPIRES_IN: number =
    parseInt(process.env.JWT_EXPIRES_IN, 10) || 60 * 60 * 24 * 2; // 2 days (seconds)

  /**
   * The ID for the active key that generated the JWT
   */
  public static JWT_KEY_ID: string = process.env.JWT_KEY_ID || '1';

  /**
   * The JTW issuer
   */
  public static JWT_ISSUER: string =
    process.env.JWT_ISSUER || 'extranet.gap-group.co.uk';

  /**
   * Sets the mailer service to send emails with
   *
   * @default {@link NoopMailerService}
   */
  public static MAILER_IMPL: string = process.env.MAILER_IMPL || 'noop';

  /**
   * The password reset host link
   */
  public static PASSWORD_RESET_HOST: string =
    process.env.PASSWORD_RESET_HOST || Config.DEFAULT_WEB_URL;

  /**
   * The password path
   * @type {string}
   */
  public static PASSWORD_RESET_PATH = 'resetpw';

  /**
   * The password for the API user
   * min 1 number, min 1 uppercase, min length=8
   */
  public static PASSWORD_RULES: RegExp[] = [/.{8,}/, /\d+/, /[A-Z]+/, /[a-z]+/];

  /**
   * The SQS queue url for Accruals
   */
  public static SQS_ACCOUNT_URL: string = process.env.SQS_ACCOUNT_URL;
  /**
   * The SQS queue url for Accruals
   */
  public static SQS_ACCRUAL_URL: string = process.env.SQS_ACCRUAL_URL;

  /**
   * The SQS queue url for Hire Movements
   */
  public static SQS_HIRE_MOVEMENT_URL: string =
    process.env.SQS_HIRE_MOVEMENT_URL;

  /**
   * The SQS queue url for Insights
   */
  public static SQS_INSIGHT_URL: string = process.env.SQS_INSIGHT_URL;

  /**
   * The SQS queue url for Invoices
   */
  public static SQS_INVOICE_URL: string = process.env.SQS_INVOICE_URL;

  /**
   * The SQS queue url for Live Hires
   */
  public static SQS_LIVE_HIRE_URL: string = process.env.SQS_LIVE_HIRE_URL;

  /**
   * The SQS queue url for Depots
   */
  public static SQS_DEPOT_URL: string = process.env.SQS_DEPOT_URL;

  /**
   * GAP's support telephone number
   */
  public static SUPPORT_PHONE =
    process.env.SUPPORT_PHONE || '0141 225 4600 (Option 1)';

  /**
   * The Welcome email host
   */
  public static WELCOME_EMAIL_HOST: string =
    process.env.WELCOME_EMAIL_HOST || Config.DEFAULT_WEB_URL;

  /**
   * The Welcome email path
   * @type {string}
   */
  public static WELCOME_EMAIL_PATH = 'setfirstpw';
}
