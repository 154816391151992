import { Component } from '@angular/core';
import { DefaultInputComponent } from '../default/default.component';
import { FormControl } from '@angular/forms';
import { setupInputProviders } from '../_helpers';

@Component({
  selector: 'report-input-number',
  template: `
    <input #rawInputRef
      class="form-control mt-0 placeholder-with-icon"
      placeholder="{{ placeholder | i18n:'REPORT.FILTERS' | translate }}"
      [formControl]="inputControl"
      [value]="inputControl.value | currency:'GBP'"
      (input)="onChange($event)"
      type="text"/>
  `,
  styles: [`
    input.form-control {
      width: 100%;
    }
  `],
  providers: setupInputProviders(CurrencyInputComponent),
})
export class CurrencyInputComponent extends DefaultInputComponent {
  constructor() {
    super();
    this.inputControl = new FormControl(null, { updateOn: 'blur' });
  }
}
