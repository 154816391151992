import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthFrameComponent } from './auth-frame/auth-frame.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthForgottenComponent } from './auth-forgotten/auth-forgotten.component';
import { AuthCreateComponent } from './auth-create/auth-create.component';

const routes: Routes = [

  {
    path: 'auth',
    component: AuthFrameComponent,
    children: [
      {
        path:'login',
        component: AuthLoginComponent,
      },
      {
        path:'forgotten',
        component: AuthForgottenComponent,
      },
      {
        path:'create',
        component: AuthCreateComponent,
      },
      { path: '**', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserAuthRoutingModule { }
