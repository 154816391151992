import {
  ITextSearchFilter,
  TextSearchFilter,
} from './textSearch.filter';

export interface IDepotSearchFilter extends ITextSearchFilter {
  code: string;
  name: string;
}

export class DepotSearchFilter extends TextSearchFilter implements IDepotSearchFilter {

  constructor(limit: number = 100, page: number = 1, maxLimit: number = 100) {
    // deliberate non-checking of type so '0' can be coerced into 0
    // set max limit to 1000
    super(limit, page, maxLimit);
  }

  private _code: string;
  private _name: string;

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  public toJS(): IDepotSearchFilter {
    return Object.assign(
      super.toJS(),
      {
        name: this.name,
        code: this.code,
      },
    );
  }
}
