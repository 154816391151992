import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './_directives';
import { createRoutes } from './app.routes';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AdminAuthGuard, UserAuthGuard, AdminRedirectGuard } from './_guards';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminAccountComponent } from './user-admin/admin-account/admin-account.component';
import { DashHeaderComponent } from './dashboard/dash-header/dash-header.component';
import { DashSidebarComponent } from './dashboard/dash-sidebar/dash-sidebar.component';
import { DashFooterComponent } from './dashboard/dash-footer/dash-footer.component';
import { DashIndexComponent } from './dashboard/dash-index/dash-index.component';
import { RequestNewUserComponent } from './_modals/request-new-user/request-new-user.component';
import { PasswordResetComponent } from './_modals/password-reset/password-reset.component';
import { UiKitModule } from '@waracle/gap-extranet/ui-kit';
import { DashboardMiniNavComponent } from './_components/dashboard-mini-nav/dashboard-mini-nav.component';
import { ReportsNavComponent } from './_components/reports-nav/reports-nav.component';
import { UserAdminNavComponent } from './_components/user-admin-nav/user-admin-nav.component';
import { ExternalLinksNavComponent } from './_components/external-links-nav/external-links-nav.component';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './_state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './_state/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { AuthInterceptor } from './_interceptors/auth.interceptor';
import { ConfirmModalComponent } from './_modals/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './_modals/error/error-modal.component';
import { UserAuthModule } from './user-auth/user-auth.module';
import { SharedModule } from './shared/shared.module';
import {
  NgTagManagerModule,
  NgTagManagerService,
} from '@waracle/ng-tag-manager';
import { PinnedInsightsComponent } from './_components/pinned-insights/pinned-insights.component';
import { ErrorModalService } from './_services/error-modal.service';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const devOnlyImports = environment.production
  ? []
  : [
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
    ];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost',
  },
  position: 'bottom',
  theme: 'block',
  palette: {
    popup: {
      background: '#212c65',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f07a76',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-in',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Allow Cookies',
    deny: 'Refuse Cookies',
    link: 'Privacy Policy',
    href: 'https://www.gap-group.co.uk/privacy-policy',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    DashIndexComponent,
    AdminAccountComponent,
    DashHeaderComponent,
    DashSidebarComponent,
    DashFooterComponent,
    RequestNewUserComponent,
    PasswordResetComponent,
    DashboardMiniNavComponent,
    ReportsNavComponent,
    UserAdminNavComponent,
    ExternalLinksNavComponent,
    SidebarComponent,
    DashboardComponent,
    ConfirmModalComponent,
    PinnedInsightsComponent,
    ErrorModalComponent,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    createRoutes(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    UiKitModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    UserAuthModule,
    NgTagManagerModule.forRoot(),
    ...devOnlyImports,
  ],
  providers: [
    AdminAuthGuard,
    ErrorModalService,
    UserAuthGuard,
    AdminRedirectGuard,
    Meta,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    RequestNewUserComponent,
    PasswordResetComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
  ],
})
export class AppModule {
  constructor(private _analytics: NgTagManagerService) {
    this._analytics.init(environment.gtm);
  }
}
