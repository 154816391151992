import { Component, OnInit } from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Router,
  ActivatedRoute,
} from '@angular/router';

import { PasswordService } from '../../_services/password.service';
import { Config } from '@waracle/gap-sdk';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-auth-create',
  templateUrl: './auth-create.component.html',
  styleUrls: ['./auth-create.component.scss'],
})
export class AuthCreateComponent implements OnInit {

  notMatch = false;
  hidePassword = true;
  hideConfPassword = true;
  pwFocus = false;
  pwConfirm = false;

  loading = false;
  tokenExpired = false;
  updatedSuccess = false;

  resetForm: FormGroup;

  token: string;

  constructor(
    private _passwordService: PasswordService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.createForm();

    this._activatedRoute.queryParams.pipe(
      filter((params) => params['code']),
      take(1),
    ).subscribe(
      (params) => {
        this.token = params['code'];
      },
    );
  }

  reset() {
    this.notMatch = false;
    this.loading = true;

    if (this.password.value !== this.passwordConfirmation.value) {
      this.notMatch = true;
      this.loading = false;
      return false;
    }

    this._passwordService.reset(this.password.value, this.token)
      .subscribe(
        (_) => {
          this.updatedSuccess = true;
          this.loading = false;
          setTimeout(() => {
            this._router.navigate(['/login']);
          },         6000);
        },
        (_) => {
          this.tokenExpired = true;
          this.loading = false;
        });
  }

  createForm() {
    const passCtrl = new FormControl('', [
      Validators.required,
      ...Config.PASSWORD_RULES.map((rule: RegExp) => Validators.pattern(rule)),
    ]);
    this.resetForm = new FormGroup({
      password: passCtrl,
      passwordConfirmation: _.cloneDeep(passCtrl),
    });
  }

  get passwordConfirmation() {
    return this.resetForm.get('passwordConfirmation');
  }
  get password() {
    return this.resetForm.get('password');
  }

}
