import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gap-ui-score-card',
  template: `
    <div class="seg-1" [routerLink]="link">
      <h4>{{title}}</h4>
    </div>
    <div class="seg-2">
      <p>{{score}}</p>
    </div>`,
  styles: [`
    :host {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      box-shadow: 0px 0px 15px 1px lightgrey;
      border-radius: 5px;
      background-color: white;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
    }

    :host .seg-1,
    :host .seg-2{
      padding:0px !important;
    }

    :host .seg-1 h4{
      padding:35px;
    }

    .seg-1, .seg-2 {
      display: flex;
      align-items: center;
    }
    .seg-1 {
      flex: 1 1 80%;
      overflow:hidden;
    }
    .seg-1 h4 {
      margin-bottom: 0;
      margin-top:0;
      color: #303C76;
      padding: 15px 30px 15px 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .seg-2 {
      flex: 1 1 20%;
      border-radius: 0 5px 5px 0;
      background: #303C76;
    }
    .seg-2 p {
      margin-bottom: 0;
      margin-top:0;
      width: 100%;
      text-align: center;
      color: white;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreCardComponent {
  @Input() title: string = 'Report';
  @Input() score: number | string = 'n/a';
  @Input() link: string | string[] | null = null;
}

export interface IScoreCardOptions {
  title: string;
  score: number | string;
  link: string | string[] | null;
}
