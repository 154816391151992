import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IState } from './_state/reducers';
import { AppReady } from './_state/app.actions';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private _popupOpenSubscription!: Subscription;
  private _popupCloseSubscription!: Subscription;
  private _initializeSubscription!: Subscription;
  private _statusChangeSubscription!: Subscription;
  private _revokeChoiceSubscription!: Subscription;
  private _noCookieLawSubscription!: Subscription;

  public constructor(
    private _ccService: NgcCookieConsentService,
    private _title: Title,
    private _translateService: TranslateService,
    private _meta: Meta,
    private _store: Store<IState>
  ) {
    this._store.dispatch(new AppReady());
    this._translateService.setDefaultLang('en');
    this._translateService.get('DISPLAY_NAME').subscribe((text: string) => {
      return this._title.setTitle(
        `${text} | ${environment.environment} | ${environment.version} build ${environment.buildNumber}`
      );
    });
    this._meta.updateTag({
      name: 'gap:build:version',
      content: environment.version,
    });
    this._meta.updateTag({
      name: 'gap:build:number',
      content: environment.buildNumber,
    });
  }

  title: string = environment.name;
  // buildNumber: number = environment.buildNumber;
  // version: number = environment.version;

  ngOnInit() {
    this._popupOpenSubscription = this._ccService.popupOpen$.subscribe(() => {
      // you can use this._ccService.getConfig() to do stuff...
    });

    this._popupCloseSubscription = this._ccService.popupClose$.subscribe(() => {
      // you can use this._ccService.getConfig() to do stuff...
    });

    this._initializeSubscription = this._ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {}
    );

    this._statusChangeSubscription = this._ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this._ccService.getConfig() to do stuff...
      }
    );

    this._revokeChoiceSubscription = this._ccService.revokeChoice$.subscribe(
      () => {
        // you can use this._ccService.getConfig() to do stuff...
      }
    );

    this._noCookieLawSubscription = this._ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this._ccService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this._popupOpenSubscription.unsubscribe();
    this._popupCloseSubscription.unsubscribe();
    this._initializeSubscription.unsubscribe();
    this._statusChangeSubscription.unsubscribe();
    this._revokeChoiceSubscription.unsubscribe();
    this._noCookieLawSubscription.unsubscribe();
  }
}
