import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gap-ui-report-card',
  template: `
    <mat-card>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-content><ng-content></ng-content></mat-card-content>
    </mat-card>`,
  styles: [`
    mat-card {
      border-left: 5px solid #212C65;
      padding: 19px 38px 21px 15px;
    }
    mat-card-title {
      color: #212C65;
      font-weight: 700;
    }
    mat-card-content {
      font-size: 16px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportCardComponent {
  @Input() title: string = 'Report';
}
