import { Injectable } from '@angular/core';
import {
  Router,
  NavigationStart,
} from '@angular/router';
import {
  Observable,
  Subject,
} from 'rxjs';

import {
  Alert,
  AlertType,
} from '../_models';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private _subject = new Subject<Alert>();
  private _keepAfterRouteChange = false;

  constructor(private _router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this._keepAfterRouteChange) {
          // only keep for a single route change
          this._keepAfterRouteChange = false;
        }
        else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this._subject.asObservable();
  }

  success(message: string, params: object = {}, keepAfterRouteChange = false) {
    this.alert(AlertType.SUCCESS, message, params, keepAfterRouteChange);
  }

  error(message: string, params: object = {}, keepAfterRouteChange = false) {
    this.alert(AlertType.ERROR, message, params, keepAfterRouteChange);
  }

  info(message: string, params: object = {}, keepAfterRouteChange = false) {
    this.alert(AlertType.INFO, message, params, keepAfterRouteChange);
  }

  warn(message: string, params: object = {}, keepAfterRouteChange = false) {
    this.alert(AlertType.WARNING, message, params, keepAfterRouteChange);
  }

  alert(type: AlertType, message: string, params: object = {}, keepAfterRouteChange = false) {
    this._keepAfterRouteChange = keepAfterRouteChange;
    this._subject.next(<Alert>{ type, message, params });
  }

  clear() {
    // clear alerts
    this._subject.next();
  }

  clearAfterTime(ms: number = 2000, cb?: () => void) {
    setTimeout(
      () => {
        this.clear();
        if (cb) {
          cb();
        }
      },
      ms);
  }
}
