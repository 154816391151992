import { environment } from '../../../../environments/environment';

// handles filters applied to api requests
export interface IReportApiPaginationState {
  page: number;
  limit: number;
}

// handles view to filter results in table
export interface IReportUiPaginationState {
  pageNumber: number;
  viewSize: number;
  count: number;
  total: number;
}

export interface IReportPaginationState {
  api: IReportApiPaginationState;
  ui: IReportUiPaginationState;
}

export const initialReportApiPaginationState: IReportApiPaginationState = {
  page: 1,
  limit: environment.reportFetchLimit,
};

export const initialReportUiPaginationState: IReportUiPaginationState = {
  pageNumber: 1,
  viewSize: 10,
  count: 0,
  total: 0,
};

export const initialReportPaginationState: IReportPaginationState = {
  api: initialReportApiPaginationState,
  ui: initialReportUiPaginationState,
};
