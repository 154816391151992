import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  forwardRef,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
  Validator,
  ValidationErrors,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

const SHOW_DROPDOWN_MIN_CHARS = 3;
const DROPDOWN_DEBOUNCE_TIME = 300;

@Component({
  selector: 'gap-ui-look-ahead',
  templateUrl: './look-ahead.component.html',
  styleUrls: ['./look-ahead.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookAheadComponent),
      multi: true,
    },
  ],
})
export class LookAheadComponent implements ControlValueAccessor,
  Validator,
  OnInit,
  OnDestroy {

  // Configure timeouts and limits
  @Input() set debounceTime(value: number) {
    this._debounceTime = value;
  }
  private _debounceTime: number = DROPDOWN_DEBOUNCE_TIME;
  @Input() set minCharacters(value: number) {
    this._minCharacters = value;
  }
  private _minCharacters: number = SHOW_DROPDOWN_MIN_CHARS;

  @Input() emptyLabel: string = 'No matches found';
  @Input() placeholder: string;
  @Input() set pattern(pattern: string | RegExp) {
    const validators = [
      Validators.required,
    ];
    if (Boolean(pattern) && this.queryControl) {
      validators.push(Validators.pattern(pattern));
    }
    this.queryControl.setValidators(validators);
  }

  @Input() set optionLimit(limit: number) {
    if (limit) {
      this._optionLimit = limit;
    }
  }
  get optionLimit() {
    return this._optionLimit;
  }
  private _optionLimit: number = 1000;
  @Input() showAll: boolean = false;
  @Input() set options(opts: string[]) {
    if (Boolean(opts) && opts.length > 0) {
      this._options = opts;
    }
  }
  get options() {
    if (this.showAll) {
      return this._options;
    }
    return this._options.filter((opt: string) => {
      return Boolean(this.query) ? opt.toLowerCase().includes(this.query.toLowerCase()) : true;
    });
  }
  private _options: string[] = [];

  @Output() selected: EventEmitter<string> = new EventEmitter();
  @Output() lookupQueryChanged: EventEmitter<string> = new EventEmitter();
  selectOption(item: string) {
    this.active = item;
    this.selected.emit(item);
  }

  @Input() set active(query: string) {
    this._skipOpen = Boolean(query);
    this.queryControl.setValue(query);
    // trigger change when set
    this.query = query;
  }
  set query(query: string) {
    this._onChange(query);
    this._query = query;
  }
  get query() {
    return this._query;
  }
  private _query: string = '';
  queryControl: FormControl = new FormControl();
  private _querySubs: Subscription[] = [];
  @ViewChild('queryInputRef', { static: true }) queryElementRef: ElementRef;

  private _onChange: (_: any) => void = (_: any) => {};

  @ViewChild('queryOptions', { static: true }) dropdown: NgbDropdown;
  private _skipOpen: boolean = false;

  constructor() { }

  ngOnInit() {
    const inputValueChange$ = this.queryControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this._debounceTime),
    );
    // emit current value to parent everytime user stops typing
    this._querySubs.push(inputValueChange$.subscribe((query: string) => {
      this.query = query;
      this.lookupQueryChanged.emit(query);
    }));
    // Only open dropdown if query is set or min characters === 0
    this._querySubs.push(inputValueChange$.pipe(
      filter((query: string) => Boolean(query) || this._minCharacters === 0),
    ).subscribe((query: string) => {
      this.maybeOpen();
      this._skipOpen = false;
    }));
  }

  ngOnDestroy() {
    this._querySubs.map((sub) => sub.unsubscribe());
  }

  maybeOpen() {
    const isQueryValid = (this.query && this.query.length >= this._minCharacters) || (this._minCharacters === 0);
    const shouldSkip = this.dropdown.isOpen() || this._skipOpen;
    const isUnderOptionsLimit = this.options.length <= this.optionLimit;
    if (isQueryValid && !shouldSkip && isUnderOptionsLimit) {
      this.dropdown.open();
    }
  }

  focus() {
    this.queryElementRef.nativeElement.focus();
  }

  // only handle string values
  writeValue(value: string): void {
    this.active = value;
  }
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this.queryControl.registerOnChange(this._onChange);
  }

  touched(_: any) {
    this._onTouched(_);
  }
  private _onTouched: (_: any) => void = (_: any) => {};
  // required but unused
  registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn;
  }

  validate(c: FormControl): ValidationErrors {
    return this.queryControl.errors;
  }
}
