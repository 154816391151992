import { createFilterOption } from '../_filters/_factories';
import { Validators } from '@angular/forms';
import { SupportedFilterTypes } from '../_state/generic/filter';
import {
  sharedReportFilters,
  basicStringRegex,
  sharedCustomFilterOptions,
} from './shared';

export const hireReportFilterOptions = [
  ...sharedReportFilters,
  'productCode',
  'productName',
  'address',
  'fleetNumber',
  'depotName',
  'onHireDateFrom',
];

export const fleetNumberFilter = createFilterOption('fleetNumber', SupportedFilterTypes.CHOICE);
fleetNumberFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const addressFilter = createFilterOption('address', SupportedFilterTypes.STRING);
addressFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const depotNameFilter = createFilterOption('depotName', SupportedFilterTypes.DROPDOWN);
depotNameFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const productCodeFilter = createFilterOption('productCode', SupportedFilterTypes.STRING);
productCodeFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const productNameFilter = createFilterOption('productName', SupportedFilterTypes.STRING);
productNameFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const onHireDateFromFilter = createFilterOption('onHireDateFrom', SupportedFilterTypes.DATE);
onHireDateFromFilter.context.required = true;
export const offHireDateToFilter = createFilterOption('offHireDateTo', SupportedFilterTypes.DATE);

export const gapTrackFilter = createFilterOption('gaptrack', SupportedFilterTypes.DROPDOWN);
gapTrackFilter.context.addValidator(Validators.pattern(basicStringRegex));

export const hireCustomFilterOptions = {
  ...sharedCustomFilterOptions,
  productCode: productCodeFilter,
  productName: productNameFilter,
  address: addressFilter,
  fleetNumber: fleetNumberFilter,
  depotName: depotNameFilter,
  onHireDateFrom: onHireDateFromFilter,
};
