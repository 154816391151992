import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorParams, ValidatorMap } from './interface';

export function multiInputValidator(validators: ValidatorMap = {}): ValidatorFn {
  return (control: AbstractControl): ValidatorParams => {
    const errors = {};
    let valid = true;
    Object.keys(validators).map((key) => {
      errors[key] = validators[key](control);
      if (errors[key]) valid = false;
    });
    return valid ? null : errors;
  };
}
