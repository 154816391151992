import * as jwt from 'jsonwebtoken';
import {
  CertSigningKey,
  JwksClient,
  Options,
  RsaSigningKey,
  SigningKey,
} from 'jwks-rsa';
import { IRequest } from './models/request';
import { AuthenticationError } from './authentication.error';
import { IApi } from '../api.interface';
import { API } from '../api';
import { Config } from '../../config';
import { IAuthenticationResponse } from '../../models/';
import { Logger } from '@waracle/gap-extranet/logger';

export class AuthenticationService {
  private static __INSTANCE: AuthenticationService;

  private _api: IApi;
  private _authenticationToken: string;
  private _jwksClient: JwksClient;
  private _logger: Logger;

  private constructor(api: IApi) {
    this._api = api || API.instance();
    this._logger = new Logger('authentication');
  }

  // tslint:disable-next-line:naming-convention
  protected get API(): IApi {
    return this._api;
  }

  get logger(): Logger {
    return this._logger;
  }

  get jwksClient(): JwksClient {
    if (!this._jwksClient) {
      const jwksOpts = {
        strictSsl: false, // Default value
        cache: true,
        rateLimit: true,
        jwksRequestsPerMinute: 5,
        jwksUri: Config.JWKS_URI,
      } as Options;

      this._jwksClient = new JwksClient(jwksOpts);
    }
    return this._jwksClient;
  }

  async authenticationToken(): Promise<string> {
    if (this._authenticationToken) {
      try {
        await this.isAuthenticated(this._authenticationToken);
        this.logger.info('Authentication token is valid');
        return Promise.resolve(this._authenticationToken);
      } catch (e) {
        this.logger.error(
          e,
          'Current authentication is invalid, need another one'
        );
        this._authenticationToken = null;
      }
    }

    let isAuthenticated = false;
    try {
      isAuthenticated = await this.login();
    } catch (e) {
      return Promise.reject(
        new AuthenticationError('Exception during API user login', e)
      );
    }

    if (!isAuthenticated) {
      return Promise.reject(
        new AuthenticationError(
          'Failed to login and set new authentication token'
        )
      );
    }

    this.logger.debug('Created new authentication token successfully');
    return Promise.resolve(this._authenticationToken);
  }

  static instance(api?: IApi): AuthenticationService {
    if (!AuthenticationService.__INSTANCE) {
      this.__INSTANCE = new AuthenticationService(api);
    }
    return this.__INSTANCE;
  }

  async login(
    email: string = Config.API_USER,
    password: string = Config.API_PASS
  ): Promise<boolean> {
    const response: IAuthenticationResponse = await this.API.post('/v1/login', {
      email,
      password,
    } as IRequest);

    this._authenticationToken = response.response.token;
    return true;
  }

  isAuthenticated(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!token) {
        return reject(new AuthenticationError('No JWT provided'));
      }

      return this.jwksClient.getSigningKey(
        Config.JWT_KEY_ID,
        (err: Error, key: SigningKey) => {
          if (err) {
            return reject(
              new AuthenticationError('Failed to retrieve public key', err)
            );
          }
          const signingKey = key
            ? (key as RsaSigningKey).rsaPublicKey ||
              (key as CertSigningKey).publicKey
            : null;
          if (!signingKey) {
            return reject(
              new AuthenticationError('Unable to retrieve signing key')
            );
          }

          jwt.verify(
            token,
            signingKey,
            {
              algorithms: ['RS256'],
              issuer: Config.JWT_ISSUER,
              // TODO: Implement KEY_ID checking
              // jwtid: Config.JWT_KEY_ID,
            } as jwt.VerifyOptions,
            (err: Error, decoded) => {
              // if issuer mismatch, err == invalid issuer
              if (err) {
                return reject(err);
              }

              return resolve(true);
            }
          );
        }
      );
    });
  }
}
