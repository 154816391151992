import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IReportAddFilter } from '../_state/generic/actions';
import { DateFilterModel } from '../_filters/date/date-filter.model';
import {
  Utils,
  IDepot,
  HireMovementTypes,
  GaptrackTypes,
  ReportTypes,
  AccrualReportSearchFilter,
  LiveHireReportSearchFilter,
  HireMovementReportSearchFilter,
  InvoiceTypes,
  InvoiceReportSearchFilter} from '@waracle/gap-sdk';
import {
  IReportFilter,
  IReportFilterOptions,
  isEnumFilter,
  IReportFilterContext,
  isDropdownFilter,
} from '../_state/generic/filter';
import { Observable, forkJoin } from 'rxjs';
import { map, concatMap, take, tap, concat, switchMap } from 'rxjs/operators';
import { DepotService } from '../../_services/depot.service';
import { handleFilterMapping } from '../_filters/_helpers';
import {
  LiveHireReportService,
  HireMovementReportService,
  AccrualReportService,
  InvoiceReportService,
  IReportService,
} from '../../_services/report.service';
import {
  reportSelectors as accrualSelectors, customFilterOptions as accrualOptions,
} from '../../report-management/_pages/accrual/accrual.model';
import {
  reportSelectors as hireMovementSelectors, customFilterOptions as hireMovementOptions,
} from '../../report-management/_pages/hire-movement/hire-movement.model';
import {
  reportSelectors as liveHireSelectors, customFilterOptions as liveHireOptions,
} from '../../report-management/_pages/live-hire/live-hire.model';
import {
  reportSelectors as invoiceSelectors, customFilterOptions as invoiceOptions,
} from '../../report-management/_pages/invoice/invoice.model';
import { IReportEntitySelectors } from '../_state/generic/selectors';
import { IReportDTO, IReportSearchFilter } from '../_state/generic/model';
import { rehydrateFilterContext } from '../_filters/_factories';
import { AccountService } from '../../_services/account.service';
import { IAccount } from '../../user-management/_state/accounts/account.model';
import { updateSearchWithHireMovementType, isHireMovementReport } from '../report-management.utils';
import { DefaultFilterModel } from '../_filters/default/default-filter.model';
import { DefaultInputComponent } from '../_filters/default/default.component';

@Injectable({
  providedIn: 'root',
})
export class ReportControllerService {

  private _serviceMap: {[k: string]: {
    service: IReportService<any, any>;
    selectors: IReportEntitySelectors<
      IReportDTO,
      IReportSearchFilter>;
    search: new () => IReportSearchFilter;
    options: IReportFilterOptions,
  }} = {};

  private _accountId$  = this._accounts.activeAccount$.pipe(
    map((account: IAccount) => account.accountId),
  );

  constructor(
    private _store: Store<any>,
    private _accounts: AccountService,
    private _translate: TranslateService,
    private _depot: DepotService,
    private _liveHire: LiveHireReportService,
    private _hireMovement: HireMovementReportService,
    private _accrual: AccrualReportService,
    private _invoice: InvoiceReportService,
  ) {
    this._serviceMap[ReportTypes.ACCRUAL] = {
      service: this._accrual,
      selectors: accrualSelectors,
      search: AccrualReportSearchFilter,
      options: accrualOptions,
    };
    this._serviceMap[ReportTypes.LIVE_HIRE] = {
      service: this._liveHire,
      selectors: liveHireSelectors,
      search: LiveHireReportSearchFilter,
      options: liveHireOptions,
    };
    this._serviceMap[ReportTypes.HIRE_MOVEMENT] = {
      service: this._hireMovement,
      selectors: hireMovementSelectors,
      search: HireMovementReportSearchFilter,
      options: hireMovementOptions,
    };
    this._serviceMap[ReportTypes.INVOICE] = {
      service: this._invoice,
      selectors: invoiceSelectors,
      search: InvoiceReportSearchFilter,
      options: invoiceOptions,
    };
  }

  setDefaultOnDate(
    action: new (payload: IReportFilter) => IReportAddFilter,
    type: ReportTypes = ReportTypes.LIVE_HIRE,
    key: string = 'onHireDateFrom',
    defaultDate?: NgbDate) {
    const now = new Date();
    const date = defaultDate || new NgbDate(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate());
    this.setDateForKey(key, date, action, type);
  }

  setDefaultOffDate(
    action: new (payload: IReportFilter) => IReportAddFilter,
    type: ReportTypes = ReportTypes.LIVE_HIRE,
    key: string = 'offHireDateTo',
    defaultDate?: NgbDate) {
    const now = new Date();
    const date = defaultDate || new NgbDate(
      now.getUTCFullYear(),
      now.getUTCMonth() + 1,
      now.getUTCDate());
    this.setDateForKey(key, date, action, type);
  }

  getI18nFromEnum(key: string, options: {}): Observable<string[]> {
    const translationKeys = Object.keys(options).map(
      (i) => `REPORT.${key.toUpperCase()}.${options[i].toUpperCase()}`);
    return this._translate.get(translationKeys).pipe(map((values) => {
      return Object.keys(values).map((key) => values[key]);
    }));
  }

  getI18nPairFromEnum(key: string, options: {}): Observable<{label: string, value: string}[]> {
    const translationKeys = Object.keys(options).map(
      (i) => `REPORT.${key.toUpperCase()}.${i.toUpperCase()}`);
    return this._translate.get(translationKeys).pipe(map((labels) => {
      return Object.keys(labels).map((label) => {
        const key = label.slice(label.lastIndexOf('.') + 1);
        return ({ label: labels[label], value: options[key] });
      });
    }));
  }

  getEnumStringFromI18n(key: string, value: string, options: {}): HireMovementTypes | undefined {
    const translationKeys = Object.keys(options).map(
      (i) => `REPORT.${key.toUpperCase()}.${options[i].toUpperCase()}`);
    const translations = this._translate.instant(translationKeys);
    return Boolean(value) ? Object.keys(translations)
      .find((key) => translations[key] === value)
      .split('.').pop() as HireMovementTypes : undefined;
  }

  getDepotChoices(key: ReportTypes = ReportTypes.LIVE_HIRE) {
    const search = this._serviceMap[key].search;
    const selector = this._serviceMap[key].selectors.selectValuesByKey('depotName', search);
    return this._store.pipe(
      select(selector),
      map((d) => d.map((di) => ({ label: di, value: di }))),
    );
  }

  setDateForKey(
    id: string,
    values: NgbDate,
    action: new (payload: IReportFilter) => IReportAddFilter,
    type: ReportTypes,
  ) {
    const opts = this._serviceMap[type].options;
    const filter = opts[id] || {
      context: new DefaultFilterModel(id),
      component: DefaultInputComponent,
    };
    const sub = this._translate.get(
      `REPORT.FIELDS.${Utils.toI18N(id)}`,
    ).pipe(take(1)).subscribe((label) => {
      const value = filter.context.toSearch(values);
      const payload = {
        id,
        heading: label,
        label: filter.context.format(value),
        value: value.trim(),
        required: filter.context.required,
      };
      this._store.dispatch(new action(payload));
    });
    sub.unsubscribe();
  }

  getMappedOptions(
    type: ReportTypes,
    initial: IReportFilterOptions,
  ) {
    return this.getHireReportMappedOptions(
      initial,
      type,
    );
  }

  getHireReportMappedOptions(
    initial: IReportFilterOptions,
    type: ReportTypes,
  ): IReportFilterOptions {
    const service = this._serviceMap[type].service;
    const filterMap = handleFilterMapping(initial, (filterContext: IReportFilterContext) => {
      if (!Boolean(filterContext)) return filterContext;
      const hydrated = rehydrateFilterContext(filterContext);

      if (isEnumFilter(hydrated)) {
        switch (hydrated.id) {
          case 'hireMovementType':
            hydrated.choices$ = (_: string) => {
              return this.getI18nFromEnum('HIRE_MOVEMENT_TYPE', HireMovementTypes);
            };
            break;
          case 'invoiceType':
            hydrated.choices$ = (_: string) => {
              return this.getI18nFromEnum('INVOICE_TYPE', InvoiceTypes);
            };
            break;
          default:
            // throw out to the lookahead
            const activeFilters$ = this._store.pipe(
              select(this._serviceMap[type].selectors.selectActiveSearchFilter(this._serviceMap[type].search)),
            );
            hydrated.choices$ = (query: string) => activeFilters$.pipe(
              concatMap((activeFilter) => {
                return this._accountId$.pipe(
                  switchMap((activeCustomer) => {
                    return service.autocomplete(hydrated.id, query, activeFilter, activeCustomer);
                  }),
                );
              }),
            );
            break;
        }
      }
      else if (isDropdownFilter(hydrated)) {
        switch (hydrated.id) {
          case 'depotName':
            // go get all the depots and map to string names
            hydrated.dropdownChoices$ = (_: string) => this.getDepotChoices(type);
            break;
          case 'gaptrack':
            hydrated.dropdownChoices$ = (_: string) => {
              return this.getI18nPairFromEnum('GAPTRACK_TYPE', GaptrackTypes);
            };
            break;
          default:
            // throw out to the lookahead
            const activeFilters$ = this._store.pipe(
              select(this._serviceMap[type].selectors.selectActiveSearchFilter(this._serviceMap[type].search)),
            );
            hydrated.dropdownChoices$ = (query: string) => activeFilters$.pipe(
              concatMap((activeFilter) => {
                return this._accountId$.pipe(
                  switchMap((activeCustomer) => {
                    return service.autocomplete(hydrated.id, query, activeFilter, activeCustomer).pipe(
                      map((d) => d.map((di) => ({ label: di, value: di }))),
                    );
                  }),
                );
              }),
            );
            break;
        }
      }

      return hydrated;
    });
    return filterMap;
  }

  runQueryForType(type: ReportTypes, props: {[k:string]: string}) {
    const search = new this._serviceMap[type].search();
    for (const key in props) {
      search[key] = props[key];
    }
    const service = this._serviceMap[type].service;
    return this._accountId$.pipe(
      take(1),
      concatMap((activeCustomer) => {
        search['customerNumber'] = search['customerNumber'] ? search['customerNumber'] : activeCustomer;
        const query = isHireMovementReport(search) ? updateSearchWithHireMovementType(search) : search;
        return service.search(query);
      }),
    );
  }
}
